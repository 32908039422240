import { useNavigate, useSearchParams } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import LoadingComponent from "../../compontents/LoadingComponent";
import { Alert } from "../../compontents/Alert";
import { AlertMessageContext, UserInfoContext } from "../../context";
import { useAnalyticsEventTracker } from "../../customHook";
import ReactPixel from 'react-facebook-pixel';

const Login = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const gaEventTracker = useAnalyticsEventTracker("Login");
  const setAlertMessage = useContext(AlertMessageContext);
  const [_, dispatchUserInfo] = useContext(UserInfoContext);
  const [loading, setLoading] = useState(false)
  const [sendData, setSendData] = useState([
    {
      title: "電話號碼",
      content: localStorage.getItem('userID') || "",
      placeholder: "電話號碼",
      autocomplete: "username",
      type: "text"
    }, {
      title: "密碼",
      content: localStorage.getItem('password') || "",
      placeholder: "密碼",
      autocomplete: "current-password",
      type: "password"
    },
  ]);
  useEffect(() => {
      ReactPixel.pageView();
  }, []);

  function sendDataChange(index, value) {
    sendData[index].content = value
    setSendData([...sendData])
  }

  function userLogin() {
    gaEventTracker("login", "attempt");
    if (sendData[0].content.length == 0) {
      setAlertMessage({ message: '請輸入電話號碼' });
      return
    }
    if (sendData[1].content.length == 0) {
      setAlertMessage({ message: '請輸入密碼' });
      return
    }
    setLoading(true)

    const json = {
      "id": sendData[0].content,
      "password": sendData[1].content
    }
    const api_url = process.env.REACT_APP_API_URL + "/user/login"
    axios.post(api_url, json)
      .then(response => {
        const user = response.data.data.user;
        if (user) {
          localStorage.setItem('userID', json.id)
          localStorage.setItem('password', json.password)
          dispatchUserInfo(user)
          navigate(searchParams.get('redirect') || '/userinfo')
        } else {
          gaEventTracker("login", "failed")
          setAlertMessage({ message: '登入失敗' });
        }
        setLoading(false)
      })
      .catch(error => {
        console.log('err when login:', error)
        switch (error.response.status) {
            case 401:
                setAlertMessage({ message: '電話或密碼錯誤' });
            break;
            case 403:
                setAlertMessage({ message: '你的帳戶尚未認證，請Whatsapp聯絡客服' });
            break;
            default:
                setAlertMessage({ message: '登入失敗，請重試' });
            break;
        }
        setLoading(false)
      });
  }

  useEffect(() => {
    if (localStorage.getItem('token')) {
      navigate('/userinfo')
    }
  }, [])

  return <div className="appWidth py-4">
    <Link to="/register?type=4" onClick={ () => gaEventTracker("navigate", "register-owner")}>
      <div className="py-2 text-center">
        🚕 的士托管出租？按此一次過搵多間車行報價
      </div>
    </Link>
    <div className="py-2 text-gray-800 text-center font-bold">
      登入
    </div>
    {
      !loading ?
        <form id="login" onSubmit={e => e.preventDefault()}>
          <div className="flex mb-6">
            <div className="w-full">
              {
                sendData.map((i, index) =>
                  <div className="w-full" key={i.title}>
                    <div className="w-full flex items-center">
                      <div className="mr-2 bg-main bg-opacity-100 h-1 w-4"></div>
                      <div className="px-0 py-2 font-bold text-gray-800 bg-main bg-opacity-0 mb-0.5">
                        {i.title}
                      </div>
                    </div>
                    <div className="relative w-full mb-3">
                      <input className="p-2.5 w-full bg-gray-50 placeholder-gray-400 rounded-lg border border-gray-400 focus:ring-blue-500 focus:border-blue-500" 
                        value={i.content} 
                        type={i.type} 
                        autoComplete={i.autocomplete}
                        placeholder={i.placeholder} 
                        onChange={(e) => sendDataChange(index, e.target.value)} />
                    </div>
                  </div>
                )}
            </div>
          </div>
          <div className="w-full gradiBtn p-4 specFont shadow-md text-center mb-8 cursor-pointer"
            onClick={() => userLogin()}>
            登入
          </div>
          <Link to="/home" onClick={ () => gaEventTracker("navigate", "home")}>
            <div className="w-full bg-gray-400 rounded-xl text-white p-3 mb-2 specFont shadow-md text-center cursor-pointer">
              返回主頁
            </div>
          </Link>
          <Link to="/register" onClick={ () => gaEventTracker("navigate", "register")}>
            <div className="w-full greenBtn p-3 specFont shadow-md text-center cursor-pointer">
              未有帳戶？按此註冊
            </div>
          </Link>
        </form>
        :
        <div className="flex">
          <LoadingComponent></LoadingComponent>
        </div>
    }
  </div>
}

export default Login
