import { useContext, useEffect, useRef, useState, MutableRefObject } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import JsxParser from 'react-jsx-parser';
import jsPDF from 'jspdf';
import axios from "axios";
import Modal from 'react-modal';
import SignatureCanvas from "react-signature-canvas";
import moment from "moment";
import jwt_decode from "jwt-decode";
import { FaEraser } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { AlertMessageContext } from "../../context";
import LoadingComponent from "../../compontents/LoadingComponent";
import styles from './contract.css';

const customStyles = {
  overlay: {
    zIndex: 99,
  },
  content: {
    width: '80%',
    maxHeight: '70%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const RentalContract = (props: { back: () => void }) => {
  const location = useLocation();
  const setAlertMessage = useContext(AlertMessageContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const timeoutRef = useRef(null);
  const contractDivRef = useRef(null);
  const signCanvasRef = useRef(null);
  const [contract, setContract] = useState(null);
  const [showSign, setShowSign] = useState(false);
  const [emptySign, setEmptySign] = useState(true);
  const [showCopy, setShowCopy] = useState(false);
  const [copied, setCopied] = useState(false);
  const token = localStorage.getItem('token');
  const userId = !!token && jwt_decode(token).sub;
  const [isDownloading, setDownloading] = useState(false);

  function reset() {
    setAlertMessage({
      type: "confirm",
      message: "重新設定此租車合約？",
      callback: () => {
        navigate("/userinfo/rental", {
          replace: true,
          state: {
            contractId: contract.id,
            action: "reset"
          }
        });
      },
      onCancel: () => { }
    });
  }

  async function fetchContract(id, retryCount = 0) {
    const api_url = process.env.REACT_APP_API_URL + `/contract/${id}`;
    axios.get(api_url)
      .then(response => {
        const { data } = response.data;
        setContract(data);
      })
      .catch(error => {
        if (error.response?.status === 403 || error.response?.status === 404) {
          setAlertMessage({
            message: '此合約不存在',
            callback: () =>
              navigate(location.state ? -1 : "/userinfo/rental", { replace: true })
          });
        } else if (retryCount < 5) {
          timeoutRef.current = setTimeout(function () {
            fetchContract(id, retryCount + 1);
          }, 5000);
        }
      });
  }

  async function submitContractSignature() {
    const status = contract.status;
    setContract({
      ...contract,
      status: "loading"
    });
    const api_url = process.env.REACT_APP_API_URL + `/contract/${contract.id}/sign`;
    axios.post(api_url, {
      data: signCanvasRef.current?.toDataURL()
    })
      .then(response => {
        const { data } = response.data;
        setContract(data);
        setShowSign(false);
      })
      .catch(error => {
        setContract({
          ...contract,
          status,
        });

        if (error.response?.status === 403 || error.response?.status === 404) {
          setAlertMessage({
            message: '此合約不存在',
            callback: () =>
              (props.back) ? props.back() : navigate("/", { replace: true })
          });
        } else {
          setAlertMessage({
            message: '提交合約錯誤，請稍後重試'
          });
        }
      });
  }

  async function share() {
    if (navigator.share) {
      try {
        await navigator.share({
          title: '租車合約',
          text: '請打開連結後於最底簽署',
          url: document.location.href
        });
        return;
      } catch (err) {
        console.error("err when share contract:", err);
      }
    }

    setShowCopy(true);
    setCopied(false);
  }

  async function copyLink() {
    try {
      await navigator.clipboard.writeText("請打開合約連結後於最底簽署:\n\n" + document.location.href);
      setCopied(true);
    } catch (err) {
      console.error("err when copy contract link:", err);
      setAlertMessage({
        message: "複製錯誤，請手動複製"
      });
    }
  }

  async function downloadPDF() {
    setDownloading(true);
    setTimeout(async () => {
      try {
        const response = await fetch("/contract-fonts.json");
        const fonts = await response.json();
        const doc = new jsPDF({
          format: 'a4',
          unit: 'px',
        });
        doc.addFileToVFS('SourceHanSans-normal.ttf', fonts.font);
        doc.addFont('SourceHanSans-normal.ttf', 'SourceHanSans', 'normal');
        doc.setFont('SourceHanSans', 'normal');
        doc.html(contractDivRef.current, {
          width: 380,
          windowWidth: 380,
          margin: [15, 20],
          autoPaging: true,
          callback: async (doc) => {
            try {
              await doc.save('contract');
            } catch (err) {
              setAlertMessage({
                message: "生成合約錯誤"
              });
            } finally {
              setDownloading(false);
            }
          }
        });
      } catch (err) {
        setDownloading(false);
        setAlertMessage({
          message: "下載合約錯誤"
        });
      }
    }, 10);
  }

  useEffect(() => {
    const id = searchParams.get("id");
    fetchContract(id);
  }, []);

  useEffect(() => {
    if (!showSign) {
      setEmptySign(true);
    }
  }, [showSign]);

  if (!contract) {
    return <LoadingComponent></LoadingComponent>;
  }

  return <div className="appWidth pb-20">
    <div ref={contractDivRef} style={{fontFamily: "SourceHanSans"}}>
      <div className="py-5 text-lg font-bold text-center">
        的士租賃合約
      </div>
      {contract.content ? <JsxParser 
        bindings={{ contract }}
        jsx={contract.content}>
      </JsxParser> : <>
        <div>
          <p>
            出租人名稱: <u>{contract.params.ownerName}</u> (下稱「出租人」)
          </p>
          <p>
            司機名稱: <u>{contract.params.driverName}</u> (下稱「司機」)
          </p>
          <p>
            司機駕駛執照號碼: <u>{contract.params.driverDrivingLicense}</u>
          </p>
        </div>
        <div>
          <ol>
            <li>
              司機以租賃方式向出租人承租的士自行合法經營，司機與出租人並無任何勞資關係。司機一經租賃並接收的士後，一切行為與出租人無關。司機在租賃的士前繳付按金港幣{contract.params.deposit}元{contract.params.depositInstalment ? `，另每更繳付按金${contract.params.depositInstalment}元直至供滿${contract.params.depositTotal}元` : ""}。
            </li>
            <li>
              司機所選的士租金每更為港幣{contract.params.rent}元，以出租人指定周期及方式繳交，租金因應更期、車款會有所調整。司機每更必須依出租人指定交更時間及地點交接至下更或停泊。如司機拖欠租金或失聯超過3天，出租人有權即時中止租約，安排拖車公司將的士拖走而無需作出任何賠償。
            </li>
            <li>
              司機必須持有有效駕駛的士執照方可租用的士營業。如執照過期或被扣滿分、判罰停牌等，必須停止租用的士及通知出租人。如有任何瞞騙或失實誤導，引致之一切責任及損失皆由司機承擔。
            </li>
            <li>
              除非得到出租人同意，司機不得擅交的士予他人駕駛，否則出租人有權即時沒收司機之租金、按金，司機亦需賠償出租人一切相關損失。
            </li>
            <li>
              司機在租賃的士期間一切有關該的士之違例扣分、罰款及其他刑責由司機承擔。
            </li>
            <li>
              的士在司機租賃期間所消耗之石油氣由司機負責，有關操作不正常者，應立即通知出租人並按照指示辦理。若司機疏忽檢查而繼續駕駛的士引致交通意外，或被警方檢控，所有一切責任由司機承擔。
            </li>
            <li>
              的士之一切正常耗損保養及維修，包括偈油、輪呔、唧油等，全部由出租人負責，惟須於出租人指定車房辦理。
            </li>
            <li>
              司機在每更接收的士時，需檢查及確保該的士收費錶及通訊機均運作正常，絕無違法加裝或改裝任何設備。如日後因加裝違法設備而被罰款或檢控，均與出租人無關。
            </li>
            <li>
              司機駕駛的士期間，如發生任何交通意外(不論的士損毀與否)，必須於24小時內通知出租人並按照指示辦理手續，包括報案、填報保險、失事報告書等。若司機發生交通意外而無通知出租人，或未依照出租人指示辦理手續而招致之損失全部由司機負責。
            </li>
            <li>
              若租賃的士期間發生交通意外或其他需要維修之情況，若非司機故意令的士損毀，司機負責賠償之最高金額為港幣{contract.params.excess}元。若司機故意令的士損毁，則修理費用全數由司機負責。
            </li>
            <li>
              如交通意外涉及保險申報，司機需於24小時內繳付墊底費港幣{contract.params.excess}元正。如經法庭判決告入對方，成功追討對方全數後方可能取回全部或部份墊底費。
            </li>
            <li>
              所有的士維修需由出租人指定車房進行，如修理時間超過{contract.params.deductAfterHours}小時，以該更期每小時租金按比例扣減車租。除維修外，出租人無需因的士停開而給予司機任何賠償。
            </li>
            <li>
              若司機休息或暫停租賃的士，須於{contract.params.driverNotifyDays}天前通知出租人，或相等天數之車租作代通知金補償出租人。若出租人終止租車合約，須在{contract.params.ownerNotifyDays}天前通知司機，或相等天數之車租作代通知金補償司機。當終止租車合約後{contract.params.depositReturnDays}天，出租人扣除司機一切欠款後(包括罰款、修理費等)將餘額發還予司機。若司機欠款超逾按金，司機必須即時全數清還不足之金額。
            </li>
            <li>
              租賃的士期間，如遇天文台懸掛黑色暴雨警告或8號及以上颱風訊號，司機必須立即中止營業，並於1小時內將的士駛回出租人指定位置直至相關訊號除下。當天租金將根據實際可營業時間按比例收取，相關訊號除下後，司機必須於1小時內將的士駛離停車場，否則停車場費用由司機自付。
            </li>
            <li>
              如因租賃的士產生任何結欠，司機同意出租人直接或委託第三方公司向其名下銀行賬戶設立電子直接付款授權，扣帳尚欠款項(包括該扣帳可能產生之費用)。司機授權出租人向第三方披露必要之司機個人資料，以用作欠款扣帳或追討。
            </li>
          </ol>
        </div>
        <p className="mt-4">
          * 司機已明瞭上述之租車合約條內容，並願意遵守所訂之修款及規則。
        </p>
      </>}
      {contract.status === "signed" && contract.signatures.map((sig, i) => <div key={i} className="mt-2 text-center">
        <img src={sig.data} className="signature" />
        <p className="font-bold pt-1 mx-3 border-t border-solid border-black">
          {sig.role === "driver" ? "司機" : "出租人"}簽署 ({moment(sig.at).format("YYYY/MM/DD")})
        </p>
      </div>)}
    </div>

    {contract.status === "pending" && <div className="fixed bottom-1 left-0 right-0 p-1 flex justify-center gap-2">
      {contract.userId !== userId ? <div className="gradiBtn p-2 specFont shadow-md text-xl text-center w-full"
        onClick={() => setShowSign(true)}>
        已閱讀及同意所有合約內容
      </div> : <>
        <div className="bg-red-500 text-white px-5 py-3 specFont shadow-md text-xl rounded-lg flex items-center"
          onClick={() => reset()}>
          重設
        </div>
        <div className="gradiBtn px-5 py-3 specFont shadow-md text-xl flex items-center"
          onClick={() => share()}>
          發送合約
        </div>
      </>}
    </div>}
    {contract.status === "signed" && <div className="fixed bottom-1 left-0 right-0 p-1 flex justify-center gap-2">
      <div className={`${isDownloading ? "greyBtn" : "greenBtn"} px-5 py-3 specFont shadow-md text-xl`}
        onClick={() => !isDownloading && downloadPDF()}>
        {isDownloading ? "下載中..." : "下載合約"}
      </div>
      {contract.userId === userId && <div className="gradiBtn px-5 py-3 specFont shadow-md text-xl"
        onClick={() => share()}>
        發送合約
      </div>}
    </div>}

    <Modal
      isOpen={showCopy}
      style={customStyles}
      onRequestClose={() => setShowCopy(false)}>
      <div>
        <p className="mb-4 font-bold">
          請複製以下連結，發送給司機簽署
        </p>
        <textarea rows="3" className="border border-solid border-black w-full p-1" readOnly={true} value={document.location.href}></textarea>
        <div className="flex justify-center">
          <button className={`mt-2 ${copied ? "greenBtn" : "gradiBtn"} px-4 py-2 shadow-md flex items-center`}
            onClick={() => copyLink()}>
            {copied && <TiTick className="text-lg" />}{copied ? "已" : ""}複製
          </button>
        </div>
      </div>
    </Modal>

    <Modal
      isOpen={showSign}
      style={customStyles}
      shouldCloseOnOverlayClick={contract.status !== "loading"}
      onRequestClose={() => setShowSign(false)}>
      <div>
        {!emptySign && contract.status !== "loading" && <span className="text-red-600 float-right flex items-center"
          onClick={() => [signCanvasRef.current.clear(), setEmptySign(true)]}>
          <FaEraser className="mr-1"></FaEraser> 清空
        </span>}
        <p className="mb-4 font-bold text-lg">
          {contract.params.driverName}簽署
        </p>
        {contract.status === "loading" ? <LoadingComponent></LoadingComponent> : <>
          <div className="border border-solid border-[#CCCCCC]">
            <SignatureCanvas 
              ref={(c) => signCanvasRef.current = c} 
              canvasProps={{
                style: {
                  width: "100%",
                  height: "200px"
                }
              }}
              onEnd={(e) => setEmptySign(signCanvasRef.current?.isEmpty())}
            ></SignatureCanvas>
          </div>
          <p>(請於上方空白位置簽署後按確定)</p>
          <div className={`mt-4 ${!emptySign ? "gradiBtn" : "greyBtn"} p-3 specFont shadow-md text-center text-lg`}
            onClick={() => !emptySign && submitContractSignature()}>
            提交
          </div>
        </>}
      </div>
    </Modal>
  </div>;
};

export default RentalContract;