import React, { useState, useEffect, useRef, useContext, useMemo } from 'react';
import { useNavigate, useSearchParams, useLocation, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import axios from "axios";
import { GrClose } from 'react-icons/gr';
import moment from 'moment/min/moment-with-locales';
import jwt_decode from "jwt-decode";
import ReactPixel from 'react-facebook-pixel';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Checkbox } from '@mui/material';
import TheTitle from "../../compontents/TheTitle";
import { gTaxiImg } from '../../global/constants';
import LoadingComponent from "../../compontents/LoadingComponent";
import { gOwnerWhatsapp } from '../../global/constants';
import { AlertMessageContext } from "../../context";
import { useAnalyticsEventTracker } from "../../customHook";
import "./SubDetail.css";

const customStyles = {
  overlay: {
    zIndex: 99
  },
  content: {
    width: '100%',
    maxHeight: '50%',
    top: 'auto',
    left: 'auto',
    right: 'auto',
    bottom: '0px',
    overflow: 'scroll'
  },
};

const SubDetail = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [enquiryData, setEnquiryData] = useState(props.enquiryData || location.state?.enquiryData);
  const [title, setTitle] = useState('');
  const [dataList, setDataList] = useState([]);
  const [searchParams] = useSearchParams();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [subarea, setSubarea] = useState("");
  const [meetup, setMeetup] = useState(false);
  const [rent, setRent] = useState("");
  const timeoutRef = useRef(null);
  const { t } = useTranslation();
  const setAlertMessage = useContext(AlertMessageContext);
  const { back, noAction } = props;
  const enquiryId = location.state?.enquiryId || searchParams.get("eid");
  const token = localStorage.getItem('token');
  const loggedIn = !!token;
  const userId = loggedIn && jwt_decode(token).sub;
  const userBid = useMemo(() => enquiryData?.bids?.find(b => b.userId === userId), [enquiryData]);

  useEffect(() => {
    ReactPixel.pageView();
  }, []);

  function tKV(obj) {
    for (const k in obj) {
      return obj[k] !== undefined && obj[k] !== null && obj[k] !== '' ? t(`${k}.${obj[k]}`) : '';
    }
  }

  function setData(enquiry) {
    const {
      ref, offer, user, time
    } = enquiry;
    const {
      area, carColor, carType
    } = offer;
    setTitle(`#${ref} ${tKV({ carColor })} ${tKV({ carType })}`);

    const data = [
      {
        title: '地區',
        value: area
      },
      {
        title: '更期',
        value: `${tKV({ shift: time.shift })}`
      },
      {
        title: '日期',
        value: moment(time.start).format("M月D日") + " (星期" + tKV({ weekday: moment(time.start).day() || 7 }) + ")"
      },
      {
        title: '經驗',
        value: user.expYears != null && ("的士" + (user.expYears ? `${user.expYears}年` : "新手") + (user.drivingExpYears ? `，其他${user.drivingExpYears}年` : ""))
      },
    ].filter(val => val.value);
    setDataList(data)
  }

  function getByEnquiryId(enquiryId, retryCount = 0) {
    const json = {
      "id": enquiryId,
    };
    const api_url = process.env.REACT_APP_API_URL + "/trequest/getById";
    axios.post(api_url, json)
      .then(response => {
        setEnquiryData(response.data.data);
      })
      .catch(error => {
        if (error.response?.status === 403 || retryCount >= 5) {
          if (!enquiryData) {
            if (error.response?.status === 403 
                && error.response?.data?.message !== 'role forbidden') {
              setAlertMessage({
                message: '此替更資料已過期',
                callback: () =>
                  (back) ? back() : navigate("/", { replace: true })
              });
            }
          }
        } else {
          timeoutRef.current = setTimeout(function () {
            getByEnquiryId(enquiryId, retryCount + 1);
          }, 5000);
        }
      });
  }

  function getDesc() {
    const { ref, offer, time } = enquiryData;
    const { carColor, carType, area } = offer;
    return `#${ref}\n` +
      `${time.day} (${tKV({ weekday: (moment(time.start).day() || 7) })})\n` +
      `${area}\n` +
      `${tKV({ carColor })}${tKV({ carType })} ${tKV({ shift: time.shift })}`;
  }

  async function confirm() {
    setModalLoading(true);
    try {
      const { offer, time } = enquiryData;
      const { carColor, carType, area } = offer;
      const { shift, day } = time;
      const bid = {
        carColor, carType, area, shift, day,
        subarea: `${subarea}${meetup ? " (可見面更)" : ""}`,
        rent,
      };
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/trequest/${enquiryData._id}/bid`, bid);
      setModalOpen(false);
      setEnquiryData(data.data);
      setAlertMessage({ message: '已成功報價，請等候我們向司機確認預約' });
    } catch (err) {
      console.error("err when bid request:", err);
      setAlertMessage({ message: '報價錯誤，請重試' });
    } finally {
      setModalLoading(false);
    }
  }

  useEffect(() => {
    if (loggedIn && enquiryId) {
      if (!enquiryData || !noAction) {
        getByEnquiryId(enquiryId);
      }
    } else {
      navigate("/", { replace: true });
    }

    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current);
    };
  }, []);

  useEffect(() => {
    enquiryData && setData(enquiryData);
  }, [enquiryData]);

  return <div className="w-full bg-white">
    <div className={`appWidth pt-4 pb-${noAction ? 0 : 16}`}>
      <div className="relative">
        <div>
          <TheTitle className="pl-4 mb-2" title={title} views={!noAction && enquiryData && enquiryData.views || null}></TheTitle>
          {
            enquiryData ?
              <div>
                {
                  !noAction && <div className="flex mx-auto my-2 justify-center" style={{maxWidth: "600px"}}>
                  {
                    <div key={enquiryData.offer.carType} className="px-12">
                      <img className="w-full h-full object-contain" 
                        src={gTaxiImg(enquiryData.offer.carColor, enquiryData.offer.carType)} />
                    </div>
                  }
                  </div>
                }
                {
                  dataList.map((i, index) => i.value ? <div key={index} className="border-b border-solid border-gray-400 overflow-hidden">
                    <div className="col-span-2 px-5 py-3">
                      <div className="flex flex-row items-center">
                        {i.title ? <div>
                          <p className="text-gray-500 whitespace-nowrap mr-2">{i.title}</p>
                        </div> : ''}
                        <div className={`text-black ${i.title ? "text-lg" : ""}`}>
                          {i.value.split("\n").map((r, i) => <p key={i}>{r}</p>)}
                        </div>
                      </div>
                    </div>
                  </div>: '')
                }
                {
                  !noAction ? <div className="fixed bottom-0 left-0 right-0 p-1 bg-white">
                    <div className={`gradiBtn p-3 specFont shadow-md text-center${!userBid ? " text-lg" : ""}`}
                      onClick={() => setModalOpen(true)}>
                      {!userBid ? "報價" : `已報 $${userBid.rent} ${userBid.subarea}`}
                    </div>
                  </div> : ""
                }
              </div>
              :
              <LoadingComponent></LoadingComponent>
          }
        </div>
        <Modal
          isOpen={modalOpen}
          style={customStyles}
          onRequestClose={() => setModalOpen(false)}>
          <GrClose className="text-black float-right" onClick={() => setModalOpen(false)}></GrClose>
          {modalLoading ? <LoadingComponent></LoadingComponent> : <>
            <div className="mt-3 mx-5">
              <div>
                <div>交車地點</div>
                <input type="text" className="text-input" value={subarea} onChange={(e) => setSubarea(e.target.value)} />
                <FormControlLabel className="mt-1"
                  label="接受見面更"
                  control={<Checkbox
                    checked={meetup}
                    onChange={(e) => setMeetup(e.target.checked)}
                  />}
                />
              </div>
              <div className="mt-3">
                <div>租金</div>
                <input type="number" className="text-input" value={rent} onChange={(e) => setRent(e.target.value)} />
              </div>
            </div>
            <button className="mt-8 w-full gradiBtn p-3 specFont shadow-md"
              onClick={confirm}>
              確定
            </button>
          </>}
        </Modal>
      </div>
    </div>
  </div>
}

export default SubDetail
