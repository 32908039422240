import { menuData } from "../global/constants"
import {useState} from 'react'
import { useTranslation } from "react-i18next";
import { Link as LinkScroll, animateScroll as scroll,scroller } from "react-scroll";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";

const LoadingComponent = (props) =>{
    const { t } = useTranslation();
    const { i18n } = useTranslation();
   
    const changeLanguage = () => {
        console.log(i18n.language)
        if (i18n.language == 'zh-TW'){
            i18n.changeLanguage('en');
        }
        else{
            i18n.changeLanguage('zh-TW');
        }
 
      }; 

    

    return <div className="relative text-xl py-2 w-full text-black">
     <p className="text-center text-base mb-2 text-main font-bold">載入中</p>
     <img className="w-20 h-20 mx-auto" src={require("../assets/loading.gif")} />


    </div>

}
export default LoadingComponent