import { useState, useEffect, useContext, useMemo } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import ReactPixel from 'react-facebook-pixel';
import { AlertMessageContext } from "../../context";
import { useAnalyticsEventTracker } from "../../customHook";
import LoadingComponent from "../../compontents/LoadingComponent";
import RentalContractForm from "./contractForm";
import { gBanks, gCollectionTypes } from "../../global/constants";
import axios from "axios";
import { AiOutlineEdit } from 'react-icons/ai';
import { BsWhatsapp } from 'react-icons/bs';
import { GrClose } from 'react-icons/gr';
import { MdDelete } from "react-icons/md";
import { IoIosAdd } from "react-icons/io";
import { IoDocumentTextOutline } from "react-icons/io5";
import Select from 'react-select';
import Modal from 'react-modal';
import moment from 'moment';
import { last } from 'lodash';

const customStyles = {
  overlay: {
    zIndex: 99
  },
  content: {
    width: '80%',
    maxHeight: '70%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const rentalRows = [{
  title: "司機稱呼",
  key: "name",
  placeholder: "識別司機的稱呼"
}, {
  title: "電話號碼",
  type: "number",
  key: "phone",
  placeholder: "Whatsapp電話號碼",
  maxlength: 8
}, {
  title: "英文全名",
  key: "fullName",
  placeholder: "英文全名(與身份證一致)",
  pattern: "[A-Za-z]"
}, {
  title: "身份證號碼",
  key: "idNumber",
  placeholder: "香港身份證號碼",
  pattern: "[A-Za-z0-9]"
}, {
  title: "起租日",
  type: "date",
  key: "startAt",
  placeholder: ""
}];

const bankAccountRows = [{
  title: "銀行",
  type: "select",
  key: "bankCode",
  values: gBanks.map(b => ({
    ...b,
    label: `${b.label} (${b.value})`
  })),
  placeholder: "請選擇銀行"
}, {
  title: "戶口名稱",
  type: "text",
  key: "name"
}, {
  title: "戶口號碼",
  type: "number",
  key: "number",
  placeholder: "請輸入戶口號碼"
}];

const collectionRows = [{
  title: "類型",
  type: "select",
  key: "type",
  values: gCollectionTypes,
  placeholder: "請選擇收款類型"
}, {
  title: "日子",
  type: "date",
  key: "date"
}, {
  title: "金額($)",
  type: "number",
  key: "amount",
  placeholder: "請輸入金額"
}, {
  title: "備註",
  key: "desc",
  placeholder: "其他備註"
}];

const idNumberSuffices = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, "A"];

const Rental = () => {
  const gaEventTracker = useAnalyticsEventTracker("User Info");
  const location = useLocation(false);
  const [listLoading, setListLoading] = useState(false);
  const [listReload, setListReload] = useState(false);
  const [rentalSearch, setRentalSearch] = useState("");
  const [rentals, setRentals] = useState([]);
  const [rentalData, setRentalData] = useState(null);
  const [rentalLoading, setRentalLoading] = useState(false);
  const [bankAccountData, setBankAccountData] = useState(null);
  const [bankAccountLoading, setBankAccountLoading] = useState(false);
  const [collectionData, setCollectionData] = useState(null);
  const [collectionLoading, setCollectionLoading] = useState(false);
  const [bankAccountsModalRental, setBankAccountsModalRental] = useState(null);
  const [collectionsModalRental, setCollectionsModalRental] = useState(null);
  const [rentalCollectionsLoading, setRentalCollectionsLoading] = useState(false);
  const [rentalCollections, setRentalCollections] = useState(null);
  const [rentalModalRental, setRentalModalRental] = useState(null);
  const [licenseModalRental, setLicenseModalRental] = useState(null);
  const [bankAccountModalRental, setBankAccountModalRental] = useState(null);
  const [collectionModalRental, setCollectionModalRental] = useState(null);
  const [contractModalRental, setContractModalRental] = useState(null);
  const setAlertMessage = useContext(AlertMessageContext);
  const navigate = useNavigate();
  const filteredRentals = useMemo(() => {
    if (rentalSearch) {
      return rentals.filter(r => [
        r.payerName,
        r.payer.phone,
        r.payer.idNumber,
        ...r.payer.names
      ].some(s => s.toUpperCase().includes(rentalSearch.toUpperCase())));
    }
    return rentals;
  }, [rentals, rentalSearch]);

  useEffect(() => {
    ReactPixel.pageView();
  }, []);

  function formatAmount(amount) {
    return (amount < 0 ? "-" : "") + `$${parseFloat(Math.abs(amount).toFixed(1))}`;
  }

  function getRentals(reset = false) {
    if (!reset) {
      if (listLoading) {
        return;
      }
    } else {
      setListLoading(true);
      setListReload(false);
      setRentals([]);
    }

    const api_url = process.env.REACT_APP_API_URL + "/rental/search";
    axios.post(api_url).then(response => {
      const rentals = response.data.data;
      setRentals(rentals);
    }).catch(error => {
      console.log('err when get rentals:', error);
      if (rentals.length === 0 || reset) {
        setListReload(true);
      }
    }).finally(() => {
      setListLoading(false)
    });
  }

  function createOrUpdateRental() {
    const problemRow = rentalRows.find(row => !rentalData[row.key]);
    if (problemRow) {
      setAlertMessage({ message: `請填寫${problemRow.title}` });
      return;
    }

    const action = (rentalModalRental._id ? "update" : "create");
    gaEventTracker(`${action} rental`, "submit");
    setRentalLoading(true);

    let api_url = process.env.REACT_APP_API_URL + "/rental";
    if (rentalModalRental._id) {
      api_url += `/${rentalModalRental._id}`;
    }
    axios.post(api_url, {
      ...rentalData,
      idNumber: `${rentalData.idNumber}(${rentalData.idNumberSuffix})`,
      idNumberSuffix: undefined,
      currency: "HKD"
    }).then(response => {
      const rental = response.data.data;
      if (rentalModalRental._id) {
        setRentals(rentals.map(r => r._id === rental._id ? rental : r));
      } else {
        setRentals([rental, ...rentals]);
      }
      setRentalModalRental(null);
    }).catch(error => {
      console.log(`err when ${action} rental:`, error)
      setAlertMessage({
        message: `${rentalModalRental._id ? "修改" : "新增"}司機資料錯誤，請重試`
      });
    }).finally(() => {
      setRentalLoading(false);
    });
  }

  function updateLicense() {
    if (!licenseModalRental.licensePlate) {
      setAlertMessage({ message: `請輸入車牌` });
      return;
    }

    gaEventTracker("update rental license", "submit");
    setRentalLoading(true);

    const api_url = process.env.REACT_APP_API_URL + `/rental/${licenseModalRental._id}/license`;
    axios.post(api_url, {
      licensePlate: licenseModalRental.licensePlate
    }).then(response => {
      const rental = response.data.data;
      setRentals(rentals.map(r => r._id === rental._id ? rental : r));
      setLicenseModalRental(null);
    }).catch(error => {
      console.log("err when update rental license:", error)
      setAlertMessage({
        message: "更新車牌錯誤，請重試"
      });
    }).finally(() => {
      setRentalLoading(false);
    });
  }

  function createBankAccount() {
    const problemRow = bankAccountRows.find(row => !bankAccountData[row.key]);
    if (problemRow) {
      setAlertMessage({ message: `請填寫${problemRow.title}` });
      return;
    }

    gaEventTracker("add bank account", "submit");
    setBankAccountLoading(true);

    const api_url = process.env.REACT_APP_API_URL + `/rental/${bankAccountData.rentalId}/bank`;
    axios.post(api_url, bankAccountData).then(response => {
      const rental = response.data.data;
      setRentals(rentals.map(r => r._id === rental._id ? rental : r));
      setBankAccountModalRental(null);
    }).catch(error => {
      console.log("err when add bank acc:", error)
      setAlertMessage({
        message: "新增銀行戶口錯誤，請重試"
      });
    }).finally(() => {
      setBankAccountLoading(false);
    });
  }

  function removeBankAccount(account) {
    gaEventTracker("remove bank account", "open");
    setAlertMessage({
      type: "confirm",
      message: `刪除戶口 ${account.number}？`,
      callback: () => {
        gaEventTracker("remove bank account", "confirm");
        const api_url = process.env.REACT_APP_API_URL + `/rental/${bankAccountsModalRental._id}/bank`;
        axios.delete(api_url, {
          data: account
        }).then(response => {
          const rental = response.data.data;
          setRentals(rentals.map(r => r._id === rental._id ? rental : r));
          setBankAccountsModalRental(rental);
        }).catch(error => {
          console.log("err when remove bank account:", error)
          setAlertMessage({
            message: "移除銀行戶口錯誤，請重試"
          });
        });
      },
      onCancel: () => {
        gaEventTracker("remove bank account", "cancel");
      }
    });
  }

  function createCollection() {
    const problemRow = collectionRows.find(row => !collectionData[row.key]);
    if (problemRow) {
      setAlertMessage({ message: `請填寫${problemRow.title}` });
      return;
    }

    gaEventTracker("create rental collection", "submit");
    setCollectionLoading(true);

    const api_url = process.env.REACT_APP_API_URL + `/rental/${collectionData.rentalId}/collect`;
    axios.post(api_url, {
      ...collectionData,
      currency: "HKD"
    }).then(response => {
      const rental = response.data.data;
      setRentals(rentals.slice().map(r => (r._id === rental._id ? rental : r)));
      setCollectionModalRental(null);
    }).catch(error => {
      console.log('err when create rental collection:', error);
      setAlertMessage({
        message: "新增收款項目錯誤，請重試"
      });
    }).finally(() => {
      setCollectionLoading(false);
    });
  }

  function removeCollection(col) {
    gaEventTracker("remove rental collection", "open");
    const date = moment(col.date).format("M/D");
    const type = gCollectionTypes.find(t => t.value === col.type)?.label;
    setAlertMessage({
      type: "confirm",
      message: `刪除款項${date} ${type} ${formatAmount(col.amount/100)}？`,
      callback: () => {
        gaEventTracker("remove rental collection", "confirm");
        setRentalCollections(rentalCollections.map(c => c._id === col._id ? {
          ...col,
          status: "loading"
        } : c));
    
        const api_url = process.env.REACT_APP_API_URL + `/rental/${col.rentalId}/collect`;
        axios.delete(api_url, {
          data: {
            id: col._id
          }
        }).then(response => {
          const rental = response.data.data;
          setRentals(rentals.slice().map(r => (r._id === col.rentalId ? rental : r)));
          setRentalCollections(rentalCollections.filter(c => c._id !== col._id));
        }).catch(error => {
          console.log('err when remove rental collection:', error);
          setRentalCollections(rentalCollections.map(c => c._id === col._id ? col : c));
          setAlertMessage({
            message: "刪除收款項目錯誤，請重試"
          });
        });
      },
      onCancel: () => {
        gaEventTracker("remove rental collection", "cancel");
      }
    });
  }

  function getRentalCollections(reset = true) {
    if (rentalCollectionsLoading) {
      return;
    }

    gaEventTracker("get rental collections", reset ? "refresh" : "next");
    setRentalCollectionsLoading(true);
    if (reset) {
      setRentalCollections(null);
    }

    const api_url = process.env.REACT_APP_API_URL + `/rental/${collectionsModalRental._id}/collections`;
    axios.get(api_url, {
      params: {
        offset: (reset ? 0 : rentalCollections.length)
      }
    }).then(response => {
      const collections = response.data.data;
      setRentalCollections(reset ? collections : [...rentalCollections, ...collections]);
    }).catch(error => {
      console.log('err when fetch rental collections:', error);
      if (reset) {
        setCollectionsModalRental(null);
        setAlertMessage({
          message: "讀取收款記錄錯誤，請重試"
        });
      }
    }).finally(() => setRentalCollectionsLoading(false));
  }

  function getFormInputRow(i, index, data, setData) {
    if (!data) {
      return "";
    }
    return <div className="w-full" key={index}>
      {i.title && <div className="w-full flex items-center">
        <div className="mr-2 bg-main bg-opacity-100 h-1 w-2"></div>
        <div className="font-bold text-base text-gray-800 bg-main bg-opacity-0 mb-0.5">
          {i.title}
        </div>
      </div>}
      <div className="flex bg-main bg-opacity-0 mb-4">
        <div className="grow leading-8 text-xl">
          {i.type === "select" ? <Select
            styles={{ menu: base => ({ ...base, zIndex: 9 }) }}
            placeholder={i.placeholder}
            value={i.values.find(({ value }) => value === data[i.key])}
            options={i.values}
            className="basic-multi-select text-black"
            onChange={({ value }) => setData({
              ...data,
              [i.key]: value
            })}
          /> : <input className={"placeholder-gray-400 border border-gray-400 rounded-md focus:ring-blue-600 focus:border-blue-600 " + (i.type === "date" ? "p-2.5 w-full bg-gray-50" : "px-4 py-2 w-full text-gray-700")}
            type={i.type || "text"}
            value={data[i.key]}
            maxLength={i.maxLength}
            pattern={i.pattern}
            placeholder={i.placeholder}
            onChange={(e) => setData({
              ...data,
              [i.key]: e.target.value
            })} />}
        </div>
        {
          i.key === "idNumber" ? <select className="px-1 m-1 text-lg border-x-2 border-gray-700 rounded-xl bg-gray-50"
            value={data[`${i.key}Suffix`]}
            onChange={(e) => setData({
              ...data,
              [`${i.key}Suffix`]: e.target.value
            })}>
            {idNumberSuffices.map(v => <option key={v} value={v}>
              {v}
            </option>)}
          </select> : ""
        }
      </div>
    </div>;
  }

  function getOrGenerateContract(rental) {
    if (rental.contractId) {
      navigate(`/contract?id=${rental.contractId}`);
      return;
    }

    setContractModalRental(rental);
  }

  function generateContract(form) {
    const api_url = process.env.REACT_APP_API_URL + `/rental/${contractModalRental._id}/contract`;
    return axios.post(api_url, {
      params: form
    }).then(response => {
      const contract = response.data.data;
      setRentals(rentals.slice().map(r => (r._id === contractModalRental._id ? {
        ...r,
        contractId: contract.id
      } : r)));
      setContractModalRental(null);
      navigate(`/contract?id=${contract.id}`);
    }).catch(error => {
      console.log('err when generate rental contract:', error);
      setAlertMessage({
        message: "生成合約錯誤，請重試"
      });
    });
  }

  useEffect(() => {
    getRentals(true);
  }, []);

  useEffect(() => {
    const action = location.state?.action;
    if (action === "reset") {
      const contractId = location.state.contractId;
      if (rentals.length) {
        rentals.some(r => {
          if (r.contractId === contractId) {
            getOrGenerateContract({
              ...r,
              contractId: ""
            });
            return true;
          }
        });
        navigate(location.pathname, { replace: true });
      }
    }
  }, [rentals]);

  useEffect(() => {
    if (rentalModalRental) {
      if (!rentalModalRental._id) {
        setRentalData(rentalRows.reduce((accu, { type, key }) => {
          accu[key] = (type === "date" ? moment().format("YYYY-MM-DD") : "");
          return accu;
        }, {}));
      } else {
        let idNumber = rentalModalRental.payer.idNumber;
        const match = /\(([^)]+)\)/.exec(idNumber);
        if (match) {
          idNumber = idNumber.substring(0, match.index);
        }
        setRentalData({
          name: rentalModalRental.payerName,
          phone: rentalModalRental.payer.phone,
          fullName: last(rentalModalRental.payer.names),
          idNumber,
          idNumberSuffix: (match && idNumberSuffices.includes(match[1]) ? match[1] : "0"),
          startAt: rentalModalRental.startAt.substring(0, 10)
        });
      }
    }
  }, [rentalModalRental]);

  useEffect(() => {
    if (bankAccountModalRental) {
      setBankAccountData(bankAccountRows.reduce((accu, { type, key }) => {
        accu[key] = (key === "name" && bankAccountModalRental.payer?.names[0] || "");
        return accu;
      }, {
        rentalId: bankAccountModalRental._id
      }));
    }
  }, [bankAccountModalRental]);

  useEffect(() => {
    if (collectionModalRental) {
      setCollectionData(collectionRows.reduce((accu, { type, key }) => {
        accu[key] = (type === "date" ? moment().format("YYYY-MM-DD") : "");
        return accu;
      }, {
        rentalId: collectionModalRental._id
      }));
    }
  }, [collectionModalRental]);

  useEffect(() => {
    if (collectionsModalRental) {
      getRentalCollections(true);
    }
  }, [collectionsModalRental]);

  return <div>
    <div className="appWidth">
      <div className="py-5 text-lg font-bold text-center">
        自動收租及追收欠款
      </div>
      <div className="flex justify-between items-center mb-2 relative">
        <div className="relative grow mr-2">
          <div className="absolute inset-y-0 start-0 flex items-center ps-3 ml-2 pointer-events-none">
            <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
            </svg>
          </div>
          <input type="search"
            className="block w-full pl-7 pr-4 py-1.5 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
            placeholder="搜尋姓名/電話/身份證號碼"
            value={rentalSearch}
            onChange={(e) => setRentalSearch(e.target.value)} />
        </div>
        <div className="bg-main px-3 py-1.5 rounded-lg text-white shadow"
          onClick={() => setRentalModalRental({})}>
          新增司機
        </div>
      </div>
      {
        !listLoading && listReload &&
          <div className="flex w-full h-20">
            <div className="m-auto gradiBtn w-28 cursor-pointer text-center px-4 py-2"
              onClick={() => getRentals(true) }>
              請重試
            </div>
          </div>
      }
      {
        !listLoading && !listReload && filteredRentals.length === 0 ? <div className="text-gray-500 font-bold text-center my-10">
          - 沒有收租司機 -
        </div> : <table className="table-auto w-full bg-white mt-5">
          <tbody>
            {filteredRentals.map((r, i) => <tr key={i}>
              <td className="border border-gray-500 px-1 py-2">
                <div className="flex items-center mb-2">
                  <div className="flex-1 text-lg">
                    <span className="mr-2">
                      #{r.ref}
                    </span>
                    <b>
                      {r.payerName}
                    </b>
                    {r.payer?.phone && <a className="underline text-green-600 ml-2"
                      href={`https://wa.me/852${r.payer.phone}`}
                      target="_blank">
                      {r.payer.phone}
                      <BsWhatsapp className="inline ml-1 mb-1"></BsWhatsapp>
                    </a>}
                  </div>
                  <div className="flex-none">
                    <div className="flex items-center text-main"
                      onClick={() => setRentalModalRental(r)}>
                      修改<AiOutlineEdit></AiOutlineEdit>
                    </div>
                  </div>
                </div>
                {/* {r.licensePlate ? <div className="flex mb-2">
                  <div className="flex items-center"
                    onClick={() => setLicenseModalRental(r)}>
                    <AiOutlineEdit className="mr-0.5 text-main text-lg">
                      更新
                    </AiOutlineEdit> 車牌: {r.licensePlate}
                  </div>
                </div> : <div className="mb-2">
                  <button className="greyBtn px-2 py-1 rounded-lg"
                    onClick={() => setLicenseModalRental(r)}>
                    加入車牌
                  </button>
                </div>} */}
                <div className="flex items-center mb-2">
                  {r.payer?.bankAccounts?.length ? <button className="bg-main text-white px-2 py-1 mr-1 rounded-lg"
                    onClick={() => setBankAccountsModalRental(r)}>
                    查看
                  </button> : <button className="greyBtn px-2 py-1 mr-1 rounded-lg"
                    onClick={() => setBankAccountModalRental(r)}>
                    加入
                  </button>}
                  <div>
                    銀行戶口: {r.payer?.bankAccounts?.length || 0}個
                  </div>
                </div>
                <div>
                  <div className={r.balance < 0 ? "text-red-600" : ""}>
                    {r.balance < 0 ? `尚欠 $${-r.balance/100}` : "無欠款"}
                  </div>
                  {r.collections[0] && <p className="text-sm">
                    最近未付: ({moment(r.collections[0].date).format("M/D")}) {gCollectionTypes.find(t => t.value === r.collections[0].type)?.label}
                    {formatAmount((r.collections[0].amount - r.collections[0].paid)/100)}
                  </p>}
                </div>
                <div className="flex flex-row justify-end gap-2 mt-2">
                  {r.contractStatus === "signed" ? <>
                    <span className="flex items-center text-main mr-4"
                      onClick={() => getOrGenerateContract(r)}>
                      <IoDocumentTextOutline /> 合約
                    </span>
                    <button className="bg-main text-white px-3 py-1 rounded-lg whitespace-nowrap"
                      onClick={() => setCollectionsModalRental(r)}>
                      記錄
                    </button>
                    <button className="bg-green-500 text-white px-3 py-1 rounded-lg whitespace-nowrap"
                      onClick={() => setCollectionModalRental(r)}>
                      收款
                    </button>
                  </> : <button className={`${!r.contractId ? "bg-green-500" : "bg-red-500"} text-white px-3 py-1 rounded-lg whitespace-nowrap`}
                    onClick={() => getOrGenerateContract(r)}>
                    {r.contractId ? "簽" : "設立"}合約
                  </button>}
                </div>
              </td>
            </tr>)}
          </tbody>
        </table>
      }
      {
        listLoading &&
          <LoadingComponent>
          </LoadingComponent>
      }
    </div>

    <Modal
      isOpen={!!bankAccountsModalRental}
      style={customStyles}
      onRequestClose={() => setBankAccountsModalRental(null)}>
      {
        bankAccountsModalRental ? <div>
          <div className="sticky top-0 bg-white pb-4">
            <GrClose className="text-black float-right" onClick={() => setBankAccountsModalRental(null)}></GrClose>
            <p className="text-main font-bold text-xl">
              #{bankAccountsModalRental.ref} 銀行戶口
            </p>
          </div>
          <div className="mb-3">
            <button className="flex items-center greyBtn px-2 py-1 rounded-lg"
              onClick={() => [setBankAccountsModalRental(null), setBankAccountModalRental(bankAccountsModalRental)]}>
              <IoIosAdd className="inline text-lg" /> 新增戶口
            </button>
          </div>
          {
            bankAccountsModalRental.payer?.bankAccounts?.map((acc, i) => <div key={i}
              className="mb-3 rounded-lg border border-[#cccccc]">
              <div className="flex items-center px-2 py-1 rounded-t-lg bg-gray-600">
                <div className="grow text-white">
                  {acc.name}
                </div>
              </div>
              <div className="flex items-end px-1 py-3 bg-main3">
                <div className="grow">
                  ({acc.bankCode}) {acc.number}
                </div>
                <div className="flex-none text-red-400 text-xl"
                  onClick={() => removeBankAccount(acc)}>
                  <MdDelete />
                </div>
              </div>
            </div>) || <div className="text-gray-500 font-bold text-center">
              - 沒有銀行戶口 -
            </div>
          }
        </div> : ""
      }
    </Modal>

    <Modal
      isOpen={!!collectionsModalRental}
      style={customStyles}
      onRequestClose={() => setCollectionsModalRental(null)}>
      {
        collectionsModalRental ? <div>
          <div className="sticky top-0 bg-white pb-4">
            <GrClose className="text-black float-right" onClick={() => setCollectionsModalRental(null)}></GrClose>
            <p className="text-main font-bold text-xl">
              #{collectionsModalRental.ref} 收款記錄
            </p>
          </div>
          {
            rentalCollections && (
              rentalCollections.length ? rentalCollections.map((c, i) => <div key={i}
                className="mb-3 rounded-lg border border-[#cccccc]">
                <div className="flex items-center px-2 py-1 rounded-t-lg bg-gray-600">
                  <div className="grow text-white">
                    {moment(c.date).format("M月D日")}
                  </div>
                  {((overdue, dueDays) => <div className={`flex-none ${overdue > 0 ? "text-red-400" : "text-green-500"}`}>
                    {overdue > 0 ? (dueDays > 0 ?`過期${dueDays}天` : "未付") : (overdue === 0 ? "已付款" : "待退款")}
                  </div>)(c.amount - c.paid, moment().diff(c.dueAt, "days"))}
                </div>
                <div className="flex items-end px-2 py-1 bg-main3">
                  <div className="grow">
                    {gCollectionTypes.find(t => t.value === c.type)?.label} {formatAmount(c.amount/100)}
                    {c.desc && <p className="text-sm">*{c.desc}</p>}
                  </div>
                  {c.status === "pending" ? <div className="flex-none text-red-400 text-xl"
                    onClick={() => removeCollection(c)}>
                    <MdDelete />
                  </div> : (c.status === "loading" ? <span>
                    載入中
                  </span> : "")}
                </div>
              </div>) : <div className="text-gray-500 font-bold text-center">
                - 沒有收款記錄 -
              </div>)
          }
          {
            rentalCollectionsLoading ? <LoadingComponent></LoadingComponent> : ""
          }
        </div> : ""
      }
    </Modal>

    <Modal
      isOpen={!!licenseModalRental}
      style={customStyles}
      shouldCloseOnOverlayClick={false}
      onRequestClose={() => setLicenseModalRental(null)}>
      {
        licenseModalRental ? (rentalLoading ? <LoadingComponent>
        </LoadingComponent> : <div>
          <GrClose className="text-black float-right" onClick={() => setLicenseModalRental(null)}></GrClose>
          <p className="mb-4 text-main font-bold text-xl">
            #{licenseModalRental.ref} 更新車牌號碼
          </p>
          {
            getFormInputRow({
              key: "licensePlate",
              placeholder: "車牌號碼"
            }, 0, licenseModalRental, setLicenseModalRental)
          }
          <button className="mt-4 w-full gradiBtn p-4 specFont shadow-md"
            onClick={updateLicense}>確定</button>
        </div>) : ""
      }
    </Modal>

    <Modal
      isOpen={!!bankAccountModalRental}
      style={customStyles}
      shouldCloseOnOverlayClick={false}
      onRequestClose={() => setBankAccountModalRental(null)}>
      {
        bankAccountModalRental ? (bankAccountLoading ? <LoadingComponent>
        </LoadingComponent> : <div>
          <GrClose className="text-black float-right" onClick={() => setBankAccountModalRental(null)}></GrClose>
          <p className="mb-4 text-main font-bold text-xl">#{bankAccountModalRental.ref} 加入銀行戶口</p>
          {
            bankAccountRows.map((i, index) => getFormInputRow(i, index, bankAccountData, setBankAccountData))
          }
          <button className="mt-4 w-full gradiBtn p-4 specFont shadow-md"
            onClick={createBankAccount}>確定</button>
        </div>) : ""
      }
    </Modal>

    <Modal
      isOpen={!!collectionModalRental}
      style={customStyles}
      shouldCloseOnOverlayClick={false}
      onRequestClose={() => setCollectionModalRental(null)}>
      {
        collectionModalRental ? (collectionLoading ? <LoadingComponent>
        </LoadingComponent> : <div>
          <GrClose className="text-black float-right" onClick={() => setCollectionModalRental(null)}></GrClose>
          <p className="mb-4 text-main font-bold text-xl">#{collectionModalRental.ref} 加入收款項目</p>
          {
            collectionRows.map((i, index) => getFormInputRow(i, index, collectionData, setCollectionData))
          }
          <button className="mt-4 w-full gradiBtn p-4 specFont shadow-md"
            onClick={createCollection}>確定</button>
        </div>) : ""
      }
    </Modal>

    <Modal
      isOpen={!!rentalModalRental}
      style={customStyles}
      shouldCloseOnOverlayClick={false}
      onRequestClose={() => setRentalModalRental(null)}>
      {
        rentalModalRental ? (rentalLoading ? <LoadingComponent>
          </LoadingComponent> : <div>
          <GrClose className="text-black float-right" onClick={() => setRentalModalRental(null)}></GrClose>
          <p className="mb-4 text-main font-bold text-xl">
            {rentalModalRental._id ? `#${rentalModalRental.ref} 修改` : "新增"}司機資料
          </p>
          {
            rentalRows.map((i, index) => getFormInputRow(i, index, rentalData, setRentalData))
          }
          <button className="mt-4 w-full gradiBtn p-4 specFont shadow-md"
            onClick={createOrUpdateRental}>確定</button>
        </div>) : ""
      }
    </Modal>

    <Modal
      isOpen={!!contractModalRental}
      style={customStyles}
      shouldCloseOnOverlayClick={false}
      onRequestClose={() => setContractModalRental(null)}>
      {
        contractModalRental && <RentalContractForm 
          driver={contractModalRental.payer}
          onFormConfirm={generateContract}
          onClose={() => setContractModalRental(null)}></RentalContractForm>
      }
    </Modal>
  </div>;
};

export default withTranslation()(Rental);