import {
  useNavigate,
  useSearchParams
} from "react-router-dom";

import { useState, useEffect, useContext } from "react";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import Modal from 'react-modal';
import { BsWhatsapp } from 'react-icons/bs';
import { FaMoneyBill } from 'react-icons/fa';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import LoadingComponent from "../../compontents/LoadingComponent";
import { AlertMessageContext, UserInfoContext } from "../../context";
import { useAnalyticsEventTracker } from "../../customHook";
import { gUserType, gUserTypePrivate, gOwnerWhatsapp, gTaxiImg } from '../../global/constants';
import ReactPixel from 'react-facebook-pixel';

Modal.setAppElement('#root');

const customStyles = {
  overlay: {
    padding: '3rem 1rem',
    zIndex: 99,
    touchAction: 'none'
  },
  content: {
    inset: 'unset',
    position: 'relative',
    width: '100%',
    maxHeight: '100%',
    padding: '0px',
    overflowY: 'scroll',
    touchAction: 'none'
  },
};

const ownerCarRents = [
  {
    type: '4seat',
    color: 'red',
    price: 17000
  }, {
    type: '5seat',
    color: 'red',
    price: 15000
  }, {
    type: 'hybrid',
    color: 'red',
    price: 24000
  }
];

const Register = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const setAlertMessage = useContext(AlertMessageContext);
  const [_, dispatchUserInfo] = useContext(UserInfoContext);
  var passwordValidator = require('password-validator');
  const gaEventTracker = useAnalyticsEventTracker("Register");
  const { t } = useTranslation();

  const [registerStep, setRegisterStep] = useState(0);
  const [secondTimer, setSecondTimer] = useState(0);

  const queryType = searchParams.get("type");
  const initialUserType = (queryType ? (gUserType[parseInt(queryType)] || {}).title : "");
  const [userType, setUserType] = useState(() => {
    return initialUserType || gUserType[0].title;
  })

  const [loading, setLoading] = useState(false);
  const [ownerModalOpen, setOwnerModalOpen] = useState(initialUserType === gUserTypePrivate);

  const [OTPcode, setOTPcode] = useState(
    {
      title: "電話驗證碼",
      content: "",
      placeholder: "電話驗證碼"
    })

  const [sendData, setSendData] = useState([
    {
      title: "電話號碼",
      content: "",
      placeholder: "電話號碼",
      autocomplate: "username"
    },
    {
      title: "密碼",
      content: "",
      placeholder: "密碼",
      autocomplate: "new-password"
    },
    {
      title: "名稱",
      content: "",
      placeholder: "名稱"
    },
    {
      title: "車行名稱",
      content: "",
      placeholder: "車行名稱"
    },
  ]);

  useEffect(() => {
    ReactPixel.pageView();
  }, []);

  function sendOTPChange(value) {

    OTPcode.content = value
    setOTPcode({ ...OTPcode })

  }

  function sendDataChange(index, value) {

    sendData[index].content = value
    setSendData([...sendData])

  }

  function getFormData() {
    return {
      "phone": sendData[0].content,
      "password": sendData[1].content,
      "name": sendData[2].content,
      "supplier": userType === gUserType[0].title ? sendData[3].content : "",
      "otp": OTPcode.content,
      "type": userType
    };
  }

  const phoneSchema = new passwordValidator();
  phoneSchema.is().min(8)
  phoneSchema.is().max(8)
  phoneSchema.has().not().uppercase()
  phoneSchema.has().not().lowercase()
  phoneSchema.has().digits(8)
  phoneSchema.has().not().spaces()

  const pwSchema = new passwordValidator();
  pwSchema.is().min(1)
  pwSchema.has().not().spaces()

  function sendOTP() {
    gaEventTracker("otp", "attempt");
    if (!phoneSchema.validate(sendData[0].content)) {
      gaEventTracker("otp", "failed (incorrect otp)");
      setAlertMessage({ message: "請輸入正確8位數字香港電話號碼" });
      return
    }

    if (!pwSchema.validate(sendData[1].content)) {
      gaEventTracker("register", "failed (incorrect password)");
      setAlertMessage({ message: "請輸入沒有空格的密碼" });
      return
    }

    if (!pwSchema.validate(sendData[2].content)) {
      gaEventTracker("register", "failed (missing name)");
      setAlertMessage({ message: "請輸入名稱" });
      return
    }

    setLoading(true)

    const json = getFormData();
    const api_url = process.env.REACT_APP_API_URL + "/user/check"
    axios.post(api_url, json)
      .then(response => {
        setSecondTimer(60)
        setRegisterStep(1)
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data && error.response.data.message === "phone number already used") {
            gaEventTracker("register", "failed (existed phone number)");
            setAlertMessage({ message: "該電話號碼已被使用" });
            return;
          }

          if (error.response.status === 400) {
            gaEventTracker("register", "failed (invalid phone number)");
            setAlertMessage({ message: "電話號碼不正確" });
            return;
          }

          if (error.response.status === 429) {
            gaEventTracker("register", "failed (retry later)");
            setAlertMessage({ message: "發送驗證碼次數太多，請稍後重試" });
            return;
          }
        }
        gaEventTracker("register", "failed (unknown)");
        setAlertMessage({ message: "發送驗證碼失敗，請檢查號碼後重試" });
      })
      .finally(() => setLoading(false));
  }

  function userRegister() {
    gaEventTracker("register", "attempt");
    if (!phoneSchema.validate(sendData[0].content)) {
      gaEventTracker("register", "failed (incorrect phone)");
      setAlertMessage({ message: "請輸入正確8位數字香港電話號碼" });
      return
    }

    if (!pwSchema.validate(sendData[1].content)) {
      gaEventTracker("register", "failed (missing password)");
      setAlertMessage({ message: "請輸入密碼" });
      return
    }

    if (!pwSchema.validate(sendData[2].content)) {
      gaEventTracker("register", "failed (missing name)");
      setAlertMessage({ message: "請輸入名稱" });
      return
    }

    if (OTPcode.content.length === 0) {
      gaEventTracker("register", "failed (missing otp)");
      setAlertMessage({ message: "請輸入電話驗證碼" });
      return
    }

    setLoading(true)

    const json = getFormData();
    const api_url = process.env.REACT_APP_API_URL + "/user/create"
    axios.post(api_url, json)
      .then(response => {
        const user = response.data.data.user;
        localStorage.setItem('userID', user.id)
        localStorage.setItem('password', json.password)
        if (user.enabled && user.authToken) {
          dispatchUserInfo(user)
          navigate('/userinfo')
        } else {
          navigate('/login')
        }
        gaEventTracker("register", "completed");
        setAlertMessage({
          message: user.enabled ? '註冊成功' : '請聯絡客服認證你的帳號，然後登入',
          callback: () => {
            if (!user.enabled) {
              window.open(`https://wa.me/${gOwnerWhatsapp}?text=我已登記為出租人，請認證帳戶`, '_blank')
            }
          }
        });
      })
      .catch(error => {
        setLoading(false)
        if (error.response 
            && (error.response.data.message === "unfound phone number or unfound otp or time out"
                || error.response.data.message === "password validation failed")) {
          gaEventTracker("register", "failed (incorrect otp)");
          setAlertMessage({ message: "電話驗證碼錯誤或過期" });
        } else {
          gaEventTracker("register", "failed (unknown)");
          setAlertMessage({ message: "註冊失敗" });
        }

      });
  }

  function back() {
    if (registerStep === 0) {
      gaEventTracker("navigate", "home");
      navigate('/login');
    } else {
      setRegisterStep(0);
    }
  }

  useEffect(() => {
    setTimeout(() => {
      secondTimer - 1 < 0 ? setSecondTimer(i => i) : setSecondTimer(i => i - 1)
    }, 1000)
  }, [secondTimer])

  useEffect(() => {
    if (localStorage.getItem('token')) {
      navigate('/userinfo')
    }
  }, [])

  return <div className="pt-2 pb-4 appWidth">
    <div className="mb-2 text-xl text-gray-800 font-bold text-center">
      註冊帳號
    </div>

    <form id="login" onSubmit={e => e.preventDefault()}>
      <div className="flex mb-24">
        {
          registerStep === 0 ? <div className='w-full'>
            {
              initialUserType !== gUserTypePrivate ? <>
                <div className="w-full flex items-center">
                  <div className="mr-2 bg-main bg-opacity-100 h-1 w-4"></div>
                  <div className="px-0 py-2 font-bold text-gray-800 bg-main bg-opacity-0 mb-0.5">
                    用戶身份
                  </div>
                </div>
                <div className="flex flex-wrap mb-4">
                  <RadioGroup row name="type">
                    {
                      gUserType.map((i) => <FormControlLabel
                        key={i.title}
                        label={i.title}
                        value={i.title}
                        checked={i.title === userType}
                        control={<Radio
                          onChange={(e) => setUserType(i.title)}
                        />} />
                      )}
                  </RadioGroup>
                </div>
              </> : <div className="my-2 shadow">
                <img src={require("../../assets/user_private_banner.jpg")} />
              </div>
            }
            {
              sendData.slice(0, userType === gUserType[0].title ? 4 : 3).map((i, index) =>
                <div className='w-full' key={i.title}>
                  <div className="w-full flex items-center">
                    <div className="mr-2 bg-main bg-opacity-100 h-1 w-4"></div>
                    <div className="px-0 py-2 font-bold text-gray-800 bg-main bg-opacity-0 mb-0.5">
                      {i.title}
                    </div>
                  </div>
                  <div className="relative w-full mb-4">
                    <input disabled={registerStep !== 0} 
                      className="p-2.5 w-full bg-gray-50 placeholder-gray-400 rounded-lg border border-gray-400 focus:ring-blue-500 focus:border-blue-500"
                      value={i.content} 
                      type="text" 
                      placeholder={i.placeholder} 
                      autoComplete={i.autocomplete}
                      onChange={(e) => sendDataChange(index, e.target.value)} />
                  </div>
                </div>
              )}
          </div> : <div className='w-full'>
            <div className="w-full flex items-center">
              <div className="mr-2 bg-main bg-opacity-100 h-1 w-4"></div>
              <div className="px-0 py-2 font-bold text-xl text-gray-800 bg-main bg-opacity-0 mb-0.5">
                輸入{OTPcode.title}
              </div>
            </div>

            <div className="relative w-full mb-6">
              <input className="p-2.5 w-full bg-gray-50 placeholder-gray-400 rounded-lg border border-gray-400 focus:ring-blue-500 focus:border-blue-500"
                type="text" 
                value={OTPcode.content}
                placeholder={OTPcode.placeholder}
                onChange={(e) => sendOTPChange(e.target.value)} />
            </div>

            {
              !loading && secondTimer <= 0 ?
                <button className="w-full gradiBtn p-4 specFont shadow-md text-center mb-1" onClick={() => sendOTP()}>
                  獲取電話驗證碼
                </button> : <div className="w-full rounded-lg bg-gray-500 text-white p-4 specFont shadow-md text-center mb-4">
                  {secondTimer}秒後可重發電話驗證碼
                </div>
            }
          </div>
        }
      </div>

      <div className="fixed bottom-0 left-0 w-full px-1">
      {
        !loading && (registerStep === 1 ?
          <div className="mb-1 w-full gradiBtn p-4 specFont shadow-md text-center cursor-pointer" onClick={() => userRegister()}>
            註冊
          </div> : <button className="w-full gradiBtn p-4 specFont shadow-md text-center mb-1" onClick={() => sendOTP()}>
            獲取電話驗證碼
          </button>)
      }

      {
        !loading && <div className="w-full bg-gray-400 rounded-xl text-white p-4 specFont shadow-md text-center cursor-pointer"
          onClick={() => back()}>
          {registerStep === 0 ? "返回登入" : "返回"}
        </div>
      }

      {
        loading && <div className="flex">
          <LoadingComponent></LoadingComponent>
        </div>
      }
      </div>
    </form>
    
    <Modal
      isOpen={ownerModalOpen}
      shouldCloseOnOverlayClick={false}
      style={customStyles}
      onRequestClose={() => setOwnerModalOpen(false)}>
      <p className="px-2 mt-4 text-gray-800 font-bold text-center">
        OKTaxi 幫你一次過比較多間的士車行包租報價，爭取全城最高回報
        <FaMoneyBill className="inline text-green-600 text-2xl ml-0.5"></FaMoneyBill>
      </p>
      
      {
        ownerCarRents.map((data, i) => <div key={i} className="relative border border-gray-400 rounded p-2 mx-3 my-8">
          <div className="mb-2 text-center">
            {t(`carType.${data.type}`)}{t(`carColor.${data.color}`)}
          </div>
          <div style={{ width: "50%", maxWidth: "300px", margin: "0px auto" }}>
            <img className="w-full" src={gTaxiImg(data.color, data.type)} />
          </div>
          <div className="mt-2 text-center flex items-center justify-center">
            每月租金高達<span className="text-red-600 text-xl font-bold ml-1">${data.price}</span>
          </div>
          <div className="absolute -top-1 -right-1 greenBtn p-2 flex items-center justify-center"
            onClick={() => window.open(`https://wa.me/${gOwnerWhatsapp}?text=查詢${t(`carType.${data.type}`)}包租價錢`, '_blank')}>
            <BsWhatsapp className="mr-1"></BsWhatsapp> <span>查詢報價</span>
          </div>
        </div>)
      }
      
      <div className="sticky bottom-0 left-0 right-0 text-center py-4 bg-white">
        <div className="inline-block gradiBtn px-5 py-3 specFont shadow-md text-center"
          onClick={() => setOwnerModalOpen(false)}>
          繼續登記包租
        </div>
      </div>
    </Modal>
  </div>
}

export default Register
