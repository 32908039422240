import { useState, useEffect, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from "react-router-dom";
import { BsWhatsapp, BsTelephone } from 'react-icons/bs';
import axios from "axios";
import CarDetail from '../CarDetail';
import LoadingComponent from "../../compontents/LoadingComponent";
import { AlertMessageContext } from "../../context";
import ReactPixel from 'react-facebook-pixel';
import { gWhatsapp } from '../../global/constants';

const CarRequest = (props) => {
  const navigate = useNavigate();
  const [carUser, setCarUser] = useState(null);
  const [carData, setCarData] = useState(null);
  const [extras, setExtras] = useState(null);
  const [searchParams] = useSearchParams();
  const timeoutRef = useRef(null);
  const { t } = useTranslation();
  const setAlertMessage = useContext(AlertMessageContext);
  useEffect(() => {
      ReactPixel.pageView();
  }, []);

  function tKV(obj) {
    for (const k in obj) {
      return obj[k] !== undefined && obj[k] !== null && obj[k] !== '' ? t(`${k}.${obj[k]}`) : '';
    }
  }

  function back() {
    navigate('/home');
  }

  function getByRequestId(id, retryCount = 0) {
    const json = {
      "requestId": id,
    };
    const api_url = process.env.REACT_APP_API_URL + "/offer/getByRequestId";
    axios.post(api_url, json)
      .then(response => {
        const { user, offer, extras } = response.data.data;
        setCarUser(user);
        setCarData(offer);
        setExtras(extras);
      })
      .catch(error => {
        if (error.response.status === 403 || retryCount >= 5) {
          if (error.response.status === 403) {
            setAlertMessage({ message: '此出租的士已下架' });
          }
          back();
        } else {
          timeoutRef.current = setTimeout(function () {
            getByRequestId(id, retryCount + 1);
          }, 5000);
        }
      });
  }

  function submitAction(action) {
    const json = {
      "requestId": searchParams.get('rid'),
      "action": action,
    };
    const api_url = process.env.REACT_APP_API_URL + "/offer/action";
    axios.post(api_url, json);
  }

  function alertCallback(callback) {
    if (carUser.allowDeposit) {
      return callback();
    }

    setAlertMessage({
      message: '提醒⚠️ 你應在確認出租人身分及簽訂租車合約後，才支付任何形式的按金或費用',
      onClose: callback,
    });
  }

  function call() {
    alertCallback(() => {
      submitAction('call');
      window.open(`tel:+852${carUser.phone}`, '_blank');
    });
  }

  function getDesc() {
    let { ref, carColor, carType, carYear, area, subarea, currentShift, shift, rent } = carData;
    if (extras && extras.district) {
      area = extras.district.join('/');
    }
    return `#${ref} ${tKV({ carColor })}${tKV({ carType })}${carYear ? ` (${carYear})` : ''}\n${area}${subarea || ''} ${tKV({ currentShift })}${tKV({ shift })}${rent ? ` $${rent}` : ''}`;
  }

  function openWhatsappLink(askDeposit) {
    let desc = askDeposit ? `*申請按金擔保*: ${carUser.name || ''} ${carUser.phone}\n${getDesc()}` : `你好${carUser.name || ''}，經OK的查詢${getDesc()}`;
    if (extras) {
      const { weekdays, daysPerWeek, phone, name } = extras;
      if (weekdays || daysPerWeek) {
        desc += "\n";
        if (weekdays.length > 0) {
          desc += `${carData.currentShift === 'part' ? '開' : '休'}${!extras.weekdays.includes(0) ? '星期' : ''}${weekdays.sort().map(d => t(`weekday.${d}`)).join('、')} `;
        }
        if (daysPerWeek && (extras.daysPerWeek > 1 || extras.weekdays.length === 0)) {
          desc += `每星期替${daysPerWeek}日`;
        }
      }
      if (phone) {
        desc += `\n\n聯絡: ${phone} (${name})`
      }
    }
    alertCallback(() => {
      submitAction('whatsapp');
      window.open(`https://wa.me/${askDeposit ? gWhatsapp : ('852' + carUser.phone)}?text=${encodeURIComponent(desc)}`, '_blank');
    });
  }

  function whatsapp() {
    if (carUser.allowDeposit && carData.shift !== 'all') {
      setAlertMessage({
        type: "confirm",
        message: "車主接受OK的按金擔保，無需預繳一筆按金，只需定期每更供款，需要申請嗎？",
        confirmText: "需要",
        cancelText: "不需要",
        callback: () => {
          submitAction('deposit');
          openWhatsappLink(true);
        },
        onCancel: openWhatsappLink
      });
    } else {
      openWhatsappLink();
    }
  }

  useEffect(() => {
    const rid = searchParams.get('rid');
    if (!rid) {
      back();
    } else {
      getByRequestId(rid);
    }
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <div>
      {
        carData ? <CarDetail
          carUser={carUser}
          carData={carData}
          noAction={true}
          back={back}></CarDetail>
          :
          <LoadingComponent></LoadingComponent>
      }
      {
        carUser ? <div className="fixed bottom-0 left-0 right-0 p-1 bg-white z-20">
          {
            !carUser.monthly && !carUser.limited && <div className="text-center text-sm mb-1">
              * 請盡快與車主聯絡，租車後通知我們安排現金回贈
            </div>
          }
          <div>
            <div className="greenBtn p-3 specFont text-xl flex items-center justify-center"
              onClick={whatsapp}>
              <BsWhatsapp className="mr-1"></BsWhatsapp> <span>聯絡出租人</span>
            </div>
          </div>
          {/* <div className="gradiBtn p-3 specFont shadow-md text-xl text-center col-span-5 flex items-center justify-center"
            onClick={call}>
            <BsTelephone className="mr-1"></BsTelephone> 致電
          </div> */}
        </div> : ""
      }
    </div>
  )
};

export default CarRequest;
