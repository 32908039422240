import { useTranslation } from "react-i18next";
import React, { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';
import {
  useNavigate,
  useSearchParams,
  useLocation
} from "react-router-dom";
import Select from 'react-select';
import TheTitle from "../../compontents/TheTitle";
import axios from "axios";
import { Checkbox } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { gRegion, gRegionDistricts, gCarColor, gCarCurrentShift, gCarShift, gCarType, gTaxiImg, gUserTypePrivate, gCarShiftWithoutAll } from "../../global/constants";
import LoadingComponent from "../../compontents/LoadingComponent";
import { Alert } from "../../compontents/Alert";
import { AlertMessageContext, DataListContext, UserDataListContext } from "../../context";
import { useAnalyticsEventTracker } from "../../customHook";
import ReactPixel from 'react-facebook-pixel';

const UserCarDetail = () => {
  const defaultAreaOpt = { label: '全區', value: '' };
  
  const gaEventTracker = useAnalyticsEventTracker("User Car Detail");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const setAlertMessage = useContext(AlertMessageContext);
  const [searchParams] = useSearchParams();
  const [, setSavedDataList] = useContext(DataListContext);
  const [, setSavedUserDataList] = useContext(UserDataListContext);
  const [loading, setLoading] = useState(true);
  const [ref, setRef] = useState('');
  const [userInfo, setUserInfo] = useState(location.state?.user);
  const [region, setRegion] = useState(null);
  const [area, setArea] = useState(null);
  const [carColor, setCarColor] = useState(gCarColor[0].value);
  const [carType, setCarType] = useState(gCarType[0].value);
  const [carShift, setCarShift] = useState(gCarShift[0].value);
  const [carCurrentShift, setCarCurrentShift] = useState(gCarCurrentShift[0].value);
  const [restDays, setRestDays] = useState(new Set());
  const [carYear, setCarYear] = useState("");
  const [deposit, setDeposit] = useState("");
  const [depositRemark, setDepositRemark] = useState("");
  const [rent, setRent] = useState("");
  const [rentRemark, setRentRemark] = useState("");
  const [otherRemark, setOtherRemark] = useState("");
  const carYearInputRef = useRef();
  const regionInputRef = useRef();
  const restDaysInputRef = useRef();
  const rentInputRef = useRef();
  const timeoutRef = useRef(null);
  const isBareOnly = useMemo(() => userInfo?.type === gUserTypePrivate, [userInfo]);
  const isUserLimited = useMemo(() => userInfo?.limited, [userInfo]);
  const offerId = searchParams.get('id') || undefined;
  const [limited, setLimited] = useState(!offerId ? false : undefined);
  
  useEffect(() => {
    ReactPixel.pageView();

    if (!userInfo) {
      const json = {
        "id": searchParams.get('userID'),
      }
      const api_url = process.env.REACT_APP_API_URL + "/user/getByUserId"
      axios.post(api_url, json)
        .then(response => {
          const user = response.data.data.user;
          setUserInfo(user);
        })
        .catch(error => {
          console.log('err when get user:', error)
        });
    }
  }, []);

  useEffect(() => {
    if (userInfo) {
      if (userInfo.type === gUserTypePrivate) {
        setCarShift('bare');
        setCarCurrentShift('');
      }
      if (offerId) {
        setLimited(!!userInfo.limited);
      }
    }
  }, [userInfo]);

  function getByOfferId(retryCount = 0) {
    setLoading(true)

    const json = {
      "offerId": offerId,
    }
    const api_url = process.env.REACT_APP_API_URL + "/offer/getByOfferId"
    axios.post(api_url, json)
      .then(response => {
        const {
          ref, area, carColor, carType, shift, currentShift, dayOpen, dayOff, carYear, deposit, depositRemark, rent, rentRemark, otherRemark,
        } = response.data.data;
        setRef(ref)
        if (gRegionDistricts[area]) {
          setRegion(gRegion.find(r => r.value === area))
          setArea(defaultAreaOpt)
        } else {
          for (const region in gRegionDistricts) {
            if (gRegionDistricts[region].indexOf(area) >= 0) {
              setRegion(gRegion.find(r => r.value === region))
              break
            }
          }
          setArea({
            label: area,
            value: area
          })
        }
        setCarColor(carColor)
        setCarType(carType)
        setCarShift(shift)
        setCarCurrentShift(currentShift)
        setRestDays(new Set(currentShift === "part" ? dayOpen : dayOff))
        setCarYear(carYear)
        setRent(rent || '')
        setRentRemark(rentRemark || '')
        setDeposit(deposit == null ? '' : deposit)
        setDepositRemark(depositRemark || '')
        setOtherRemark(otherRemark || '')
        setLoading(false)
      })
      .catch(error => {
        console.log('err when get offer:', error)
        if (error.response.status === 403 || retryCount >= 5) {
          setAlertMessage({ message: "發生錯誤" });
          navigate(`/userinfo?userID=${searchParams.get('userID') || ''}`);
        } else {
          timeoutRef.current = setTimeout(function () {
            getByOfferId(retryCount + 1);
          }, 5000);
        }
      });
  }

  function update() {
    gaEventTracker("add taxi", "submit");
    const isShiftAll = (carShift === 'all' || carShift === 'bare');
    if (!isShiftAll && (!region || !area)) {
      setAlertMessage({ message: "請選擇地區" });
      regionInputRef.current?.scrollIntoView();
      return;
    }

    if (isShiftAll && !carYear) {
      setAlertMessage({ message: "請輸入年份" });
      carYearInputRef.current?.scrollIntoView();
      return;
    }

    const isCurrentShiftFull = (carCurrentShift === 'full');
    if (!isShiftAll && isCurrentShiftFull && restDays.size === 0) {
      setAlertMessage({ message: "請選擇休息日" });
      restDaysInputRef.current?.scrollIntoView();
      return;
    }

    const rentVal = parseInt(rent);
    if (!rentVal) {
      setAlertMessage({ message: "請輸入租金" });
      rentInputRef.current?.scrollIntoView();
      return;
    }

    if (carShift === 'bare' && rentVal < 10000) {
      setAlertMessage({ message: "包租租金應為5位數或以上" });
      rentInputRef.current?.scrollIntoView();
      return;
    }

    const isCreate = !offerId;
    const userId = searchParams.get('userID');
    const json = {
      "offerId": offerId,
      "userId": userId,
      "carColor": carColor,
      "carType": carType,
      "carYear": String(carYear),
      "shift": carShift,
      "currentShift": isShiftAll ? '' : carCurrentShift,
      "area": isShiftAll ? '' : (area.value || region.value),
      "rent": rentVal,
      "rentRemark": rentRemark,
      "deposit": parseInt(deposit),
      "depositRemark": depositRemark,
      "otherRemark": otherRemark,
      "dayOpen": (isShiftAll || isCurrentShiftFull) ? [] : Array.from(restDays),
      "dayOff": (isShiftAll || !isCurrentShiftFull) ? [] : Array.from(restDays),
    };
    if (isNaN(json.rent)) {
      json.rent = null;
    }
    if (isNaN(json.deposit)) {
      json.deposit = null;
    }
    setLoading(true)
    const api_url = process.env.REACT_APP_API_URL + `/offer/${isCreate ? 'create' : 'setOffer'}`
    axios.post(api_url, json)
      .then(response => {
        setSavedDataList();
        setSavedUserDataList((savedData) => ({
          ...savedData,
          data: undefined,
        }));
        const offer = response.data.data;
        setAlertMessage({
          message: `${isCreate ? '新增' : '更新'}成功${offer.invalidated ? '，我們會盡快審核你的出租資料' : ''}`,
          callback: () => navigate(`/userinfo?userID=${userId || ''}`)
        });
      })
      .catch(error => {
        console.log('err when update:', error)
        if (error.response.status === 403 && error.response.data.message === 'quota exceeded') {
          setAlertMessage({ message: "你的出租項目已經到達上限 請聯絡客服處理" });
        } else {
          setAlertMessage({ message: `${isCreate ? '新增' : '更新'}失敗` });
        }
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      const { pathname, search } = window.location;
      return navigate(`/login?redirect=${encodeURIComponent(pathname + search)}`, { replace: true });
    }

    if (offerId) {
      getByOfferId()
    } else {
      setLoading(false)
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    }
  }, []);

  return <div className="appWidth pt-4 pb-10">
    <TheTitle title={`的士資料 ${loading ? '' : (ref ? `#${ref}` : '新增')}`}></TheTitle>
    {
      !loading && limited !== undefined && userInfo ?
      <div>
        <div style={{width: "50%", maxWidth: "500px", margin: "0px auto"}}>
          <div className="ratio-16/9 rounded-md">
            <div className="ratio-in">
              <img className="w-full h-full object-contain" src={gTaxiImg(carColor, carType)} />
            </div>
          </div>
        </div>
        {
          limited && <p className="pt-2">
            * 限制用戶只能修改租金及按金，如有需要請聯絡客服升級為付費用戶
          </p>
        }

        <div className="pt-2 pb-4">
          <p className="text-gray-500">車種</p>
          <div className="flex flex-row overflow-x-auto mt-1">
            <ul className="rounded-lg border border-gray-500 overflow-hidden flex text-gray-700 divide-x divide-gray-200">
            {
              gCarColor.map((i) => <li key={i.value}
                className={`inline-block h-full px-4 py-1 ${carColor === i.value ? `bg-${!offerId && !limited ? `${i.value}-700` : "gray-400"} text-white` : "bg-white"}`}
                onClick={() => !offerId && !limited && setCarColor(i.value)}>
                {t(`carColor.${i.value}`)}
              </li>)
            }
            </ul>
          </div>

          <p className="text-gray-500 mt-3">車款</p>
          <div className="flex flex-row overflow-x-auto mt-1">
            <ul className="rounded-lg border border-gray-500 overflow-hidden flex text-gray-700 divide-x divide-gray-200">
            {
              gCarType.map((i) => <li key={i.value}
                className={`inline-block h-full px-3 py-1 ${carType === i.value ? `bg-gray-${!offerId && !limited ? 700 : 400} text-white` : "bg-white"}`}
                onClick={() => !offerId && !limited && setCarType(i.value)}>
                {t(`carType.${i.value}`)}
              </li>)
            }
            </ul>
          </div>
          <div ref={carYearInputRef} className="w-full border border-solid border-[#CCCCCC] rounded text-lg bg-white mt-2">
            <input className='px-2 py-1 w-full bg-transparent placeholder-gray-400 text-gray-700'
              disabled={ limited }
              type="text" value={carYear}
              placeholder="年份/字頭"
              onChange={(e) => setCarYear(e.target.value)} />
          </div>

          {
            !isBareOnly && <div>
              <p className="text-gray-500 mt-3">更期</p>
              <div className="flex flex-row overflow-x-auto mt-1">
                <ul className="rounded-lg border border-gray-500 overflow-hidden flex text-gray-700 divide-x divide-gray-200">
                {
                  (isUserLimited ? gCarShiftWithoutAll : gCarShift).map((i) => <li key={i.value}
                    className={`inline-block h-full px-3 py-1 ${carShift === i.value ? `bg-gray-${!offerId && !limited ? 700 : 400} text-white` : "bg-white"}`}
                    onClick={() => !offerId && !limited && setCarShift(i.value)}>
                    {t(`shift.${i.value}`)}
                  </li>)
                }
                </ul>
              </div>
            </div>
          }

          {carShift !== 'all' && carShift !== 'bare' ? <div ref={regionInputRef}>
            <p className="text-gray-500 mt-3">地區</p>
            <div className="grid grid-cols-10">
              <Select
                isDisabled={!!offerId || limited}
                styles={{ menu: base => ({ ...base, zIndex: 9 }) }}
                placeholder="請選擇"
                value={region}
                options={gRegion}
                className="basic-multi-select text-black col-span-5"
                onChange={(r) => [setRegion(r), setArea(null)]}
              />
              {region ? <Select
                isDisabled={ limited }
                styles={{ menu: base => ({ ...base, zIndex: 9 }) }}
                placeholder="請選擇"
                value={area}
                options={[
                  defaultAreaOpt,
                  ...gRegionDistricts[region.value].map(d => ({ label: d, value: d})),
                ]}
                className="basic-multi-select text-black col-span-5"
                onChange={(d) => setArea(d)}
              /> : ""}
            </div>

            <p className="text-gray-500 mt-3">正/替更</p>
            <div className="flex flex-row overflow-x-auto mt-1">
                <ul className="rounded-lg border border-gray-500 overflow-hidden flex text-gray-700 divide-x divide-gray-200">
                {
                  gCarCurrentShift.map((i) => <li key={i.value}
                    className={`inline-block h-full px-4 py-1 ${carCurrentShift === i.value ? `bg-gray-${!offerId && !limited ? 700 : 400} text-white` : "bg-white"}`}
                    onClick={() => !offerId && !limited && [setCarCurrentShift(i.value), setRestDays(new Set())]}>
                    {t(`currentShift.${i.value}`)}
                  </li>)
                }
                </ul>
              </div>

            <p className="text-gray-500 mt-3">{carCurrentShift === 'full' ? '休息日' : '開工日'}</p>
            <div ref={restDaysInputRef} className="flex flex-wrap mb-2">
              {
                Array(8).fill(0).map((_, i) => <FormControlLabel
                  disabled={ limited }
                  key={`restDay${i}`}
                  label={t(`weekday.${i}`)}
                  value={i}
                  control={<Checkbox
                    checked={restDays.has(i)}
                    onChange={(e) => setRestDays(s => {
                      const value = i;
                      if (e.target.checked) {
                        s.add(value)
                      } else {
                        s.delete(value)
                      }
                      return new Set(s);
                    })}
                  />}
                />)
              }
            </div>
          </div> : ""}

          <div ref={rentInputRef} className="grid grid-cols-10 mb-2 mt-3">
            <div className="col-span-3">
              <p className="text-gray-500">{isBareOnly && '期望每月'}租金</p>
              <div className="w-full border border-solid border-[#CCCCCC] rounded bg-white">
                <input className='px-2 py-1 w-full bg-transparent placeholder-gray-400 text-gray-700' type="number" value={rent} onChange={(e) => setRent(e.target.value)} />
              </div>
            </div>

            <div className="col-span-1"></div>

            <div className="col-span-6">
              <p className="text-gray-500">租金備註 (如有)</p>
              <div className="w-full border border-solid border-[#CCCCCC] rounded bg-white">
                <input className='px-2 py-1 w-full bg-transparent placeholder-gray-400 text-gray-700' type="text" value={rentRemark} onChange={(e) => setRentRemark(e.target.value)} />
              </div>
            </div>
          </div>

          {
            !isBareOnly && <div className="grid grid-cols-10 mb-2">
              <div className="col-span-3">
                <p className="text-gray-500">按金</p>
                <div className="w-full border border-solid border-[#CCCCCC] rounded bg-white">
                  <input className='px-2 py-1 w-full bg-transparent placeholder-gray-400 text-gray-700' type="number" value={deposit} onChange={(e) => setDeposit(e.target.value)} />
                </div>
              </div>

              <div className="col-span-1"></div>

              <div className="col-span-6">
                <p className="text-gray-500">按金備註 (如有)</p>
                <div className="w-full border border-solid border-[#CCCCCC] rounded bg-white">
                  <input className='px-2 py-1 w-full bg-transparent placeholder-gray-400 text-gray-700' type="text" value={depositRemark} onChange={(e) => setDepositRemark(e.target.value)} />
                </div>
              </div>
            </div>
          }

          <p className="text-gray-500">{isBareOnly ? '特別要求' : '額外配套'}</p>
          <div className="w-full border border-solid border-[#CCCCCC] rounded bg-white">
            <textarea rows="3" className='px-2 py-1 w-full bg-transparent placeholder-gray-400 text-gray-700' value={otherRemark} onChange={(e) => setOtherRemark(e.target.value)} />
          </div>
          <div className="h-10">
          </div>
        </div>
        {
          searchParams.get('action') && <button className="fixed bottom-0 left-0 w-full gradiBtn p-4 specFont shadow-md z-10"
            onClick={() => update()}>
            確定
          </button>
        }
      </div>
      :
      <LoadingComponent></LoadingComponent>
    }

  </div>

}

export default UserCarDetail
