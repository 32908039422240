import React, { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { useNavigate, useSearchParams, Navigate } from "react-router-dom";
import axios from "axios";
import LoadingComponent from "../../compontents/LoadingComponent";
import { gRegion, gRegionDistricts, gCarColor, gCarTypeForSubPlaza, gCarShiftWithoutAll, gOrderBySubplaza } from "../../global/constants";
import { SubTaxiInfo } from "../../compontents/SubTaxiInfo";
import { Filter } from "../../compontents/Filter";
import { useAnalyticsEventTracker } from "../../customHook";
import jwt_decode from "jwt-decode";
import ReactPixel from 'react-facebook-pixel';
import { SubDataListContext, ScrollPositionContext } from "../../context";
import { isEmpty } from 'lodash';

const SubPlaza = ({ t }) => {
  const navigate = useNavigate();
  const gaEventTracker = useAnalyticsEventTracker("SubPlaza");
  const [searchParams, setSearchParams] = useSearchParams();
  const [savedDataList, setSavedDataList] = useContext(SubDataListContext);
  const [savedScrollPosition, setSavedScrollPosition] = useContext(ScrollPositionContext);
  const loggedIn = !!localStorage.getItem('token');

  const getSearchParams = useCallback((type) => {
    try {
      const param = searchParams.get(type);
      if (type === 'orderBy') {
        const asc = (searchParams.get('asc') === 'true');
        return gOrderBySubplaza.find(({ value }) => value.field === param && value.asc === asc) || gOrderBySubplaza[0];
      }

      if (param) {
        let searchArray = param.split(",");
        switch (type) {
          case "region":
            return searchArray.reduce((accu, option) => {
              if (gRegionDistricts[option]) {
                if (!accu.has(option)) {
                  accu.set(option, []);
                }
              } else {
                const region = gRegion.find(r => gRegionDistricts[r.value].includes(option));
                if (region) {
                  accu.set(region.value, [...(accu.get(region.value) || []), option]);
                }
              }
              return accu;
            }, new Map());

          case "color":
            searchArray = searchArray.filter(option => gCarColor.find(color => color.value === option));
            break;
          case "type":
            searchArray = searchArray.filter(option => gCarTypeForSubPlaza.find(type => type.value === option));
            break;
          case "shift":
            searchArray = searchArray.filter(option => gCarShiftWithoutAll.find(shift => shift.value === option));
            break;
          case "dayOpen":
            searchArray = searchArray.filter(option => [1, 2, 3, 4, 5, 6, 7].find(day => day === parseInt(option))).map(day => parseInt(day));
            break;
        }
        return new Set(searchArray);
      }
    } catch (e) {
      console.error(e);
    }
    return new Set();
  }, []);

  const [isAdmin, setAdmin] = useState(false);
  const [loading, setLoading] = useState(savedDataList === undefined);
  const [scrollFinal, setScrollFinal] = useState(false);
  const [filterRegion, setFilterRegion] = useState(getSearchParams("region"));
  const [filterCarColor, setFilterCarColor] = useState(getSearchParams("color"));
  const [filterCarType, setFilterCarType] = useState(getSearchParams("type"));
  const [filterCarShift, setFilterCarShift] = useState(getSearchParams("shift"));
  const [filterCarCurrentShift, setFilterCarCurrentShift] = useState(getSearchParams("currentShift"));
  const [filterDayOpen, setFilterDayOpen] = useState(getSearchParams("dayOpen"));
  const [filterOrderBy, setFilterOrderBy] = useState(getSearchParams('orderBy'));
  const [reload, setReload] = useState(savedDataList === undefined);
  const [dataList, setDataList] = useState(savedDataList || []);
  const [selectedData, setSelectedData] = useState(null);
  const filterPostSource = useRef(null);

  const isFilterSet = useMemo(() => [
    filterRegion, filterCarColor, filterCarType, 
    filterCarShift, filterCarCurrentShift, filterDayOpen
  ].some(f => !isEmpty(f)), [
    filterRegion, filterCarColor, filterCarType, 
    filterCarShift, filterCarCurrentShift, filterDayOpen
  ]);

  useEffect(() => {
    ReactPixel.pageView();

    const token = localStorage.getItem('token');
    if (token) {
      setAdmin(jwt_decode(token).role === 'admin');
    }
    if (savedScrollPosition) window.scrollTo(0, savedScrollPosition);
    setSavedScrollPosition();
    setSavedDataList();
  }, []);

  useEffect(() => {
    const handleScroll = event => {
        window.document.body.getBoundingClientRect().bottom - window.innerHeight < 100 && searchCar();
    };
    window.document.addEventListener("scroll", handleScroll);

    return () => window.document.removeEventListener("scroll", handleScroll);
  }, [dataList, loading])

  function openModal(enquiry) {
    setSavedScrollPosition(window.pageYOffset);
    setSavedDataList(dataList);
    navigate(`/subplaza/detail?eid=${enquiry._id}`, {
      state: {
        enquiryData: enquiry,
      }
    });
  }

  function resetSearch() {
    gaEventTracker("reset search", "empty result");
    setFilterRegion(new Map());
    setFilterCarColor(new Set());
    setFilterCarType(new Set());
    setFilterCarShift(new Set());
    setFilterCarCurrentShift(new Set());
    setFilterDayOpen(new Set());
  }

  function searchCar(reset = false) {
    if (!reset) {
      if (scrollFinal || loading) {
        return;
      }
    } else {
      setScrollFinal(false)
    }

    setLoading(true)
    setReload(false)
    if (reset) {
      setDataList([])
    }
    const source = axios.CancelToken.source()
    if (filterPostSource.current) {
      filterPostSource.current.cancel()
    }
    filterPostSource.current = source;
    
    const json = {
      "area": filterRegion.size === 0 ? null : Array.from(filterRegion).map(([r, districts]) => {
        if (districts.length === 0) {
          districts = gRegionDistricts[r];
        }
        return [...districts, r];
      }).flat(),
      "carColor": filterCarColor.size === 0 ? null : { $in: Array.from(filterCarColor) },
      "carType": filterCarType.size === 0 ? null : { $in: Array.from(filterCarType) },
      "shift": filterCarShift.size === 0 ? null : { $in: Array.from(filterCarShift) },
      "offset": reset ? 0 : dataList.length,
      "limit": 100
    };
    const api_url = process.env.REACT_APP_API_URL + "/trequest/pending"
    axios.post(api_url, json, {
      cancelToken: source.token
    }).then(response => {
      if (filterPostSource.current !== source) {
        return
      }

      const offers = response.data.data;
      if (offers.length < json.limit) {
        setScrollFinal(true)
      }
      if (reset) {
        setDataList(offers)
      } else {
        setDataList(list => [...list, ...offers])
      }
    }).catch(err => {
      if (filterPostSource.current !== source) {
        return
      }

      console.error('err when search:', err)
      if (dataList.length === 0 || reset) {
        setReload(true)
      }
    }).finally(() => {
      if (filterPostSource.current !== source) {
        return
      }

      setLoading(false)
    });
  }

  useEffect(() => {
    const keys = ["region", "color", "type", "shift"];
    const params = [filterRegion, filterCarColor, filterCarType, filterCarShift].reduce((accu, optSet, i) => {
      if (optSet.size > 0) {
        accu[keys[i]] = Array.from(optSet).join(",");
      }
      return accu;
    }, {});
    const oid = searchParams.get('oid');
    if (oid) {
      params.oid = oid;
    }
    setSearchParams(params);

    savedDataList === undefined && searchCar(true);
}, [filterRegion, filterCarColor, filterCarType, filterCarShift]);

  const onUpdateFilter = (filter) => {
    setFilterRegion(new Map(filter.region));
    setFilterCarColor(new Set([...filter.color].filter(pair => pair[1]).map(pair => pair[0])));
    setFilterCarType(new Set([...filter.type].filter(pair => pair[1]).map(pair => pair[0])));
    setFilterCarShift(new Set([...filter.shift].filter(pair => pair[1]).map(pair => pair[0])));
  }

  useEffect(() => {
      loading && window.scrollTo(0, document.body.scrollHeight);
  }, [loading]);

  return !loggedIn ? <Navigate to="/" /> :
  (
    <div id="home" className="appWidth w-full">
      <Filter
        carType={gCarTypeForSubPlaza}
        carShift={gCarShiftWithoutAll}
        filters={{
          region: filterRegion,
          color: filterCarColor,
          type: filterCarType,
          shift: filterCarShift
        }}
        updateFilter={onUpdateFilter}
      />
      <div className="appWidth mt-1">
        {
          dataList.map(i => <SubTaxiInfo isAdmin={isAdmin}
            info={i}
            key={i._id}
            onSelected={(info) => {
              openModal(info);
              gaEventTracker("taxi info", "view");
            }} />)
        }
        {
          !loading && dataList.length === 0 && !reload ?
            <div className="my-10 text-center">
              <div className="text-gray-700 text-xl">
                - 未有司機資料 -
              </div>
              {isFilterSet ? <button className="greyBtn w-28 p-3 specFont shadow-md mt-6"
                onClick={() => resetSearch()}>
                顯示全部
              </button> : ""}
            </div>
            :
            ""
        }
        {
          !loading && reload ?
            <div className="text-center my-10">
              <div className="text-gray-700 text-xl">
                發生網絡錯誤
              </div>
              <button className="gradiBtn w-28 text-center p-3 mt-6"
                onClick={() => searchCar(true)}>
                重試
              </button>
            </div>
            :
            ""
        }
        {
          !loading ?
            ""
            :
            <LoadingComponent></LoadingComponent>
        }
      </div>
    </div>
  )
}

export default withTranslation()(SubPlaza)
