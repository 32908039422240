import "./TaxiInfo.css";
import { gTaxiImg } from "../global/constants"
import { useTranslation } from "react-i18next";
import { AiOutlineClockCircle, AiOutlineEye } from 'react-icons/ai';
import Moment from 'react-moment';
import { useSearchParams, useNavigate } from "react-router-dom";
import { FaMoneyBill } from "react-icons/fa";

export const TaxiInfo = (props: { isAdmin: boolean, info: Object, onSelected: Function }) => {

  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div onClick={() => props.onSelected(props.info)} key={props.info._id}
      className="grid grid-cols-10 mb-1 bg-main3 rounded-lg cursor-pointer border border-[#cccccc]">
      <div className="ratio-16/9 rounded-md col-span-3">
        <div className={`ratio-in p-1${props.info.request ? " pt-3" : ""}`}>
          {props.info.request && <span className="absolute p-1 bg-whatsapp rounded text-white text-xs left-0 top-0">
            ✓曾聯絡
          </span>}
          <img className="w-full h-full object-contain" src={gTaxiImg(props.info.carColor, props.info.carType)} />
        </div>
      </div>
      <div className="col-span-7 p-2 relative">
        <div className="flex justify-between items-start text-maintext text-base">
          <b className="text-sm">
            #{props.info.ref} {props.isAdmin && props.info.userId ? `(${props.info.user.name} ${props.info.userId})` : ''} {props.info.user.allowDeposit && props.info.shift !== 'all' && <p className="inline leading-none">
              <FaMoneyBill className="inline text-green-600 text-base mr-0.5"></FaMoneyBill>
              <span className="text-yellow-600 text-xs font-semibold">
                按金免預繳
              </span>
            </p>}
          </b>
          {(props.info.tags || []).indexOf("新春特選") >= 0 ? <span className="flex items-center" style={{ height: "0" }}>
            <img width={"70px"} src={require("../assets/special.png")}>
            </img>
          </span> : <span className="flex items-center whitespace-nowrap">
            <AiOutlineClockCircle className="inline mr-1"></AiOutlineClockCircle>
            <Moment fromNow className="text-sm">{props.info.updatedAt}</Moment>
          </span>}
        </div>
        <p className="text-maintext text-base">
          {t(`carType.${props.info.carType}`)}{props.info.carYear ? `(${props.info.carYear})` : ""} {props.info.currentShift ? t(`currentShift.${props.info.currentShift}`) : ''}{t(`shift.${props.info.shift}`)} {props.info.area}
        </p>
        <div className="text-maintext text-base" style={{ paddingRight: "60px" }}>
          租金: <span className="text-black font-extrabold">{props.info.rent ? `$${props.info.rent}` : '待議'}</span> {props.info.rentRemark}
        </div>
        {props.info.otherRemark ? <p className="text-maintext text-sm text-base remark" style={{ paddingRight: "60px" }}>
          + {props.info.otherRemark}
        </p> : ""}
        <div className="taxi_info-view flex items-center">
          <AiOutlineEye className="inline mr-1"></AiOutlineEye>
          <span>{props.info.views || 0}</span>
        </div>
      </div>
    </div>
  )
}
