import { useState } from 'react';

const data = [
  {
    name: '藝影快速沖印公司',
    area: '新界',
    district: '東涌',
    address: '東涌逸東邨逸東商場二樓219號舖',
    price: '隔日取證：\n$130 (1張) / $230 (2張)',
    desc: '電話: 2495 2378\n傳真: 2495 2373\n(星期一至日 10:00-18:00)'
  },
  {
    name: '幻影攝影沖曬器材',
    area: '新界',
    district: '元朗',
    address: '元朗教育路70號豐裕樓地下5號H舖',
    price: '一個工作天取證：\n$60 (1張) / $80 (2張)',
    desc: '電話: 2475 1102\n(星期一、星期三至日10:00-19:30)\n(星期二休息)'
  },
  {
    name: '光明公司',
    area: '新界',
    district: '西貢',
    address: '西貢灣景街14號地下',
    price: '即日取證：\n$80 (1張) / $100 (2張)',
    desc: '電話: 2792 1678\n傳真: 2792 0725\n(星期一至日 08:30 - 20:00)'
  },
  {
    name: '冲晒站',
    area: '新界',
    district: '荃灣',
    address: '荃灣大河道1-17號A慶興樓地下1號舖',
    price: '即日取證：\n$60 (1張) / $80 (2張)',
    desc: '電話: 2940 6010\n傳真: 3489 2260\n(星期一至日 10:00-19:00)\n(公眾假期休息)'
  },
  {
    name: '多彩沖印公司',
    area: '新界',
    district: '屯門',
    address: '屯門青賢街雅都商場25號地下',
    price: '即日取證：\n' +
      '$100 (1張) / $120 (2張)\n' +
      '隔日 (12時後) 取證：\n' +
      '$80 (1張) / $100 (2張)\n' +
      '三天取證：\n' +
      '$60 (1張) / $80 (2張)',
    desc: '電話: 2458 2010\n' +
      '傳真: 2189 7311\n' +
      '(星期一、二及四至六 10:30 - 19:00)\n' +
      '(星期日 11:00-19:00)\n' +
      '(星期三及勞工假期休息)'
  },
  {
    name: '李健駕駛學校有限公司',
    area: '新界',
    district: '上水',
    address: '上水龍豐商場二層10A號舖',
    price: '即日取證：\n$100 (1張) / $150 (2張)\n隔日取證：\n$50 (1張) / $100 (2張)',
    desc: '電話: 2668 6999\n傳真: 2887 0713\n(星期一至六 11:00-20:30)'
  },
  {
    name: '李健駕駛學校有限公司',
    area: '新界',
    district: '元朗',
    address: '元朗大馬路162號聯昇樓10樓C室',
    price: '即日取證：\n$100 (1張) / $150 (2張)\n隔日取證：\n$50 (1張) / $100 (2張)',
    desc: '電話: 2476 6099\n傳真: 2476 6348\n(星期一至六 11:00-20:30)'
  },
  {
    name: '李健駕駛學校有限公司',
    area: '新界',
    district: '屯門',
    address: '屯門屯門西鐵站12-13號舖 (付費區內)',
    price: '即日取證：\n$100 (1張) / $150 (2張)\n隔日取證：\n$50 (1張) / $100 (2張)',
    desc: '電話: 2499 8789\n傳真: 2499 8769\n(星期一至日 11:00-20:30)'
  },
  {
    name: '李健駕駛學校有限公司',
    area: '新界',
    district: '沙田',
    address: '沙田好運中心 3樓 158號舖',
    price: '即日取證：\n$100 (1張) / $150 (2張)\n隔日取證：\n$50 (1張) / $100 (2張)',
    desc: '電話: 2691 6786\n傳真: 2613 2132\n(星期一至六 11:00-20:00)'
  },
  {
    name: '李健駕駛學校有限公司',
    area: '新界',
    district: '荃灣',
    address: '荃灣南豐中心17樓1704室',
    price: '即日取證：\n$100 (1張) / $150 (2張)\n隔日取證：\n$50 (1張) / $100 (2張)',
    desc: '電話: 2499 2277\n傳真: 2499 2010\n(星期一至日 11:00-20:30)'
  },
  {
    name: '沖印城公司',
    area: '新界',
    district: '元朗',
    address: '元朗擊壤路16號崇德樓地下B舖',
    price: '即日取證：\n$60 (1張) / $70 (2張) / $80(3張)\n隔日取證：\n$50 (1張) / $60 (2張) / $70(3張)',
    desc: '電話: 2473 2680\n傳真: 2473 2608\n(星期一至日 10:00-19:00)\n(每月第一個及第三個星期三休息)'
  },
  {
    name: '怡光攝影沖印公司',
    area: '新界',
    district: '粉嶺',
    address: '粉嶺百和路花都廣場地下A65號',
    price: '即日取證：\n$110 (1張) / $155 (2張)\n隔日取證：\n$95 (1張) / $140 (2張)',
    desc: '電話: 2683 1943\n傳真: 2683 1012\n(星期一至日 10:30-19:30)'
  },
  {
    name: '海怡攝影沖印公司',
    area: '新界',
    district: '大圍',
    address: '大圍大圍道55-65號金禧商場1樓125A舖',
    price: '即日取證：\n$120 (1張) / $160 (2張)\n隔日取證 ：\n$100 (1張) / $130 (2張)',
    desc: '電話: 2692 8112\n(星期一至日 10:30-20:30)'
  },
  {
    name: '海怡攝影沖印公司',
    area: '新界',
    district: '粉嶺',
    address: '粉嶺一嗚路15號碧湖花園UG層3A室',
    price: '即日取證：\n$120 (1張) / $160 (2張)\n隔日取證 ：\n$100 (1張) / $130 (2張)',
    desc: '電話: 2256 4406\n(星期一至日 10:30-20:30)'
  },
  {
    name: '晶美攝影沖印',
    area: '新界',
    district: '葵涌',
    address: '葵涌葵富路7-11號葵涌廣場一樓B27舖',
    price: '即日取證：\n$80 (1張) / $110 (2張)',
    desc: '電話: 3426 2121\n' +
      '傳真: 3426 9329\n' +
      '(星期一至三、星期五至日 11:00-15:15, 16:15-20:00)\n' +
      '(星期四休息)'
  },
  {
    name: '新英實業有限公司',
    area: '新界',
    district: '元朗',
    address: '元朗西裕街17號民康大廈地下7號舖',
    price: '隔日取證：\n$30 (1張) / $50 (2張)',
    desc: '電話: 2478 8511\n' +
      '傳真: 2477 7570\n' +
      '(星期一至五 09:00-18:00)\n' +
      '(星期六 09:00-13:00)\n' +
      '(星期日及公眾假期休息)'
  },
  {
    name: '漢鋒沖印影視中心',
    area: '新界',
    district: '大圍',
    address: '隆亨邨隆亨商場214號舖',
    price: '隔日取證：\n$80 (1張) / $110 (2張)',
    desc: '電話: 2602 2823\n傳真: 2997 8598\n(星期一至日 10:00-20:00)'
  },
  {
    name: '漢鋒沖印影視中心',
    area: '新界',
    district: '沙田',
    address: '瀝源邨福海樓2樓S20號舖',
    price: '即日取證：\n$80 (1張) / $110 (2張)',
    desc: '電話: 2997 8598\n傳真: 2997 8598\n(星期一至日 10:00-20:00)'
  },
  {
    name: '漢鋒沖印影視中心',
    area: '新界',
    district: '沙田',
    address: '沙角邨沙角商場201號舖',
    price: '即日取證：\n$80 (1張) / $110 (2張)',
    desc: '電話: 2635 3686\n傳真: 2997 8598\n(星期一至日 10:00-20:00)'
  },
  {
    name: '標準數碼攝影工作室',
    area: '新界',
    district: '東涌',
    address: '新界東涌迎東邨萬有街市地下YT-26號鋪',
    price: '即日取證：\n$100 (1張) / $120 (2張)',
    desc: '電話: 6996 6819\n(星期一至日 09:00-19:00)'
  },
  {
    name: '蔚藍彩色沖印',
    area: '新界',
    district: '天水圍',
    address: '天水圍天秀路8號天一商城3樓3079號舖',
    price: '即日取證：\n$90 (1張) / $110 (2張)',
    desc: '電話: 2868 3638\n傳真: 2868 3132\n(星期一至日 10:30-19:00)'
  },
  {
    name: '蔚藍彩色沖印',
    area: '新界',
    district: '天水圍',
    address: '天水圍新北江商場1 期1樓C18B-19A舖',
    price: '即日取證：\n$90 (1張) / $110 (2張)',
    desc: '電話: 2668 3838\n傳真: 3401 9998\n(星期一至日 10:30-19:00)'
  },
  {
    name: '光時數碼沖印',
    area: '九龍',
    district: '長沙灣',
    address: '長沙灣東京街37-39號恒順大廈地下4號舖',
    price: '隔日取證：\n$80 (1張) / $100 (2張) / $120 (3張)',
    desc: '電話: 2386 7167\n傳真: 2386 7167\n(星期一至四、六及日 10:00 - 19:00)\n(星期五休息)'
  },
  {
    name: '好運駕駛專業學校有限公司',
    area: '九龍',
    district: '旺角',
    address: '旺角亞皆老街86號昌明大廈2樓F室',
    price: '即日取證：\n$120 (1張)\n隔日取證：\n$100 (1張)',
    desc: '電話: 2392 8700\n' +
      '傳真: 2395 2299\n' +
      '(星期一至六 09:30-13:00, 14:00-18:00)\n' +
      '(星期日及公眾假期休息休息)'
  },
  {
    name: '好運駕駛專業學校有限公司',
    area: '九龍',
    district: '深水埗',
    address: '深水埗長沙灣道277號B舖',
    price: '即日取證：\n$120 (1張)\n隔日取證：\n$100 (1張)',
    desc: '電話: 2728 6133\n' +
      '傳真: 2728 1085\n' +
      '(星期一至六 09:30-13:00, 14:00-18:00)\n' +
      '(星期日及公眾假期休息休息)'
  },
  {
    name: '百好攝影沖晒器材公司',
    area: '九龍',
    district: '土瓜灣',
    address: '土瓜灣道315號A地下',
    price: '即日取證：\n$60 (1張) / $80 (2張) / $100 (3張)',
    desc: '電話: 2364 9238\n傳真: 2364 9996\n(星期一至日 10:00-19:30)'
  },
  {
    name: '百譽有限公司',
    area: '九龍',
    district: '九龍灣',
    address: '九龍灣宏開道20號地下A舖',
    price: '即日取證：\n$100 (1張) / $120 (2張)',
    desc: '電話: 2799 8798\n傳真: 3622 1007\n(星期一至六 09:00-19:00)\n(星期日及公眾假期休息)'
  },
  {
    name: '快捷數碼攝影',
    area: '九龍',
    district: '牛頭角',
    address: '牛頭角安基苑商場一樓129號舗',
    price: '即日取證：\n$100 (1張) / $140 (2張)',
    desc: '電話: 2757 7910\n(星期一至日 10:00-19:00)'
  },
  {
    name: '嘉聯車行有限公司',
    area: '九龍',
    district: '黃大仙',
    address: '黃大仙翠鳳街68號地鋪',
    price: '即日取證：\n$80 (1張) / $120 (2張)',
    desc: '電話: 3143 4188\n傳真: 2554 9996\n(星期一至五 09:00-18:00)\n(星期六 09:00-13:00)'
  },
  {
    name: '李健駕駛學校有限公司',
    area: '九龍',
    district: '旺角',
    address: '旺角彌敦道582號信和中心10樓1007室',
    price: '即日取證：\n$100 (1張) / $150 (2張)\n隔日取證：\n$50 (1張) / $100 (2張)',
    desc: '電話: 2390 2666\n傳真: 2770 4068\n(星期一至五 09:00-20:30)\n(星期六、日及公眾假期 11:00-20:30)'
  },
  {
    name: '李健駕駛學校有限公司',
    area: '九龍',
    district: '深水埗',
    address: '深水埗西九龍中心2樓210B室',
    price: '即日取證：\n$100 (1張) / $150 (2張)\n隔日取證：\n$50 (1張) / $100 (2張)',
    desc: '電話: 2388 9566\n傳真: 2360 1362\n(星期一至五 09:00-19:00)\n(星期六 09:00-15:00)'
  },
  {
    name: '李健駕駛學校有限公司',
    area: '九龍',
    district: '深水埗',
    address: '深水埗港鐵站內13號舖',
    price: '即日取證：\n$100 (1張) / $150 (2張)\n隔日取證：\n$50 (1張) / $100 (2張)',
    desc: '電話: 2468 3321\n傳真: 2613 2621\n(星期一至六 09:00-20:30)\n(星期日及公眾假期 11:00-20:30)'
  },
  {
    name: '李健駕駛學校有限公司',
    area: '九龍',
    district: '觀塘',
    address: '觀塘巧明街117號港貿中心2105室',
    price: '即日取證：\n$100 (1張) / $150 (2張)\n隔日取證：\n$50 (1張) / $100 (2張)',
    desc: '電話: 2389 3122\n傳真: 2389 1779\n(星期一至日 11:00-20:30)'
  },
  {
    name: '汽車交通運輸業總工會',
    area: '九龍',
    district: '油麻地',
    address: '油麻地彌敦道499-501號2樓',
    price: '兩個工作天取證(會員)：\n$20 (1張) \n兩個工作天取證(非會員)：\n$100 (1張)',
    desc: '電話: 2782 6630\n傳真: 2332 2083\n(星期一至六 10:00-18:00)\n(星期日及公眾假期休息)'
  },
  {
    name: '欣榮沖印文儀公司',
    area: '九龍',
    district: '土瓜灣',
    address: '土瓜灣馬頭角道33號欣榮花園商場地下26號舖',
    price: '即日取證：\n$80 (1張) / $140 (2張)',
    desc: '電話: 2756 2468\n傳真: 2756 2403\n(星期一至六 11:00-18:00)\n(星期日休息)'
  },
  {
    name: '南京攝影沖印公司',
    area: '九龍',
    district: '深水埗',
    address: '深水埗北河街155號地下B舖',
    price: '即日取證：\n$90 (1張) / $100 (2張)\n隔日取證：\n$80 (1張) / $90 (2張)',
    desc: '電話: 2720 0608\n傳真: 2720 0608\n(星期一至日 09:15-19:00)'
  },
  {
    name: '思勤電腦系統',
    area: '九龍',
    district: '旺角',
    address: '旺角上海街438-444號同珍商業中心15樓1503室',
    price: '即日取證：\n$50 (1張) / $95 (2張)',
    desc: '電話: 2375 6907\n傳真: 2375 6908\n(星期一至五 10:00-13:00, 14:00-19:00)\n(星期六及日休息)'
  },
  {
    name: '美美攝影沖印中心',
    area: '九龍',
    district: '石硤尾',
    address: '石硤尾商場366號地下',
    price: '隔日取證：\n$80 (1張) / $100 (2張)',
    desc: '電話: 2779 9427\n' +
      '傳真: 2779 9427\n' +
      '(星期一至五 10:00-18:00)\n' +
      '(星期六 10:00-17:00)\n' +
      '(星期日及公眾假期 10:00-13:00)'
  },
  {
    name: '祥龍投資(集團)有限公司',
    area: '九龍',
    district: '深水埗',
    address: '深水埗順寧道25B和豐樓地下7號舖',
    price: '即日取證：\n$40 (1張) / $70 (2張)\n隔日取證：\n$30 (1張) / $50 (2張)',
    desc: '電話: 2333 3322\n傳真: 3697 0881\n(星期一至五 11:30-20:10)\n(星期六、日及公眾假期休息)'
  },
  {
    name: '德寶數碼沖印',
    area: '九龍',
    district: '紅磡',
    address: '紅磡寶其利街53號寶利閣地下14號舖',
    price: '即日取證：\n$90 (1張) / $150 (2張)',
    desc: '電話: 2471 8798\n傳真: 2471 8773\n(星期一至日 10:00-20:00)'
  },
  {
    name: '數碼店',
    area: '九龍',
    district: '深水埗',
    address: '深水埗南昌街146號地下B舖',
    price: '即日取證：\n$70 (1張) / $80 (2張)',
    desc: '電話: 6565 0909\n傳真: 3013 8740\n(星期一至日 10:30-20:00)'
  },
  {
    name: '學車易駕駛有限公司',
    area: '九龍',
    district: '尖沙咀',
    address: '尖沙咀麼地道63號好時中心2樓41-43室',
    price: '即日取證：\n$50 (1張) / $80 (2張)',
    desc: '電話: 2442 2882\n傳真: 2153 9990\n(星期一至五 10:00-19:00)\n(星期六及日休息)'
  },
  {
    name: '鏗鏘駕駛學校有限公司',
    area: '九龍',
    district: '深水埗',
    address: '深水埗長沙灣道264號地下A舖',
    price: '7日取證：\n$100 (1張) / $150 (2張)',
    desc: '電話: 2341 1322 \n傳真: 2708 3723\n(星期一至日 11:00-20:00)'
  },
  {
    name: '麗星沖曬公司',
    area: '九龍',
    district: '九龍灣',
    address: '九龍灣常悅道9號企業廣場1期1樓B1-1A舖',
    price: '即日取證：\n$70 (1張) / $110 (2張) / $150 (3張)',
    desc: '電話: 2763 6638\n傳真: 2763 6638\n(星期一至六 08:30-17:00)\n(星期日及公眾假期休息)'
  },
  {
    name: '李健駕駛學校有限公司',
    area: '香港',
    district: '金鐘',
    address: '金鐘金鐘道95號統一中心2樓商場2036室',
    price: '即日取證：\n$100 (1張) / $150 (2張)\n隔日取證：\n$50 (1張) / $100 (2張)',
    desc: '電話: 2577 9921\n傳真: 2528 6369\n(星期一至五 09:00-19:30)\n(星期六 10:00-19:30)'
  },
  {
    name: '李健駕駛學校有限公司',
    area: '香港',
    district: '銅鑼灣',
    address: '銅鑼灣怡和街22號17樓1室',
    price: '即日取證：\n$100 (1張) / $150 (2張)\n隔日取證：\n$50 (1張) / $100 (2張)',
    desc: '電話: 2577 9007\n傳真: 2388 9649\n(星期一至日 11:00-20:30)'
  },
  {
    name: '汽車交通運輸業總工會',
    area: '香港',
    district: '灣仔',
    address: '灣仔軒尼詩道213-219號2樓',
    price: '兩個工作天取證 (會員)：\n$20 (1張) \n兩個工作天取證 (非會員)：\n$100 (1張)',
    desc: '電話: 2519 0110\n傳真: 2598 4698\n(星期一至六 10:00-18:00)\n(星期日及公眾假期休息)'
  },
  {
    name: '動力數碼館',
    area: '香港',
    district: '天后',
    address: '天后銀幕街16C地下',
    price: '即日取證：\n$80 (1張) / $140 (2張)',
    desc: '電話: 2566 7678\n(星期一至五 10:00-20:00)\n(星期六、日及公眾假期 11:00-20:00)'
  },
  {
    name: '創藝沖晒店',
    area: '香港',
    district: '香港仔',
    address: '香港仔華富(一)邨商場平台24號舗',
    price: '隔日取證：\n$100 (1張) / $140 (2張)',
    desc: '電話: 2551 6348\n傳真: 2551 6388\n(星期一至日 10:00-19:00)'
  },
  {
    name: '譽彩有限公司',
    area: '香港',
    district: '筲箕灣',
    address: '筲箕灣天悅廣場 1057-1058號地舖',
    price: '隔日取證：\n$90 (1張) / $160 (2張)',
    desc: '電話: 2535 9982\n傳真: 2535 9280\n(星期一至日 11:00-19:00)'
  }
];

const DriverLicense = () => {
  const [selectedArea, setSelectedArea] = useState("");
  const [locations, setLocations] = useState(data);

  return <div className="py-4 appWidth">
    <p className="text-center font-bold mb-2">
      的士司機證辦理地點
    </p>
    {
      <div className="flex flex-row justify-center overflow-x-scroll">
        {
          [
            "",
            "新界",
            "九龍",
            "香港"
          ].map(area => <span key={area}
            className={`flex justify-center items-center px-4 py-1 rounded-full mr-1 whitespace-nowrap ${area === selectedArea ? "bg-gray-700 text-white" : "bg-white border border-gray-500"}`}
            onClick={() => [setSelectedArea(area), setLocations(!area ? data : data.filter(l => l.area === area))]}>
            {area || "全部"}
          </span>)
        }
      </div>
    }
    <table className="table-auto w-full shadow-lg bg-white">
      <thead>
        <tr>
        {
          [
            "公司",
            "價錢",
          ].map((title, i) => <th key={i} scope="col" className="border border-gray-500 p-1">
            {title}
          </th>)
        }
        </tr>
      </thead>
      <tbody>
      {
        locations.map((item, i) => <tr key={i}>
          <td className="border border-gray-500 p-1">
            <p className="font-semibold">{item.name}</p>
            <p className="text-sm mt-2">地址: {item.address}</p>
            <div className="text-sm mt-2">
              {item.desc.split("\n").map((r, i) => {
                const match = r.replace(/\s*/g, "").match(/電話.*(\d{8})/);
                if (match && match[1]) {
                  return <p key={i}>
                    電話: {<a className="underline text-blue-500" href={`tel:${match[1]}`}>
                      {match[1].substring(0, 4)} {match[1].substring(4)}
                    </a>}
                  </p>;
                }
                return <p key={i}>{r}</p>;
              })}
            </div>
          </td>
          <td className="border border-gray-500 p-1">
            {item.price.split("\n").map((r, i) => <p key={i}>{r}</p>)}
          </td>
        </tr>)
      }
      </tbody>
    </table>
  </div>;
};

export default DriverLicense;