import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams
} from "react-router-dom";
import { useContext } from "react";
import { AiFillHome } from 'react-icons/ai';
import { IoChevronBack } from 'react-icons/io5';
import { gOwnerWhatsapp, gWhatsapp } from '../global/constants';
import { useAnalyticsEventTracker } from "../customHook";
import "./Header.css";

const Header = () => {
  const location = useLocation();
  const gaEventTracker = useAnalyticsEventTracker("Header");
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const isHome = (location.pathname == "/");
  const isNewcomer = (location.pathname == "/newcomer");
  const isCardetail = (location.pathname == "/cardetail");
  const isCarrequest = (location.pathname == "/carrequest");
  const isSubdetail = (location.pathname == "/subplaza/detail");
  const isContract = (location.pathname == "/contract");
  const showBackBtn = (isCardetail || isCarrequest || isSubdetail || isContract
    || location.pathname == "/usercardetail" 
    || location.pathname == "/usertimelyoffer" 
    || location.pathname == "/timelyrequest" 
    || location.pathname == "/subplaza/list" 
    || location.pathname == "/deposit" 
    || location.pathname.startsWith("/newcomer")
  );
  const loggedIn = !!localStorage.getItem('token');

  function goToMember() {
    gaEventTracker("navigate", "userinfo");
    navigate('/userinfo');
  }

  function back() {
    gaEventTracker("navigate", "back");
    if (isNewcomer && searchParams.get('form')) {
      setSearchParams();
    } else if (!loggedIn || isCardetail || isCarrequest) {
      navigate(location.state ? -1 : "/");
    } else {
      if (!location.state) {
        let url = '/userinfo';
        if (isSubdetail) {
          url += '/driver';
        } else if (isContract) {
          url += '/rental';
        }
        const qUserID = searchParams.get('userID');
        if (qUserID) {
          url += `?userID=${qUserID}`;
        }
        navigate(url);
      } else {
        navigate(-1);
      }
    }
  }

  function contactCS() {
    gaEventTracker("whatsapp", "header");
    const isAuth = (location.pathname == "/login" || location.pathname == '/register');
    window.open(`https://wa.me/${isAuth || loggedIn ? gOwnerWhatsapp : gWhatsapp}`, '_blank');
  }

  return <div className="sticky top-0 z-10 w-full">
    <div className="logobg left-0 top-0 w-full px-4 py-1">
      <div id="header-layout" className="relative">
        <div className="h-10 text-white">
          {
            showBackBtn ?
              <IoChevronBack className="w-10 h-full p-1"
                onClick={ back }
              />
              :
              (!isHome &&
                <Link to={'/'} onClick={ () => gaEventTracker("navigate", "home") }>
                  <AiFillHome className="w-full h-full p-1" />
                </Link>
              )
          }
        </div>
        <div>
          <img className="h-8 object-contain" src={require("../assets/logo.png")} />
        </div>
          {
            !showBackBtn &&
              (isHome ?
                <button className="text-white text-xl" onClick={goToMember}>出租</button>
                  :
                <button className="text-white text-xl" onClick={contactCS}>客服</button>)
          }
          { isCardetail && navigator.share && <div className="h-10">
            <button className="flex text-white text-lg items-center gap-x-1"
              onClick={ () => {
                gaEventTracker("share", "car detail");
                navigator.share({
                    title: 'OK的 的士至啱租',
                    text: '我搵到呢架的士可以租!',
                    url: document.location.href
                })
                .catch((error) => console.error('err when share car detail:', error));
              }}>
              <div id="share-icon" /> 分享
            </button>
          </div> }
      </div>
    </div>
  </div>
}
export default Header
