import React, { useState, useEffect, useReducer, useRef } from "react";
import App from './App';
import Header from './compontents/Header'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import ScrollToTop from './compontents/ScrollToTop';
import CarDetail from './pages/CarDetail'
import CarRequest from './pages/CarRequest'
import UserCarDetail from './pages/UserCarDetail'
import TimelyRequest from './pages/TimelyRequest'
import UserInfo from "./pages/UserInfo"
import RentalContract from "./pages/Rental/contract";
import SubPlaza from "./pages/SubPlaza";
import SubPlazaForm from "./pages/SubPlazaForm";
import SubDetail from "./pages/SubDetail";
import Register from "./pages/Register"
import Login from "./pages/Login";
import Home from "./pages/Home";
import { AlertMessageContext, UserDataListContext, DataListContext, SubDataListContext, ScrollPositionContext, UserScrollPositionContext, UserInfoContext } from "./context";
import { Alert } from "./compontents/Alert";
// import Deposit from "./pages/Deposit";
import NewComer from "./pages/NewComer";
import NewPractice from "./pages/NewPractice";
import DriverLicense from "./pages/NewComer/DriverLicense";

export const Content = () => {
  const [alertMessage, setAlertMessage] = useState();
  const [userDataList, setUserDataList] = useState();
  const [dataList, setDataList] = useState();
  const [subDataList, setSubDataList] = useState();
  const [scrollPosition, setScrollPosition] = useState();
  const [userScrollPosition, setUserScrollPosition] = useState();
  const [userInfo, dispatchUserInfo] = useReducer((state, newState) => {
    if (!newState) {
      localStorage.removeItem('token');
    } else if (newState.authToken) {
      localStorage.setItem('token', newState.authToken);
    }
    return newState ? { ...newState } : null;
  }, null);
  const prevUserInfo = useRef();
  const location = useLocation();

  function updateDataListItem(item) {
    setDataList((dataList) => {
      const idx = dataList?.data.findIndex(({ _id }) => _id === item._id);
      if (idx >= 0) {
        const data = [...dataList.data];
        data[idx] = item;
        return {
          ...dataList,
          data
        };
      }
      return dataList;
    });
  }

  useEffect(() => {
    if (!location.pathname.startsWith('/user')) {
      setUserDataList();
    }
  }, [location.pathname])

  useEffect(() => {
    if (!userInfo || !prevUserInfo.current || userInfo.id !== prevUserInfo.current.id) {
      setDataList();
      setScrollPosition();
      setSubDataList();
      setUserDataList();
      setUserScrollPosition();
    }
    prevUserInfo.current = userInfo;
  }, [userInfo]);

  return (
    <AlertMessageContext.Provider value={setAlertMessage}>
      <div className='grid grid-cols-1 w-full mx-auto'>
        <ScrollToTop />
        <div className='w-full mx-auto grid-cols-none'>
          <UserScrollPositionContext.Provider value={[userScrollPosition, setUserScrollPosition]}>
            <UserDataListContext.Provider value={[userDataList, setUserDataList]}>
              <ScrollPositionContext.Provider value={[scrollPosition, setScrollPosition]}>
                <DataListContext.Provider value={[dataList, setDataList, updateDataListItem]}>
                  <SubDataListContext.Provider value={[subDataList, setSubDataList]}>
                    <UserInfoContext.Provider value={[userInfo, dispatchUserInfo]}>
                      <Header />
                      <App />
                      <Routes>
                        <Route exact path="/" element={<Home />}></Route>
                        <Route path="/carrequest" element={<CarRequest />}></Route>
                        <Route path="/cardetail" element={<CarDetail />}></Route>
                        <Route path="/usercardetail" element={<UserCarDetail />}></Route>
                        <Route path="/timelyrequest" element={<TimelyRequest />}></Route>
                        <Route path="/userinfo">
                          <Route path=":tab" element={<UserInfo />} />
                          <Route path="" element={<UserInfo />} />
                        </Route>
                        <Route path="/contract" element={<RentalContract />}></Route>
                        <Route path="/subplaza" element={<SubPlazaForm />}></Route>
                        <Route path="/subplaza/list" element={<SubPlaza />}></Route>
                        <Route path="/subplaza/form" element={<SubPlazaForm />}></Route>
                        <Route path="/subplaza/detail" element={<SubDetail />}></Route>
                        <Route path="/newcomer">
                          <Route path="" element={<NewComer />} />
                          <Route path="license" element={<DriverLicense />} />
                        </Route>
                        {/* <Route path="/deposit" element={<Deposit />}></Route> */}
                        <Route path="/newpractice" element={<NewPractice />}></Route>
                        <Route path="/register" element={<Register />}></Route>
                        <Route path="/login" element={<Login />}></Route>
                        <Route path="*" element={<Navigate replace to="/" />} />
                      </Routes>
                    </UserInfoContext.Provider>
                  </SubDataListContext.Provider>
                </DataListContext.Provider>
              </ScrollPositionContext.Provider>
            </UserDataListContext.Provider>
          </UserScrollPositionContext.Provider>
        </div>
      </div>
      {alertMessage !== undefined && <Alert message={alertMessage.message} 
        type={alertMessage.type || "alert"}
        confirmText={alertMessage.confirmText}
        cancelText={alertMessage.cancelText}
        callback={alertMessage.callback}
        onCancel={alertMessage.onCancel}
        onClose={() => (!alertMessage.onClose || alertMessage.onClose() !== false) && setAlertMessage()} />}
    </AlertMessageContext.Provider>
  )
}
