import React, { useRef, useState, useEffect, useCallback } from "react";
import "./Filter.css";
import { gRegion, gRegionDistricts, gCarColor, gCarCurrentShift, gCarShift, gCarType, gOrderBy, gTaxiImg, gWhatsapp } from "../global/constants";
import { Checkbox } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { AiOutlineClockCircle, AiOutlineDollar } from 'react-icons/ai';
import { BiSortDown, BiSortUp } from 'react-icons/bi';
import { useAnalyticsEventTracker } from "../customHook";

export const Filter = (props: { carType: any[], carShift: any[], orderBy: any[], filters: Object, updateFilter: Function, sort?: Object, updateSorter?: Function, resetTag?: Function }) => {
  const carType = props.carType || gCarType;
  const carShift = props.carShift || gCarShift;
  const orderBy = props.orderBy || gOrderBy;
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const gaEventTracker = useAnalyticsEventTracker("Filter");
  const elemRef = useRef();
  const [opening, setOpening] = useState(-1);
  const [blockTop, setBlockTop] = useState(0);
  const [filter, setFilter] = useState({
    region: new Map(),
    color: new Map(),
    type: new Map(),
    shift: new Map(),
    currentShift: new Map(),
    dayOpen: new Map()
  });

  useEffect(() => {
    setFilter(resetToOriginal());
  }, [props.filters]);

  const resetToOriginal = useCallback(() => {
    return {
      region: new Map(props.filters.region),
      color: new Map(gCarColor.map(color => [color.value, props.filters.color.has(color.value)])),
      type: new Map(carType.map(type => [type.value, props.filters.type.has(type.value)])),
      shift: new Map(carShift.map(shift => [shift.value, props.filters.shift.has(shift.value)])),
      currentShift: new Map(gCarCurrentShift.map(currentShift => [currentShift.value, props.filters.currentShift ? props.filters.currentShift.has(currentShift.value) : false])),
      dayOpen: new Map([1, 2, 3, 4, 5, 6, 7].map(day => [day, props.filters.dayOpen ? props.filters.dayOpen.has(day) : false]))
    }
  }, [props.filters]);

  const clickFilter = useCallback((type) => {
    gaEventTracker("open", ["region", "color/type", "shift/currentShift", "sort"].indexOf(type));
    const close = (opening === type);
    if (close) {
      gaEventTracker("cancel", "filter");
      setFilter(resetToOriginal());
    }
    setOpening(close ? -1 : type);
  }, [opening]);

  const filterContent = () => {
    switch (opening) {
      case 2:
        return <div className="filter-display">
          {gRegion.map((i, index) =>
            <div key={i.value}>
              <FormControlLabel
                label={i.value}
                control={<Checkbox checked={filter.region.has(i.value)} onChange={(e) => {
                  const newRegion = new Map(filter.region);
                  if (newRegion.has(i.value)) {
                    newRegion.delete(i.value);
                  } else {
                    newRegion.set(i.value, []);
                  }
                  const newFilter = { ...filter, region: newRegion };
                  setFilter(newFilter);
                }} />}
              />
              {filter.region.has(i.value) &&
                <div className="filter-details-suboption">
                  <FormControlLabel
                    key={`${i}`} label="不限"
                    control={<Checkbox checked={filter.region.get(i.value)?.length === 0} onChange={(e) => {
                      if (filter.region.get(i.value)?.length !== 0) {
                        const newRegion = new Map(filter.region);
                        newRegion.set(i.value, []);
                        const newFilter = { ...filter, region: newRegion };
                        setFilter(newFilter);
                      }
                    }} />}
                  />
                  {
                    gRegionDistricts[i.value].map(j => (
                      <FormControlLabel
                        key={j} label={j}
                        control={<Checkbox checked={filter.region.get(i.value)?.includes(j)} onChange={(e) => {
                          const regionArr = filter.region.get(i.value)?.slice() || [];
                          const idx = regionArr.findIndex(place => place === j);
                          if (idx >= 0) {
                            regionArr.splice(idx, 1);
                          } else {
                            regionArr.push(j);
                          }
                          const newRegion = new Map(filter.region);
                          newRegion.set(i.value, regionArr);
                          const newFilter = { ...filter, region: newRegion };
                          setFilter(newFilter);
                        }} />}
                      />
                    ))
                  }
                </div>
              }
            </div>
          )}
        </div>;

      case 1:
        return <div>
          <p className="mb-n2">車種</p>
          {gCarColor.map((i, index) =>
            <FormControlLabel
              key={i.value} label={t(`carColor.${i.value}`)}
              control={<Checkbox checked={filter.color.get(i.value)} onChange={(e) => {
                let newColor = new Map(filter.color);
                newColor.set(i.value, !filter.color.get(i.value));
                let newFilter = { ...filter, color: newColor };
                setFilter(newFilter);
              }} />}
            />)
          }
          <p className="mb-n2 mt-1">車款</p>
          {carType.map((i, index) =>
            <FormControlLabel
              key={i.value} label={t(`carType.${i.value}`)}
              control={<Checkbox checked={filter.type.get(i.value)} onChange={(e) => {
                let newType = new Map(filter.type);
                newType.set(i.value, !filter.type.get(i.value));
                let newFilter = { ...filter, type: newType };
                setFilter(newFilter);
              }} />}
            />)
          }
        </div>;

      case 0:
        return <div>
          {(props.filters.currentShift || props.filters.dayOpen) && <p className="mb-n2">更期</p>}
          {carShift.map((i) =>
            <FormControlLabel
              key={i.value} label={t(`shift.${i.value}`)}
              control={<Checkbox checked={filter.shift.get(i.value)} onChange={(e) => {
                let newShift = new Map(filter.shift);
                newShift.set(i.value, !filter.shift.get(i.value));
                let newFilter = { ...filter, shift: newShift };
                setFilter(newFilter);
              }} />}
            />)
          }
          {props.filters.currentShift && <>
            <p className="mb-n2 mt-1">正/替</p>
            {gCarCurrentShift.map((i) => <FormControlLabel
              key={i.value} label={t(`currentShift.${i.value}`)}
              control={<Checkbox checked={filter.currentShift.get(i.value)} onChange={(e) => {
                let newCurrentShift = new Map(filter.currentShift);
                newCurrentShift.set(i.value, !filter.currentShift.get(i.value));
                let newFilter = { ...filter, currentShift: newCurrentShift };
                setFilter(newFilter);
              }} />}
            />)}
          </>}
          {props.filters.dayOpen && <>
            <p className="mb-n2 mt-1">開工日</p>
            {[1, 2, 3, 4, 5, 6, 7].map((i) => <FormControlLabel
              key={i} label={t(`weekday.${i}`)}
              control={<Checkbox checked={filter.dayOpen.get(i)} onChange={(e) => {
                let newDayOpen = new Map(filter.dayOpen);
                newDayOpen.set(i, !filter.dayOpen.get(i));
                let newFilter = { ...filter, dayOpen: newDayOpen };
                setFilter(newFilter);
              }} />}
            />)}
          </>}
        </div>;
        
      case 3:
        return <div>
          {
            orderBy.map(order => <button key={order.key}
              id={props.sort.key === order.key ? "filter-sorter-selected" : ""}
              className="filter-sorter-button"
              onClick={() => {
                gaEventTracker("sort", order.label);
                props.updateSorter(order);
                setOpening(-1);
              }}>
              {order.label}
            </button>)
          }
        </div>;
    }
  }

  useEffect(() => {
    setFilter(resetToOriginal());

    const handleScroll = () => {
      if (elemRef.current) {
        setBlockTop(elemRef.current.getBoundingClientRect().top);
      }
    };
    handleScroll();
    window.document.addEventListener("scroll", handleScroll);

    return () => {
      window.document.removeEventListener("scroll", handleScroll);
    }
  }, []);

  const onCancel = useCallback(() => {
    gaEventTracker("cancel", "backdrop");
    setFilter(resetToOriginal());
    setOpening(-1);
  }, [props.filters]);

  const onConfirm = useCallback(() => {
    const gaFilter = {};
    for (const type in filter) {
      if (type !== "region") {
        gaFilter[type] = Array.from(filter[type]).filter(pair => pair[1]).map(pair => pair[0]);
      }
    }
    gaEventTracker("set", JSON.stringify(gaFilter));
    props.updateFilter(filter);
    setOpening(-1);
  }, [filter])

  const onAllReset = useCallback(() => {
    gaEventTracker("reset", "all");
    const newFilter = {
      region: new Map(),
      color: new Map(),
      type: new Map(),
      shift: new Map(),
      currentShift: new Map(),
      dayOpen: new Map()
    }
    setFilter(newFilter);
    props.updateFilter(newFilter);
    props.resetTag && props.resetTag();
    setOpening(-1);
  }, [])

  const onReset = () => {
    let keys: string[] = [];
    switch (opening) {
      case 0:
        keys = ['shift', 'currentShift', "dayOpen"];
        break;
      case 1:
        keys = ['color', 'type'];
        break;
      case 2:
        keys = ['region'];
        break;
    }
    gaEventTracker("reset", keys.join("/"));
    const newFilter = { ...filter };
    keys.forEach((key) => {
      newFilter[key] = new Map();
    });
    setFilter(newFilter);
    props.updateFilter(newFilter);
    setOpening(-1);
  };

  return (
    <div id="filter" ref={elemRef}>
      <div id="filter-options" style={{ gridTemplateColumns: `repeat(${props.sort ? 4 : 3}, 1fr)` }}>
        <button className={opening === 0 ? "active" : ""} onClick={() => clickFilter(0)} aria-checked={Array.from(filter.shift.values()).includes(true) || Array.from(filter.currentShift.values()).includes(true) || Array.from(filter.dayOpen.values()).includes(true)}>
          <div>更期</div>
          <div className="filter-options-arrow" />
        </button>
        <button className={opening === 1 ? "active" : ""} onClick={() => clickFilter(1)} aria-checked={Array.from(filter.color.values()).includes(true) || Array.from(filter.type.values()).includes(true)}>
          <div>車種</div>
          <div className="filter-options-arrow" />
        </button>
        <button className={opening === 2 ? "active" : ""} onClick={() => clickFilter(2)} aria-checked={Array.from(filter.region.values()).length > 0}>
          <div>地區</div>
          <div className="filter-options-arrow" />
        </button>
        {props.sort && <button className={opening === 3 ? "active" : ""} onClick={() => clickFilter(3)}>
          <div>{props.sort.label}</div>
          <BiSortDown></BiSortDown>
        </button>}
      </div>
      {opening !== -1 && <div id="filter-block" style={{ top: `${blockTop}px` }} onClick={onCancel} />}
      {opening !== -1 &&
        <div id={opening !== 3 ? "filter-details" : "filter-details-sorter"}>
          {filterContent()}
          {opening !== 3 &&
            <div id="filter-content-bottom">
              <button className="filter-content-button grey" type="button" onClick={onAllReset}>全部重設</button>
              <button className="filter-content-button grey" type="button" onClick={onReset}>重設</button>
              <button className="filter-content-button" type="button" onClick={onConfirm}>確定</button>
            </div>
          }
        </div>
      }
    </div>
  )
}
