import { useContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Select from 'react-select';
import { useTranslation } from "react-i18next";
import { AiOutlineInsurance, AiOutlineClockCircle } from 'react-icons/ai';
import { BiMoney, BiTaxi } from 'react-icons/bi';
import axios from "axios";
import jwt_decode from "jwt-decode";
import ReactPixel from 'react-facebook-pixel';
import { AlertMessageContext } from "../../context";
import { useAnalyticsEventTracker } from "../../customHook";
import { gWhatsapp } from "../../global/constants";
import LoadingComponent from "../../compontents/LoadingComponent";
import Modal from 'react-modal';

const customStyles = {
  overlay: {
    padding: '5rem 2rem',
    zIndex: 99,
    touchAction: 'none'
  },
  content: {
    inset: 'unset',
    position: 'relative',
    width: '100%',
    maxHeight: '100%',
    padding: '0px',
    overlay: 'scroll',
    touchAction: 'none'
  },
};

const NewPractice = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const token = localStorage.getItem('token');
  const isAdmin = !!token && (jwt_decode(token).role === 'admin');
  const setAlertMessage = useContext(AlertMessageContext);
  const gaEventTracker = useAnalyticsEventTracker("Deposit Form");
  const [showForm, setShowForm] = useState(false);
  const [isFormModalOpen, setFormModalOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState([{
    name: "name",
    title: "你的姓名",
    shortTitle: "姓名",
    placeholder: "輸入姓名",
    value: (!isAdmin && localStorage.getItem("name") || "")
  }, {
    name: "phone",
    title: "聯絡電話",
    placeholder: "輸入Whatsapp電話號碼",
    value: (!isAdmin && localStorage.getItem("phone") || "")
  }, {
    name: "driveLicense",
    title: "駕駛執照號碼",
    placeholder: "輸入駕駛執照號碼",
    value: ""
  }, {
    name: "carType",
    title: "車款",
    placeholder: "選擇車款",
    type: "select",
    value: null,
    options: ["4seat", "hybrid"].map(i => ({
      label: t(`carType.${i}`),
      value: i
    }))
  }, {
    name: "area",
    title: "地區",
    shortTitle: "地區",
    placeholder: "輸入地區",
    value: ""
  }, {
    name: "date",
    title: "日期",
    shortTitle: "日期",
    placeholder: "輸入日期",
    value: formatDate(new Date(new Date().setDate(new Date().getDate() + (7 - new Date().getDay())))),
    min: new Date(new Date().setDate(new Date().getDate() + (7 - new Date().getDay()))),
    max: new Date(new Date().setDate(new Date().getDate() + (28 - new Date().getDay()))),
    type: "date",
    step: "7"
  }, {
    name: "hours",
    title: "鐘數",
    placeholder: "選擇鐘數",
    value: {
      label: "4小時",
      value: 4
    },
    type: "select",
    options: [4, 5, 6, 7, 8, 9, 10].map(i => ({
      label: `${i}小時`,
      value: i
    }))
  }]);

  function formatDate(date) {
    let dd = date.getDate();
    let mm = date.getMonth() + 1;
    let yyyy = date.getFullYear();

    return `${yyyy}-${mm.toString().padStart(2, "0")}-${dd.toString().padStart(2, "0")}`;
  }

  function onFormDataChanged(index, value) {
    formData[index].value = value;
    setFormData([...formData]);
  }

  function onFormNumberBlurred(index, min, max) {
    let value = formData[index].value;
    if (parseInt(value) === NaN) value = min;
    else if (parseInt(value) < parseInt(min)) value = min;
    else if (parseInt(value) > parseInt(max)) value = max;
    formData[index].value = value;
    setFormData([...formData]);
  }

  function onFormDateBlurred(index, min, max) {
    let value = formData[index].value;
    if (new Date(value) < min) value = formatDate(min);
    else if (new Date(value) > max) value = formatDate(max);
    formData[index].value = value;
    setFormData([...formData]);
  }

  async function submit() {
    const form = {};
    let desc = "*申請登記新手練車*\n";
    if (formData.some(({ name, title, shortTitle, value }) => {
      if (!value) {
        setAlertMessage({ message: `請填寫${title}` })
        return true;
      }
      form[name] = value.value || value;
      desc += `${shortTitle || title}: ${value.label || value}\n`;
    })) {
      return;
    }

    gaEventTracker("apply new practice", "submit");
    setLoading(true);
    try {
      const api_url = process.env.REACT_APP_API_URL + `/form/submit`;
      await axios.post(api_url, {
        type: "short-term",
        data: form,
      });
      window.open(`https://wa.me/${gWhatsapp}?text=${encodeURIComponent(desc)}`, '_blank');

      navigate(location.state ? -1 : '/');
    } catch (error) {
      console.error('err when submit new practice form:', error);
      let message = "登記發生錯誤，請重試";
      if (error.response?.data?.message === "invalid number") {
        message = "請輸入Whatsapp電話號碼";
      }
      setAlertMessage({ message });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    ReactPixel.pageView();
  }, []);

  const getInput = (i, index) => {
    switch (i.type) {
      case "select":
        return (
          <Select
            styles={{ menu: base => ({ ...base, zIndex: 9 }) }}
            menuPlacement="auto"
            menuPortalTarget={document.body}
            placeholder={i.placeholder}
            value={i.value}
            options={i.options}
            className="w-full basic-multi-select text-black col-span-5"
            onChange={(value) => onFormDataChanged(index, value)}
          />
        )
      case "date":
        return (
          <input className="p-2.5 w-full bg-gray-50 placeholder-gray-400 rounded-lg border border-gray-400 focus:ring-blue-500 focus:border-blue-500"
            type="date" step={i.step} max={formatDate(i.max)} min={formatDate(i.min)} placeholder={i.placeholder} value={i.value} onBlur={() => onFormDateBlurred(index, i.min, i.max)} onChange={(e) => onFormDataChanged(index, e.target.value)} />
        )
      default:
        return (
          <input className="p-2.5 w-full bg-gray-50 placeholder-gray-400 rounded-lg border border-gray-400 focus:ring-blue-500 focus:border-blue-500"
            type={i.type || "text"} placeholder={i.placeholder} value={i.value} onChange={(e) => onFormDataChanged(index, e.target.value)} />
        )
    }
  }

  return <div className="pt-4 pb-16 appWidth">
    {
      <>
        <p className="font-semibold text-gray-900 text-lg text-center mb-4">
          登記新手練車
        </p>
        <form id="deposit" onSubmit={e => e.preventDefault()}>
          {
            formData.map((i, index) => <div key={i.name}>
              <div className="flex items-center mb-1.5">
                <div className="mr-2 bg-main h-1 w-4"></div>
                <div className="font-semibold text-sm text-gray-700">
                  {i.title}
                </div>
              </div>
              <div className="mb-5 pl-2">
                {getInput(i, index)}
              </div>
            </div>)
          }
        </form>

        <div className="fixed bottom-0 left-0 right-0 p-1">
          {
            !loading ? <div className="gradiBtn p-3 specFont shadow text-lg text-center"
              onClick={submit}>
              登記
            </div> : <LoadingComponent></LoadingComponent>
          }
        </div>
      </>
    }
    <Modal
      isOpen={isFormModalOpen}
      style={customStyles}
      onRequestClose={() => setFormModalOpen(false)}>
      <div className="p-2">
        <p className="font-semibold text-gray-900 text-lg text-center">
          OKTaxi 新手練車須知
        </p>
        <ul className="mt-6 text-gray-700">
          <li className="flex items-center gap-x-2">
            <BiTaxi className="flex-shrink-0 text-2xl text-red-500"></BiTaxi>
            <span>
              4座每小時$50， 最少租4小時
            </span>
          </li>
          <li className="flex items-center gap-x-2">
            <BiTaxi className="flex-shrink-0 text-2xl text-red-500"></BiTaxi>
            <span>
              混能每小時$70， 最少租4小時
            </span>
          </li>
          <br />
          <li className="flex items-center gap-x-2">
            <BiMoney className="flex-shrink-0 text-2xl text-green-500"></BiMoney>
            <span>
              按金： 以現金或扣信用卡內信用額
            </span>
          </li>
          <li className="flex items-center gap-x-2">
            <AiOutlineClockCircle className="flex-shrink-0 text-2xl"></AiOutlineClockCircle>
            <span>
              攞車時間： 最遲早上 10:00
            </span>
          </li>
          <br />
          <li className="flex items-center gap-x-2">
            <AiOutlineInsurance className="flex-shrink-0 text-2xl text-red-500"></AiOutlineInsurance>
            <span>
              如遇交通意外需報保險需要支付墊底費：混能$20000， 四座$15000
            </span>
          </li>
        </ul>
        <div className="mt-6 text-center">
          <button className="gradiBtn px-4 py-2 specFont" onClick={() => setFormModalOpen(false)}>
            確定
          </button>
        </div>
      </div>
    </Modal>
  </div>;
};

export default NewPractice;