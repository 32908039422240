import React, { useState, useCallback, useEffect } from "react";
import { GrClose } from 'react-icons/gr';
import "./News.css";

export const News = (props: { onClose: () => {}, news: Array<Object> }) => {

    const [page, setPage] = useState(0);
    const [news, setNews] = useState(props.news);

    const updateNewsRead = (id) => {
        const readNewsData = JSON.parse(localStorage.getItem("news") || "[]");
        readNewsData.push({
            id,
            lastRead: (new Date()).valueOf()
        });
        localStorage.setItem("news", JSON.stringify(readNewsData));
    };

    const changePage = useCallback((direction) => {
        setPage((page + news.length + direction) % news.length);
        updateNewsRead(news[page]._id);
    }, [page]);

    useEffect(() => updateNewsRead(news[page]._id));

    return (
        <div id="news">
            <div id="news-container">
                <GrClose id="news-close" onClick={ props.onClose }></GrClose> 
                <div id="news-title">最新消息</div>
                <div id="news-content-date">
                    <div>
                        <div>{ new Date(news[page].createdAt).toLocaleString('default', { month: 'short' }) }</div>
                        <div>{ new Date(news[page].createdAt).getDate() }</div>
                    </div>
                    <div>{ new Date(news[page].createdAt).getFullYear() }</div>
                </div>
                {page > 0 && <button className="news-container-arrow" id="arrow-left" onClick={ () => changePage(-1) } />}
                <div id="news-content">
                    <div id="news-content-title">{ news[page].title }</div>
                    <div dangerouslySetInnerHTML={{ __html: news[page].content }} />
                </div>
                {page < news.length - 1 && <button className="news-container-arrow" id="arrow-right" onClick={ () => changePage(1) } />}
                <button id="news-close_button" type="button" onClick={ props.onClose }>關閉</button>
            </div>
        </div>
    )
}
