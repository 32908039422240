import React, { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import TheTitle from "../../compontents/TheTitle";
import { gTaxiImg, gRegionDistricts, gQueryDistricts } from '../../global/constants';
import axios from "axios";
import { FaMoneyBill } from 'react-icons/fa';
import { GrClose } from 'react-icons/gr';
import { Checkbox } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import LoadingComponent from "../../compontents/LoadingComponent";
import { gWhatsapp, gDomain, formatDate } from '../../global/constants';
import Modal from 'react-modal';
import Select from 'react-select';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import moment from 'moment/min/moment-with-locales';
import { AlertMessageContext, DataListContext } from "../../context";
import { useAnalyticsEventTracker } from "../../customHook";
import jwt_decode from "jwt-decode";
import ReactPixel from 'react-facebook-pixel';
import { isEqual } from 'lodash';
import "./CarDetail.css";
import { BsWhatsapp } from 'react-icons/bs';

const customStyles = {
  overlay: {
    zIndex: 99
  },
  content: {
    width: '100%',
    maxHeight: '80%',
    top: 'auto',
    left: 'auto',
    right: 'auto',
    bottom: '0px',
    overflow: 'scroll'
  },
};

const CarDetail = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const token = localStorage.getItem('token');
  const userId = !!token && jwt_decode(token).sub;
  const isAdmin = !!token && (jwt_decode(token).role === 'admin');
  const [carData, setCarData] = useState(null);
  const [title, setTitle] = useState('');
  const [dataList, setDataList] = useState([]);
  const [district, setDistrict] = useState(new Set());
  const [openOrOffDays, setOpenOrOffdays] = useState(new Set());
  const [daysPerWeek, setDaysPerWeek] = useState(1);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [modalRemark, setModalRemark] = useState('');
  const [searchParams] = useSearchParams();
  const [targetUserId, setTargetUserId] = useState(null);
  const [phone, setPhone] = useState(!isAdmin && localStorage.getItem("phone") || "");
  const [name, setName] = useState(!isAdmin && localStorage.getItem("name") || "");
  const timeoutRef = useRef(null);
  const { t } = useTranslation();
  const setAlertMessage = useContext(AlertMessageContext);
  const [, , updateSavedDataListItem] = useContext(DataListContext);
  const gaEventTracker = useAnalyticsEventTracker("Car Detail");
  const rebateRemark = useMemo(() => {
    if (!carData || carData.shift === 'bare') {
      return "";
    }
    return (carData.user.monthly || carData.user.limited) ? "此為「特選優惠」，並沒有現金回贈" : `✅ 成功簽約回贈$${carData.shift === 'special' || carData.shift === 'all' ? "200" : "100"}💰`;
  }, [carData]);

  let { back, noAction } = props;

  useEffect(() => {
    ReactPixel.pageView();
  }, []);

  function tKV(obj) {
    for (const k in obj) {
      return obj[k] !== undefined && obj[k] !== null && obj[k] !== '' ? t(`${k}.${obj[k]}`) : '';
    }
  }

  function weekdays(days) {
    if (!days.length) {
      return '-';
    }
    
    let desc = '';
    days = days.sort();
    if (!days.includes(0)) {
      desc += '星期';
    }
    return desc + days.map(weekday => tKV({ weekday })).join('、');
  }

  function setData(offer, carUser) {
    offer.dayOpen = offer.dayOpen.sort();
    offer.dayOff = offer.dayOff.sort();
    if (carUser) {
      offer.user = carUser;
    }
    setCarData(offer);
    const {
      ref, area, subarea, carColor, carType, carYear, shift, currentShift, dayOpen, dayOff, deposit, depositRemark, rent, rentRemark, otherRemark, updatedAt, user
    } = offer;
    setTitle(`#${ref} ${tKV({ carColor })} ${tKV({ carType })}${carYear ? ` (${carYear})` : ''}`);

    const data = [
      {
        value: otherRemark && `* ${otherRemark}`
      },
      {
        title: '車主',
        value: user ? `${isAdmin && `${user.phone} ${user.name} (${user.type})` || (shift === 'bare' && user.name || user.type)} ${user.adminRemark || ""}` : ""
      },
      {
        title: '交更',
        value: `${area}${subarea || ''}`
      },
      {
        title: '更期',
        value: `${tKV({ currentShift })}${tKV({ shift })}`
      },
      {
        title: '開工',
        value: weekdays(dayOpen)
      },
      {
        title: '休息',
        value: weekdays(dayOff)
      },
      {
        title: '租金',
        value: `${rent ? `$${rent}` : '待議'} ${rentRemark || ''}`
      },
      {
        title: '按金',
        value: `${deposit != null ? `$${deposit}` : '待議'} ${depositRemark || ''}`
      },
      {
        title: '更新',
        value: moment(updatedAt).format("L")
      },
    ];
    setDataList(data)
  }

  function getByOfferId(offerId, retryCount = 0) {
    const json = {
      "offerId": offerId,
    }
    const api_url = process.env.REACT_APP_API_URL + "/offer/getByOfferId"
    axios.post(api_url, json)
      .then(response => {
        const offer = response.data.data;
        setData(offer);
        updateSavedDataListItem(offer);
        if (offer.request && !carData) {
          navigate(`/carrequest?rid=${offer.request._id}`, {
            state: {},
            replace: true
          });
        }
      })
      .catch(error => {
        if (error.response?.status === 403) {
          setAlertMessage({
            message: '此出租的士已下架',
            callback: () =>
              (back) ? back() : navigate("/", { replace: true })
          });
        } else if (retryCount < 5) {
          timeoutRef.current = setTimeout(function () {
            getByOfferId(offerId, retryCount + 1);
          }, 5000);
        }
      });
  }

  function getDesc(code) {
    const { ref, carColor, carType, carYear, area, currentShift, shift, rent } = carData;
    return `${code ? `[${code}] ` : ""}#${ref} ${tKV({ carColor })} ${tKV({ carType })}${carYear ? ` (${carYear})` : ''}\n` +
      (district.size > 0 || area ? `交更地區: ${district.size > 0 ? Array.from(district).join('/') : area}\n` : "") +
      `更期: ${tKV({ currentShift })}${tKV({ shift })}\n` +
      `租金: ${rent ? `$${rent}` : '待議'}`;
  }

  function checkAllShift(shift) {
    if (!isAdmin && shift === "all") {
      setAlertMessage({ message: `此選項為全月租出，租金以每月日數計算。如要租全日而每星期有假期，請選擇特更。`, callback: () => setIsOpen(true) });
    } else {
      setIsOpen(true);
    }
  }

  function getWhatsappLink(desc) {
    let text = `想查詢: ${desc}\n\n${gDomain}?oid=${carData._id}`;
    if (!isAdmin) {
      text += `&userId=${userId || jwt_decode(localStorage.getItem('anonyToken')).sub || ''}`;
    }
    return `https://wa.me/${gWhatsapp}?text=${encodeURIComponent(text)}`;
  }

  function contactWhatsapp(extras, code) {
    const api_url = process.env.REACT_APP_API_URL + "/offer/submit";
    axios.post(api_url, {
      offer: carData,
      extras,
    });
    let desc = getDesc(code);
    if (extras.weekdays || extras.daysPerWeek) {
      desc += "\n";
      if (extras.weekdays.length > 0) {
        const isPart = (carData.currentShift === 'part');
        desc += `${isPart ? '開' : '休'}${!extras.weekdays.includes(0) ? '星期' : ''}${extras.weekdays.sort().map(d => t(`weekday.${d}`)).join('、')} `;
      }
      if (extras.daysPerWeek && (extras.daysPerWeek > 1 || extras.weekdays.length === 0)) {
        desc += `每星期替${extras.daysPerWeek}日`;
      }
    }
    if (extras.name || extras.phone) {
      desc += `\n\n司機: ${extras.name}\n電話: ${extras.phone}`;
    }
    window.open(getWhatsappLink(desc), '_blank');
  }

  function confirm(noContact) {
    const hasNext = (carData.shift !== 'bare');
    if (isAdmin) {
      if (!noContact) {
        if (carData.user.adminContactLink) {
          return window.open(carData.user.adminContactLink, '_blank');
        }
        return window.open(`https://wa.me/852${carData.user.phone}?text=${encodeURIComponent(getDesc())}`, '_blank');
      }
    } else if (userId === carData.userId) {
      return setAlertMessage({ message: " 這是你放租的車" });
    } else {
      gaEventTracker("rent taxi", "confirm");

      const api_url = process.env.REACT_APP_API_URL + "/offer/submit";
      axios.post(api_url, {
        offer: carData,
        extras: {
          hasNext,
          step: 1
        }
      });
    }

    if (!hasNext) {
      return contactWhatsapp({});
    }
    checkAllShift(carData.shift);
  }

  function confirmStep2() {
    if (!isAdmin) {
      gaEventTracker("rent taxi", "confirm-2");
    }

    const isPart = carData.currentShift === 'part';
    const extras = {
      step: 2,
      weekdays: Array.from(openOrOffDays).sort()
    };
    if (!carData.contactOnly) {
      if (isPart) {
        if (openOrOffDays.size === 0) {
          return setAlertMessage({ message: '請選擇替星期幾' });
        }
        extras.daysPerWeek = daysPerWeek;
      }

      if (district.size > 0) {
        extras.district = Array.from(district);
      } else if (gRegionDistricts[carData.area]) {
        return setAlertMessage({ message: '請選擇交更地區' });
      } else if (gQueryDistricts.indexOf(carData.area) >= 0) {
        return setAlertMessage({ message: '請輸入交更地點' });
      }
    }

    if (!/[456789]\d{7}/.test(phone)) {
      return setAlertMessage({ message: "請輸入正確的WhatsApp聯絡電話號碼" });
    }
    extras.phone = phone;
    localStorage.setItem("phone", phone);

    if (name.length === 0) {
      return setAlertMessage({ message: "請輸入你的稱呼" });
    }
    extras.name = name;
    localStorage.setItem("name", name);

    setModalRemark('');
    setModalLoading(true);
    const api_url = process.env.REACT_APP_API_URL + "/offer/request";
    axios.post(api_url, {
      name,
      userPhone: phone,
      userId: targetUserId,
      offer: carData,
      extras
    }).then(response => {
      const request = response.data.data;
      const url = `carrequest?rid=${request._id}`;
      if (!isAdmin) {
        updateSavedDataListItem({
          ...carData,
          request,
        });
        navigate(`/${url}`, {
          state: {},
          replace: true
        });
      } else {
        setModalRemark(
          `#${carData.ref}\n\n` +
          `${gDomain}${url}\n` +
          "👆🏼 👆🏼 👆🏼 👆🏼 👆🏼 👆🏼 👆🏼\n\n" +
          `即刻禁上面連結聯絡出租人👤${carData.user.name || ''}，我地都通知左佢👌🏻\n\n` +
          `* ${carData.user.limited || carData.user.monthly ? "此為\"特選優惠\"，並沒有現金回贈" : "租車時必須跟對方表明是”OK的陳小姐💁🏻‍♀️介紹”，先會有👍🏻現金回贈💰"}`
        );
      }
    }).catch(error => {
      if (error.response?.status === 403 && !isAdmin) {
        setIsOpen(false);

        const code = error.response.data?.code;
        if (code === "app") {
          setAlertMessage({
            message: "即刻下載「自由替」，每星期都有最新替更租！",
            callback: () => window.open(process.env.REACT_APP_SLASHTAXI_INSTALL_LINK, "_blank")
          });
        } else if (code === "app_active_booking") {
          setAlertMessage({ message: '你已在「自由替」應用程式預約租車，請先完成或取消該預約。' });
        } else if (code === "N") {
          setAlertMessage({ message: "請輸入有Whatsapp的電話號碼" });
        } else {
          setAlertMessage({
            message: "按確認後將Whatsapp到客服查詢",
            callback: () => contactWhatsapp(extras, code)
          });
        }
      } else {
        const code = error.response.data?.code;
        setAlertMessage({
          message: (code === "N" ? "請輸入WhatsApp聯絡電話號碼" : "發生錯誤，請重試")
        });
      }
    }).finally(() => setModalLoading(false));
  }

  useEffect(() => {
    const carData = props.carData || location.state?.carData;
    if (carData) {
      setData(carData, props.carUser);
    }
    const offerId = location.state?.offerId || searchParams.get("oid");
    if (offerId) {
      getByOfferId(offerId);
    } else if (!carData) {
      navigate("/", { replace: true });
    }

    const userId = searchParams.get('userId');
    if (userId) setTargetUserId(userId);

    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  const onPhoneInput = useCallback((input) => {
    setPhone(input.target.value.replace(/\D/g, ""));
  }, [phone]);
  const onNameInput = useCallback((input) => {
    setName(input.target.value);
  }, [name]);

  useEffect(() => {
    if (isAdmin && carData?.user.adminRemark) {
      setAlertMessage({ message: carData.user.adminRemark });
    }
  }, [carData]);

  useEffect(() => {
    if (modalIsOpen && carData.currentShift === "part") {
      if (carData.dayOpen.length === 1) {
        setDaysPerWeek(1);
        setOpenOrOffdays(new Set(carData.dayOpen));
      }
    }
  }, [modalIsOpen]);

  return (
    <div className="w-full bg-white">
      <div className={`appWidth pt-4 pb-${!isAdmin ? 32 : 60}`}>
        <div className="relative">
          <TheTitle className="pl-4 mb-4" title={title} views={carData && carData.views || null}></TheTitle>
          {
            carData ?
              <div>
                <div style={{ width: "50%", maxWidth: "300px", margin: "0px auto" }}>
                  <div className="ratio-16/9 rounded-md col-span-4">
                    <div className="ratio-in">
                      <img className="w-full h-full object-contain mb-4" src={gTaxiImg(carData.carColor, carData.carType)} />
                    </div>
                  </div>
                </div>
                {
                  dataList.map((i, index) => i.value ? <div key={index} className="border-b border-solid border-gray-200 overflow-hidden">
                    <div className="col-span-2 px-5 py-3">
                      <div className="flex flex-row items-center">
                        {i.title ? <div>
                          <p className="text-gray-500 whitespace-nowrap mr-2">{i.title}</p>
                        </div> : ''}
                        {i.title === '按金' && carData.user.allowDeposit && carData.shift !== 'all' ? <>
                          <FaMoneyBill className="inline text-green-600 text-2xl mr-1"></FaMoneyBill>
                          <span className="text-yellow-600 font-semibold">
                            按金免預繳
                          </span>
                        </> : <div className="text-black text-lg">
                          {i.value.split("\n").map((r, i) => <p key={i}>{r}</p>)}
                        </div>}
                      </div>
                    </div>
                  </div> : '')
                }
                {
                  !noAction ? <div className="fixed bottom-0 left-0 right-0 p-1 bg-white">
                    {
                      isAdmin ? <div className="gradiBtn p-3 specFont shadow-md text-xl text-center mb-1"
                        onClick={() => confirm(true)}>
                        確認司機租車
                      </div> : <p className="p-1 text-center">
                        {rebateRemark}
                      </p>
                    }
                    <div className="gradiBtn p-4 specFont shadow-md text-xl text-center"
                      onClick={() => confirm(false)}>
                      {isAdmin && carData.userId ? (
                        carData.user ?
                          `${carData.user.monthly ? "(月費)" : (carData.user.limited ? "(限制)" : "")}${carData.user.name} ${carData.user.phone}` :
                          '-'
                      ) : (carData.shift === "bare" ? '托管包租' : '租的士')}
                    </div>
                  </div> : ""
                }
              </div>
              :
              <LoadingComponent></LoadingComponent>
          }
          <Modal
            isOpen={modalIsOpen}
            style={customStyles}
            onRequestClose={() => setIsOpen(false)}>
            <GrClose className="text-black float-right" onClick={() => [setIsOpen(false), gaEventTracker("rent taxi", "cancel(part)")]}></GrClose>
            {modalLoading || !carData ?
              <LoadingComponent></LoadingComponent>
              :
              (modalRemark ?
                <textarea className="w-full bg-gray-100 p-1" rows={5} defaultValue={modalRemark}>
                </textarea>
                :
                <div className="mt-2">
                  {!isAdmin && <button className="fixed flex items-center top-16 bg-gray-200 rounded-lg p-2"
                    onClick={() => window.open(`https://wa.me/${gWhatsapp}?text=${encodeURIComponent(`租的士問題:\n${window.location.href}`)}`, '_blank')}>
                    <BsWhatsapp></BsWhatsapp>&nbsp;有疑問？按此聯絡客服
                  </button>}
                  {
                    !carData.contactOnly && gRegionDistricts[carData.area] ? <>
                      <p>交更地區</p>
                      {
                        gRegionDistricts[carData.area].map((dis, i) => <FormControlLabel
                          key={dis}
                          label={dis}
                          control={<Checkbox
                            checked={district.has(dis)}
                            onChange={(e) => {
                              const disSet = new Set(district);
                              if (e.target.checked) {
                                disSet.add(dis);
                              } else {
                                disSet.delete(dis);
                              }
                              setDistrict(disSet);
                            }}
                          />}
                        />)
                      }
                    </> : ""
                  }
                  {
                    !carData.contactOnly 
                      && !gRegionDistricts[carData.area] 
                      && gQueryDistricts.indexOf(carData.area) >= 0 
                      && <div className="mb-2">
                      <p>期望交更地點</p>
                      <input type="text" className="text-input" 
                        placeholder={`輸入${carData.area}區內的地點`}
                        onChange={(e) => setDistrict(new Set([e.target.value]))} />
                    </div>
                  }
                  {
                    !carData.contactOnly && (carData.currentShift === 'part' ? <>
                      <p className="mt-1">
                        一星期替
                      </p>
                      <RadioGroup row
                        name="daysPerWeek"
                        value={daysPerWeek}>
                        {Array(carData.dayOpen.length 
                            && carData.dayOpen.length < 5 ? carData.dayOpen.length : 5).fill(0).map((_, i) => <FormControlLabel
                          key={`daysPerWeek${i}`}
                          label={t(`${i + 1}日`)}
                          value={i + 1}
                          control={<Radio
                            onChange={(e) => setDaysPerWeek(parseInt(e.target.value))}
                          />}
                        />)}
                      </RadioGroup>
                      <p className="mt-1">請選擇可以替的日子</p>
                      {
                        (carData.dayOpen.length > 0 ?
                          carData.dayOpen : Array(8).fill(0).map((_, i) => i)).map(i => <FormControlLabel
                            key={`subDay${i}`}
                            label={t(`weekday.${i}`)}
                            control={<Checkbox
                              checked={openOrOffDays.has(i)}
                              onChange={(e) => {
                                const days = new Set(openOrOffDays);
                                if (e.target.checked) {
                                  days.add(i);
                                } else {
                                  days.delete(i);
                                }
                                setOpenOrOffdays(days);
                              }}
                            />}
                          />)
                      }
                    </> : (carData.shift !== 'all' && <>
                      <p className="mt-1">休息日</p>
                      {
                        (carData.dayOff.length > 0 ?
                          carData.dayOff : Array(8).fill(0).map((_, i) => i)).map(i => <FormControlLabel
                            key={`restDay${i}`}
                            label={t(`weekday.${i}`)}
                            control={<Checkbox
                              checked={openOrOffDays.has(i)}
                              onChange={(e) => {
                                const days = new Set(openOrOffDays);
                                if (e.target.checked) {
                                  days.add(i);
                                } else {
                                  days.delete(i);
                                }
                                setOpenOrOffdays(days);
                              }}
                            />}
                          />)
                      }
                    </>))
                  }
                  <div className="mt-1">
                    <div>WhatsApp聯絡電話</div>
                    <input type="tel" maxLength="8" className="text-input" value={phone} onChange={onPhoneInput} />
                  </div>
                  <div className="my-3">
                    <div>你的稱呼</div>
                    <input type="text" className="text-input" value={name} onChange={onNameInput} />
                  </div>
                  {
                    carData.user && <p className="text-sm text-center">
                      {rebateRemark}
                    </p>
                  }
                  <button className="my-1 w-full gradiBtn p-3 specFont shadow-md"
                    onClick={() => confirmStep2()}>
                    確定
                  </button>
                </div>
              )
            }
          </Modal>
        </div>
      </div>
    </div>)
}

export default CarDetail
