import "./Toggle.css";
import React, { useState } from "react";

export const Toggle = (props: { checked: boolean, disabled: boolean, toggleFunc: Function }) => {

  return (
    <span className={`toggle ${props.disabled ? 'disabled' : ''}`}>
      <span className={`toggle_container${props.checked ? " checked" : ""}`} onClick={(e) => props.toggleFunc(!props.checked, e)} />
    </span>
  )
}
