import moment from 'moment'

export let gWhatsapp = '85259856191';

export let gOwnerWhatsapp = '85262856534';

export let gDomain = 'https://app.oktaxi.org/';

export let gUserTypeDealer = "車行";
export let gUserTypePrivate = "車主搵包租";
export let gUserType = [
  {
    title: gUserTypeDealer // 0
  },
  {
    title: "打理人" // 1
  },
  {
    title: "私人車" // 2
  },
  {
    title: "司機搵拍更" // 3
  },
  {
    title: gUserTypePrivate
  }
];

export let gRegion = [
  {
    title: '九龍東',
  },
  {
    title: '九龍城區',
  },
  {
    title: '油尖旺',
  },
  {
    title: '深水埗區',
  },
  {
    title: '葵青荃',
  },
  {
    title: '北區',
  },
  {
    title: '元天屯',
  },
  {
    title: '沙田區',
  },
  {
    title: '大埔區',
  },
  {
    title: '將軍澳區',
  },
  {
    title: '西貢區',
  },
  {
    title: '東涌',
  },
  {
    title: '東區',
  },
  {
    title: '南區',
  },
  {
    title: '中西區',
  },
].map((r, i) => ({
  key: i,
  title: r.title,
  label: r.title,
  value: r.title
}))

export let gRegionDistricts = {
  "九龍東": [
    "彩虹",
    "觀塘",
    "藍田",
    "牛頭角",
    "九龍灣",
    "油塘",
    "秀茂坪",
    "黃大仙",
    "新蒲崗",
    "鑽石山",
    "樂富",
    "慈雲山"
  ],
  "九龍城區": [
    "何文田",
    "啟德",
    "紅磡",
    "土瓜灣",
    "九龍城",
    "石硤尾"
  ],
  "油尖旺": [
    "太子",
    "旺角",
    "油麻地",
    "大角咀",
    "佐敦",
    "尖沙咀"
  ],
  "深水埗區": [
    "深水埗",
    "荔枝角",
    "長沙灣",
    "美孚"
  ],
  "葵青荃": [
    "葵涌",
    "青衣",
    "荔景",
    "荃灣",
    "深井",
    "葵芳",
    "石籬",
    "大窩口"
  ],
  "北區": [
    "粉嶺",
    "上水",
    "打鼓嶺",
    "落馬洲"
  ],
  "元天屯": [
    "屯門",
    "天水圍",
    "元朗"
  ],
  "沙田區": [
    "沙田",
    "大圍",
    "馬鞍山",
    "火炭"
  ],
  "大埔區": [
    "大埔",
    "大埔墟",
    "太和",
    "大學"
  ],
  "將軍澳區": [
    "將軍澳",
    "寶林",
    "坑口",
    "調景嶺",
  ],
  "西貢區": [
    "西貢"
  ],
  "東涌": [
    "東涌"
  ],
  "東區": [
    "鰂魚涌",
    "柴灣",
    "西灣河",
    "北角",
    "天后",
    "炮台山",
    "筲箕灣",
    "太古"
  ],
  "南區": [
    "香港仔",
    "黃竹坑",
    "鴨脷洲"
  ],
  "中西區": [
    "灣仔",
    "西環",
    "堅尼地城",
    "西營盤",
    "上環",
    "跑馬地",
    "中環"
  ]
};

export let gQueryDistricts = [
  "荃灣",
  "沙田",
  "屯門",
  "元朗",
  "大埔"
];

export let gCarColor = [
  {
    id: 0,
    title: '紅',
    value: 'red',
  },
  {
    id: 1,
    title: '綠',
    value: 'green',
  },
  // {
  //   id: 2,
  //   title: '藍',
  //   value: 'blue',
  // },
]

export let gCarType = [
  {
    id: 0,
    title: '四座',
    value: '4seat',
  },
  {
    id: 1,
    title: '五座',
    value: '5seat',
  },
  {
    id: 2,
    title: '混能',
    value: 'hybrid',
  },
  {
    id: 3,
    title: '福特',
    value: 'ford',
  },
  {
    id: 4,
    title: '比亞迪',
    value: 'byd',
  },
  // {
  //   id: 4,
  //   title: '輪椅的士',
  //   value: 'wheel',
  // },
]
export let gCarTypeForSubPlaza = gCarType.filter(s => s.value !== 'ford' && s.value !== 'byd');

export let gCarShift = [
  {
    id: 0,
    title: '早',
    value: 'day',
  },
  {
    id: 1,
    title: '夜',
    value: 'night',
  },
  {
    id: 2,
    title: '特',
    value: 'special',
  },
  {
    id: 3,
    title: '全月全架',
    value: 'all',
  },
]
export let gCarShiftWithoutAll = gCarShift.filter(s => s.value !== 'all');

export let gCarShiftWithoutSpecialAndAll = gCarShiftWithoutAll.filter(s => s.value !== 'special');

export let gCarCurrentShift = [
  {
    id: 0,
    title: '正',
    value: 'full',
  },
  {
    id: 1,
    title: '替',
    value: 'part',
  },
]

export let gOrderBy = [
  {
    key: 0,
    value: {
      field: 'createdAt',
      asc: false
    },
    label: '最近'
  },
  {
    key: 1,
    value: {
      field: 'views',
      asc: false
    },
    label: '熱門'
  },
  {
    key: 2,
    value: {
      field: 'rent',
      asc: true
    },
    label: '最平'
  },
]

export let gUserInfoTabs = [
  {
    key: 'rent',
    title: '出租管理'
  }, {
    key: 'driver',
    title: '搵司機'
  }, {
    key: 'rental',
    title: '收租'
  }, {
    key: 'blacklist',
    title: '黑名單'
  }, {
    key: 'info',
    title: '帳戶資料'
  }
]

export let gUserInfoPrivateTabs = [
  {
    key: 'rent',
    title: '出租管理'
  }, {
    key: 'info',
    title: '帳戶資料'
  }
]

export let gBanks = [
  {
    label: "渣打銀行",
    value: "003"
  }, {
    label: "匯豐銀行",
    value: "004"
  }, {
    label: "花旗銀行(香港)",
    value: "250"
  }, {
    label: "中國建設銀行(亞洲)",
    value: "009"
  }, {
    label: "中國銀行(香港)",
    value: "012"
  }, {
    label: "東亞銀行",
    value: "015"
  }, {
    label: "星展銀行",
    value: "016"
  }, {
    label: "中信銀行(國際)",
    value: "018"
  }, {
    label: "招商永隆銀行",
    value: "020"
  }, {
    label: "恆生銀行",
    value: "024"
  }, {
    label: "上海商業銀行",
    value: "025"
  }, {
    label: "交通銀行",
    value: "027"
  }, {
    label: "大眾銀行",
    value: "028"
  }, {
    label: "華僑永亨銀行",
    value: "035"
  }, {
    label: "大有銀行",
    value: "038"
  }, {
    label: "集友銀行",
    value: "039"
  }, {
    label: "大新銀行",
    value: "040"
  }, {
    label: "創興銀行",
    value: "041"
  }, {
    label: "南洋商業銀行",
    value: "043"
  }, {
    label: "ZA Bank",
    value: "387"
  }, {
    label: "Livi Bank",
    value: "388"
  }, {
    label: "Mox Bank",
    value: "389"
  }, {
    label: "Welab Bank",
    value: "390"
  }, {
    label: "Fusion Bank",
    value: "391"
  }
]

export let gCollectionTypes = [
  {
    label: "租金",
    value: "rent"
  }, {
    label: "按金",
    value: "deposit"
  }, {
    label: "隧道",
    value: "tunnel"
  }, {
    label: "罰款",
    value: "penalty"
  }, {
    label: "墊底",
    value: "excess"
  }, {
    label: "維修",
    value: "repair"
  }, {
    label: "雜項",
    value: "misc"
  }
]

export let gOrderBySubplaza = [
  {
    key: 0,
    value: {
      field: 'createdAt',
      asc: false
    },
    label: '最近'
  },
  {
    key: 1,
    value: {
      field: 'taxiDrivingYears',
      asc: false
    },
    label: '多經驗'
  },
  {
    key: 2,
    value: {
      field: 'taxiDrivingYears',
      asc: true
    },
    label: '少經驗'
  },
]

export const menuData = [
  {
    title: "登入",
    path: "/login",
    scrollId: ""
  },
  {
    title: "首頁",
    path: "/joinevent",
    scrollId: ""
  },
]

export const defaultImg = require('../assets/default_icon.png')

export const ErrorImg = e => {
  e.target.onerror = null;
  e.target.src = require('../assets/default_icon.png')
};

export function gTaxiImg(color, type) {
  try {
    return require(`../assets/taxi/${color}-${type}.png`)
  } catch (_err) {
    return require('../assets/taxi/red-4seat.png');
  }
}

export function timestamp2date(timestamp) {
  return moment(timestamp * 1000).format('YYYY/MM/DD HH:mm')
}
