import { useTranslation } from "react-i18next";
import { useState, useEffect, useCallback, useContext, useMemo, useRef } from 'react';
import {
  Link,
  useNavigate,
  useSearchParams,
  useParams,
} from "react-router-dom";
import LoadingComponent from "../../compontents/LoadingComponent";
import axios from "axios";
import Modal from 'react-modal';
import { get, setWith } from 'lodash';
import { Toggle } from "../../compontents/Toggle";
import { GrClose } from 'react-icons/gr';
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { BsStars } from "react-icons/bs";
import { HiOutlineSwitchHorizontal } from "react-icons/hi";
import { SingleFilter } from "../../compontents/SingleFilter";
import { MyTaxi } from "../../compontents/MyTaxi";
import "./UserInfo.css";
import { AlertMessageContext, UserInfoContext } from "../../context";
import { useAnalyticsEventTracker } from "../../customHook";
import ReactPixel from 'react-facebook-pixel';
import jwt_decode from "jwt-decode";
import {
  gUserTypeDealer,
  gUserTypePrivate,
  gUserInfoTabs,
  gUserInfoPrivateTabs,
  gOwnerWhatsapp,
  gCarShiftWithoutAll,
  gCarCurrentShift,
  gCarColor,
  gCarType,
  gWhatsapp,
} from '../../global/constants';
import { UserDataListContext, UserScrollPositionContext } from "../../context";
import SubPlaza from "../SubPlaza";
import SubPlazaForm from "../SubPlazaForm";
import DriverBlacklist from "../DriverBlacklist";
import Rental from "../Rental";

const customStyles = {
  overlay: {
    zIndex: 99
  },
  content: {
    width: '80%',
    maxHeight: '70%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
const itemPerPage = 20;

Modal.setAppElement('#root');

const UserInfo = () => {
  const gaEventTracker = useAnalyticsEventTracker("User Info");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [savedDataList, setSavedDataList] = useContext(UserDataListContext);
  const [savedScrollPosition, setSavedScrollPosition] = useContext(UserScrollPositionContext);
  const [userInfo, setUserInfo] = useContext(UserInfoContext);
  const { tab } = useParams();
  const activeTab = tab || gUserInfoTabs[0].key;
  const [listLoading, setListLoading] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [listReload, setListReload] = useState(false);
  const [selCar, setSelCar] = useState(null);
  const [dataList, setDataList] = useState(savedDataList?.data || []);
  const [dataAreas, setDataAreas] = useState([]);
  const [modalType, setModalType] = useState(0);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [newMode, setNewMode] = useState(localStorage.getItem("newMode") !== "false");
  const [filter, setFilter] = useState(new Map());
  const [listArea, setListArea] = useState(savedDataList?.area || "");
  const [listInfo, setListInfo] = useState(savedDataList?.info || 0);
  const [showAction, setShowAction] = useState(savedDataList?.action || 0);
  const setAlertMessage = useContext(AlertMessageContext);
  const scrollPosition = useRef(0);
  const token = localStorage.getItem('token');
  const isAdmin = !!token && (jwt_decode(token).role === 'admin');

  useEffect(() => {
    ReactPixel.pageView();
  }, []);

  useEffect(() => {
    if (localStorage.getItem('token') == undefined) {
      const { pathname, search } = window.location;
      return navigate(`/login?redirect=${encodeURIComponent(pathname + search)}`, { replace: true });
    }

    if (!userInfo || activeTab === 'info') {
      getByUserId();
    }
    if (activeTab === 'rent') {
      if (savedScrollPosition) {
        window.scrollTo(0, savedScrollPosition);
      }
      if (!savedDataList?.data) {
        getListedByUserId(dataList.length === 0)
      }
      setSavedScrollPosition();
      setSavedDataList();
    }
  }, [activeTab]);

  useEffect(() => {
    if (activeTab === 'rent') {
      const handleScroll = () => { scrollPosition.current = window.pageYOffset };
      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
        setSavedScrollPosition(scrollPosition.current);
        setSavedDataList((savedData) => ({
          ...savedData,
          data: dataList.length > 0 ? dataList : undefined,
        }));
      }
    }
  }, [dataList]);

  useEffect(() => {
    setSavedDataList((savedData) => ({
      ...savedData,
      area: listArea,
      info: listInfo,
      action: showAction
    }));
  }, [listArea, listInfo, showAction]);

  const onRefChange = useCallback(elem => {
    if (elem && elem.parentElement) {
      const parentRect = elem.parentElement.getBoundingClientRect();
      const parentWidth = parentRect.width;
      const elemRect = elem.getBoundingClientRect();
      const scrollLeft = elemRect.left - parentRect.left;
      if (scrollLeft < 0) {
        elem.parentElement.scrollTo(elem.offsetLeft, 0);
      } else if (scrollLeft + elemRect.width > parentWidth) {
        elem.parentElement.scrollTo(elem.offsetLeft + elemRect.width - parentWidth, 0);
      }
    }
  }, []);

  function switchTab(tab) {
    if (tab === 'driver' && !isAdmin) { 
      if (userInfo?.limited) {
        return setAlertMessage({
          message: "你需要聯絡客服升級至付費計劃",
          callback: () => window.open(`https://wa.me/${gOwnerWhatsapp}?text=查詢付費計劃`)
        });
      }
    }
    let url = `/userinfo/${tab}`;
    const qUserID = searchParams.get('userID');
    if (qUserID) {
      url += `?userID=${qUserID}`;
    }
    navigate(url, { replace: true });
  }

  function logout() {
    gaEventTracker("logout", `attempt`);
    setAlertMessage({ message: "確定登出？", type: "confirm", callback: () => {
      gaEventTracker("logout", "confirm");
      setUserInfo(null);
      navigate('/home');
    } })
  }

  function openModal(type) {
    if (type === 0) {
      gaEventTracker("edit user info", "open panel");
      const sendData = [];
      if (userInfo.type === gUserTypeDealer) {
        sendData.push({
          key: "supplier",
          title: "車行名稱",
          content: userInfo.supplier,
          placeholder: "車行名稱"
        });
      }
      sendData.push({
        key: "name",
        title: "名稱",
        content: userInfo.name,
        placeholder: "名稱"
      }, {
        key: "password",
        title: "如修改密碼，請輸入新密碼",
        content: "",
        placeholder: "新密碼"
      });
      setSendData(sendData);
    } else if (type === 1) {
      gaEventTracker("delete taxi", "attempt");
    }
    setModalType(type);
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const [sendData, setSendData] = useState();

  function sendDataChange(index, value) {
    sendData[index].content = value
    setSendData([...sendData])
  }

  function getByUserId() {
    const json = {
      "id": searchParams.get('userID') || localStorage.getItem('userID'),
    }
    const api_url = process.env.REACT_APP_API_URL + "/user/getByUserId"
    axios.post(api_url, json)
      .then(response => {
        const user = response.data.data.user;
        setUserInfo(user);
      })
      .catch(error => {
        console.log('err when get user:', error)
      });
  }

  function retryGetListedByUserId(action) {
    gaEventTracker(action, "retry");
    setListReload(false)
    setListLoading(false)
    closeModal()
    getByUserId()
    getListedByUserId(true)
  }

  function getListedByUserId(reset = false) {
    if (!reset) {
      if (listLoading) {
        return
      }
    } else {
      setListLoading(true)
      setListReload(false)
      setDataList([])
    }

    const json = {
      "userId": searchParams.get('userID') || localStorage.getItem('userID'),
      "offset": 0
    };
    const api_url = process.env.REACT_APP_API_URL + "/offer/getByUserId"
    axios.post(api_url, json, { timeout: 1000 * 10 })
      .then(response => {
        const offers = response.data.data;
        setDataList(offers);
      })
      .catch(error => {
        console.log('err when get offer by user:', error)
        if (dataList.length === 0 || reset) {
          setListReload(true)
        }
      }).finally(() => {
        setListLoading(false)
      });
  }

  function updateUserInfo() {
      gaEventTracker("edit user info", "submit");
    var passwordValidator = require('password-validator');

    const pwSchema = new passwordValidator();
    pwSchema.has().not().spaces()

    if (!pwSchema.validate(sendData.find(d => d.key === 'password').content)) {
      setAlertMessage({ message: "密碼不能有空格" });
      return
    }

    if (!sendData.find(d => d.key === 'name').content) {
      setAlertMessage({ message: "請輸入名稱" });
      return
    }

    const json = {
      "userId": searchParams.get('userID') || userInfo.id
    };
    sendData.forEach(data => {
      if (data.content) {
        json[data.key] = data.content;
      }
    });
    const api_url = process.env.REACT_APP_API_URL + "/user/update"
    axios.post(api_url, json)
      .then(response => {
        const user = response.data.data.user;
        setUserInfo(user)
        closeModal()
      })
      .catch(error => {
        console.log('err when update user:', error)
        setAlertMessage({
          message: "更新錯誤，請重試"
        });
      });
  }

  function deleteListed() {
    setDelLoading(true)
    gaEventTracker("delete taxi", "confirm");
    const api_url = process.env.REACT_APP_API_URL + "/offer/setDeleted"
    const json = {
      "offerId": selCar._id,
      "deleted": true
    }
    axios.post(api_url, json)
      .then(() => {
        retryGetListedByUserId('deleted taxi')
      })
      .catch(error => {
        console.log('err when delete offer:', error)
        setDelLoading(false)
      });
  }

  function onUserLimited() {
    setAlertMessage({
      message: "請聯絡客服申請",
      type: "confirm",
      callback: () => window.open(`https://wa.me/${gOwnerWhatsapp}?text=申請加放租車`, '_blank')
    });
  }

  function createTaxiInfo() {
    if (userInfo.limited && dataList.length >= 1) {
      onUserLimited();
    } else {
      navigate(`/usercardetail?action=C&userID=${searchParams.get('userID') || ''}`, {
        state: {
          user: userInfo
        }
      });
    }
  }

  function editTaxiInfo(info) {
    if (!isAdmin && info.shift === "bare" && !info.invalidated) {
      return setAlertMessage({ message: `請聯絡客服修改 #${info.ref}` });
    }

    gaEventTracker("edit taxi", "attempt");
    navigate(`/usercardetail?action=U&id=${info._id}&userID=${searchParams.get('userID') || ''}`, {
      state: {
        user: userInfo
      }
    });
  }

  function deleteTaxiInfo(info) {
    if (userInfo.limited) {
      onUserLimited();
    } else {
      setSelCar(info);
      openModal(1);
    }
  }

  const updateTaxiInfo = useCallback(newInfo => {
    const newDataList = dataList.slice();
    let index = newDataList.findIndex(item => item._id === newInfo._id);
    newDataList[index] = { ...newInfo };
    setDataList(newDataList);
  }, [dataList]);

  const enableTaxi = (info, enabled) => {
    if (info.loading) {
      return;
    }

    gaEventTracker("toggle taxi", String(enabled));
    const originalEnabled = info.enabled;
    const newInfo = { ...info, enabled: enabled, loading: true };
    updateTaxiInfo(newInfo);
    const api_url = process.env.REACT_APP_API_URL + "/offer/setEnabled"
    const json = {
      "offerId": info._id,
      "enabled": enabled
    }
    axios.post(api_url, json)
      .then(() => {
        const info = { ...newInfo, loading: false };
        updateTaxiInfo(info);
      })
      .catch(error => {
        console.log("err when offer set enabled:", error);
        updateTaxiInfo({ ...info, enabled: originalEnabled, loading: false });
      });
  }

  function toggleNewMode() {
    localStorage.setItem("newMode", String(!newMode));
    setNewMode(!newMode);
    if (newMode) {
      setFilter(new Map());
    }
  }

  useEffect(() => {
    if (dataList.length === 0) {
      return;
    }

    const areaCounts = dataList.reduce((accu, data) => {
      accu.set(data.area, (accu.get(data.area) || 0) + 1);
      return accu;
    }, new Map());
    const areas = Array.from(areaCounts.keys()).sort();
    if (!areaCounts.has(listArea)) {
      setListArea(areas[0]);
    }
    const data = areas.map(area => ({
      name: area,
      count: areaCounts.get(area),
    }));
    setDataAreas(data);
  }, [dataList]);

  const filterItems = useMemo(() => {
    return dataList.filter(data => {
      for (const [category, options] of filter.entries()) {
        if (!options.has(data[category])) {
          return false;
        }
      }
      return true;
    });
  }, [filter, dataList]);

  const carColorIndices = gCarColor.reduce((accu, c, i) => {
    accu[c.value] = i;
    return accu;
  }, {});
  const carTypeIndices = gCarType.reduce((accu, t, i) => {
    accu[t.value] = i;
    return accu;
  }, {});
  const newFilterItems = useMemo(() => {
    const items = {};
    dataList.forEach(data => {
      if (!listArea) {
        if (data.shift !== 'all' && data.shift !== 'bare') {
          return;
        }
      } else {
        if (data.area !== listArea) {
          return;
        }
      }
      const key = `${data.shift}.${data.currentShift}`;
      const value = get(items, key, []);
      setWith(items, key, [...value, data].sort((a, b) => {
        if (a.carColor !== b.carColor) {
          return carColorIndices[a.carColor] - carColorIndices[b.carColor];
        }
        return carTypeIndices[a.carType] - carTypeIndices[b.carType];
      }));
    });
    return items;
  }, [listArea, dataList]);

  return <div className="pb-18">
    <ul className="fixed bottom-0 left-0 right-0 z-10 rounded-t-md border-t border-l border-r border-[#155e75] overflow-hidden flex font-medium text-center text-gray-700 divide-x divide-gray-200">
    {
      (userInfo && userInfo.type !== gUserTypePrivate ? gUserInfoTabs : gUserInfoPrivateTabs).map(
        (tab, i) => <li key={tab.key} className="w-full">
          <a href="#" 
            className={
              `inline-block w-full h-full break-all px-1 py-3 ${activeTab === tab.key ? 'text-white bg-[#155e75]' : 'bg-white hover:text-gray-700 hover:bg-gray-50'}`
            }
            onClick={() => switchTab(tab.key)}>
            {tab.title}
          </a>
        </li>)
    }
    </ul>
    {
      activeTab === 'info' && 
        (userInfo ? <div className="appWidth mt-2 text-gray-700">
          <p className="my-4">
            {userInfo.name}{userInfo.supplier ? ` (${userInfo.supplier})` : ""}
          </p>
          <p className="my-4">
            類型: {userInfo.type}
          </p>
          <p className="my-4">
            電話: {userInfo.phone}
          </p>
          <p className="my-4">
            會員計劃: {userInfo.limited ? '限制' : (userInfo.monthly ? '月費' : '單次收費')}
          </p>
          <p className="my-4">
            出租數目: {userInfo.offers}
          </p>
          <div className="mt-8 text-center">
            <button className="bg-main px-4 py-2 rounded-lg text-white"
              onClick={() => openModal(0)}>
              <span>修改資料</span>
            </button>
            <div className="text-red-600 font-bold mt-16"
              onClick={logout}>
              登出
            </div>
          </div>
        </div> : <LoadingComponent></LoadingComponent>)
    }
    {
      activeTab === 'driver' && <SubPlaza></SubPlaza>
    }
    {
      activeTab === 'rental' && <Rental></Rental>
    }
    {
      activeTab === 'blacklist' && <DriverBlacklist></DriverBlacklist>
    }
    {
      activeTab === 'rent' && <div className="appWidth mt-2">
        <div id="user_info-header" className="flex justify-between items-center mb-2 relative">
          <div className={`${newMode ? "bg-gray-500" : "bg-[#155e75]"} rounded text-white text-sm px-1.5 py-1 mr-1 shadow`}
            onClick={toggleNewMode}>
            {newMode ? <HiOutlineSwitchHorizontal className="inline">
            </HiOutlineSwitchHorizontal> : <BsStars className="inline">
            </BsStars>}{newMode ? '舊' : '新'}版面
          </div>
          <div className="flex items-center">
            {
              dataList.length && <span className="text-sm text-gray-700 font-bold mr-2">
                共{!newMode ? filterItems.length : dataList.length}項
              </span> || ""
            }
            {
              !newMode && userInfo?.type !== gUserTypePrivate && <div className="mr-2">
                <SingleFilter setFilter={setFilter}
                  data={new Map(
                    ["area", "carColor", "carType", "shift", "currentShift"].map(category => {
                      return [category, new Set(dataList.map(data => data[category]).filter(data => data !== ""))];
                    }).filter(val => val[1].size > 1))
                  }
                />
              </div>
            }
            <div className="bg-main px-3 py-1.5 rounded-lg text-white shadow" onClick={createTaxiInfo}>
              新增
            </div>
          </div>
        </div>
        {
          !listLoading && listReload &&
            <div className="flex w-full h-20">
              <div className="m-auto gradiBtn w-28 cursor-pointer text-center px-4 py-2" onClick={() => retryGetListedByUserId("loading fail") }>
                請重試
              </div>
            </div>
        }
        <div className="mx-auto relative">
        {
          !listLoading && !listReload 
            && newMode 
            && userInfo?.type !== gUserTypePrivate 
            && <div className="flex flex-row overflow-x-scroll">
              {dataAreas.map(data => <span key={data.name}
                ref={listArea === data.name && onRefChange || undefined}
                className={`flex justify-center items-center px-2 py-1 mt-1 rounded-full mr-1 whitespace-nowrap ${listArea === data.name ? "bg-gray-700 text-white" : "bg-white border border-gray-500"}`}
                onClick={() => setListArea(data.name)}>
                {data.name || t('shift.all')} <span className="text-sm">({data.count})</span>
              </span>)}
            </div>
        }
        {
          !listLoading && !listReload && (
            dataList.length === 0 ? <div className="text-gray-500 font-bold text-center my-10">
              你沒有任何的士出租
            </div> : (newMode && <table className="table-auto w-full shadow-lg bg-white">
            <thead>
              <tr>
                <th scope="col" colSpan={listArea ? 2 : 1} className="border border-gray-500"></th>
                <th scope="col" className="border border-gray-500 p-1">
                  <div className="flex flex-row items-center justify-between">
                    <div>
                      <ul className="rounded-lg border border-gray-500 overflow-hidden flex text-gray-700 divide-x divide-gray-200">
                      {
                        [
                          "租金", "按金", "備註"
                        ].map((tab, i) => <li key={i}>
                          <a href="#" 
                            className={
                              `inline-block h-full px-2 py-1 ${listInfo === i ? 'text-white bg-gray-700' : 'bg-white'}`
                            }
                            onClick={() => setListInfo(i)}>
                            {tab}
                          </a>
                        </li>)
                      }
                      </ul>
                    </div>
                    {
                      showAction ? <div className="text-gray-700 px-1"
                        onClick={() => setShowAction(0)}>
                        返回
                      </div> : <div className="flex">
                        <div className="text-red-500 px-1 mr-1"
                          onClick={() => setShowAction(2)}>
                          刪除
                        </div>
                        <div className="text-main px-1"
                          onClick={() => setShowAction(1)}>
                          編輯
                        </div>
                      </div>
                    }
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
            {(listArea ? gCarShiftWithoutAll : [
                {
                  title: '全架',
                  value: 'all',
                }, {
                  title: '包租',
                  value: 'bare',
                }
              ].filter(
                ({ value }) => newFilterItems[value]
              )).map(
                shift => (listArea ? gCarCurrentShift : [{
                  value: ""
                }]).map((currentShift, i) => <tr key={i} className="border">
                  {
                    i === 0 && <td className="border border-gray-500 p-2"
                      style={{width: '1px'}}
                      rowSpan={listArea ? gCarCurrentShift.length : 1}>
                      {shift.title}
                    </td>
                  }
                  {
                    currentShift.value && <td className="border border-gray-500 p-2" style={{width: '1px'}}>
                      {currentShift.title}
                    </td>
                  }
                  <td className="border border-gray-500">
                  {
                    get(newFilterItems, `${shift.value}.${currentShift.value}`)?.map(
                      (item, j) => <div key={item.ref}
                        className={`flex flex-row items-center gap-x-1 px-1 py-2 ${j > 0 ? "border-t border-gray-400" : ""}`}
                        onClick={() => showAction && (showAction === 1 ? editTaxiInfo : deleteTaxiInfo)(item)}>
                        <div>
                          <span className={`whitespace-nowrap text-${item.carColor === 'red' ? 'red-700' : 'green-600'}`}>
                            {t(`carType.${item.carType}`)}
                          </span>
                          {item.carYear && <div className="text-xs">({item.carYear})</div>}
                        </div>
                        <div className="flex-grow">
                        {
                          listInfo === 0 && <>
                            <b>
                              {item.rent ? `$${item.rent}` : '待議'}
                            </b>
                            {item.rentRemark && <div className="inline-block leading-4 text-xs ml-0.5">
                              {item.rentRemark}
                            </div>}
                          </> || listInfo === 1 && <>
                            <b>
                              {item.deposit ? `$${item.deposit}` : '待議'}
                            </b>
                            {item.depositRemark && <div className="inline-block leading-4 text-xs ml-0.5">
                              {item.depositRemark}
                            </div>}
                          </> || <div className="text-sm leading-4">
                            {item.otherRemark || '-'}
                          </div>
                        }
                        </div>
                        {
                          !showAction ? <div className="text-sm text-gray-700">
                            {!item.enabled && <span className="text-xs text-red-600">
                              已下架
                            </span> || item.invalidated && <span className="text-xs text-green-600">
                              審核中
                            </span>}
                            #{item.ref}
                          </div> : <>
                            <div className="text-red-500 text-xl mr-1">
                              {showAction === 1 ? <AiOutlineEdit></AiOutlineEdit> : <AiOutlineDelete></AiOutlineDelete>}
                            </div>
                            {showAction === 1 && <Toggle 
                              checked={ item.enabled } 
                              disabled={ item.loading }
                              toggleFunc={(enabled, e) => [e.stopPropagation(), enableTaxi(item, enabled)]} />}
                          </>
                        }
                      </div>
                    ) || <div className="text-center">-</div>
                  }
                  </td>
                </tr>
              )
            )}
            </tbody>
          </table> || filterItems.map(item => <MyTaxi key={ item._id } 
            info={item}
            enableTaxi={enableTaxi}
            editTaxiInfo={editTaxiInfo}
            deleteTaxi={deleteTaxiInfo} />
          )))
        }
        {
          listLoading &&
            <LoadingComponent>
            </LoadingComponent>
        }
        </div>
      </div>
    }

    <Modal
      isOpen={modalIsOpen}
      style={customStyles}
      onRequestClose={closeModal}>
      {
        modalIsOpen && (
          modalType === 0 ?
            <div>
              <GrClose className="text-black float-right" onClick={ () => { closeModal(); gaEventTracker("edit user info", "cancel"); } }></GrClose>
              <p className="mb-6 text-main font-bold text-xl">編輯個人資料</p>
              {
                sendData.map((i, index) =>
                  <div className='w-full' key={index}>
                    <div className=" w-full flex items-center">
                      <div className="mr-2 bg-main bg-opacity-100  h-1 w-4"></div>
                      <div className=" px-0 py-1 font-bold text-base  text-gray-800 bg-main bg-opacity-0 mb-0.5">
                        {i.title}
                      </div>
                    </div>
                    <div className="relative md:flex bg-main bg-opacity-0 border-solid border border-gray-400 rounded-md w-full mb-2">
                      <div className="w-full leading-8 text-xl opacity-80" >
                        <input className='px-8 py-2 w-full bg-transparent placeholder-gray-400 text-gray-700'
                          type="text"
                          value={i.content}
                          placeholder={i.placeholder}
                          onChange={(e) => sendDataChange(index, e.target.value)} />
                      </div>
                    </div>
                  </div>
                )}
              <button className="mt-6 w-full gradiBtn p-4 specFont shadow-md mb-4 mr-4" onClick={() => updateUserInfo()} >確定</button>
            </div>
            :
            <div>
              {
                !delLoading ?
                  <div>
                    <GrClose className="text-black float-right" onClick={ () => { closeModal(); gaEventTracker("delete taxi", "cancel"); } }></GrClose>
                    <p className="mb-6 text-main font-bold text-xl">確定刪除 #{selCar.ref}？</p>
                    <p>
                      {t(`carType.${selCar.carType}`)} {selCar.area} {selCar.currentShift ? t(`currentShift.${selCar.currentShift}`) : ''}{t(`shift.${selCar.shift}`)}
                    </p>
                    <button className="mt-6 w-full gradiBtn p-4 specFont shadow-md" onClick={() => deleteListed()} >確定</button>
                  </div>
                  :
                  <LoadingComponent></LoadingComponent>
              }
            </div>
        )
      }
    </Modal>
  </div>

}

export default UserInfo
