import "./TaxiInfo.css";
import { gTaxiImg } from "../global/constants"
import { useTranslation } from "react-i18next";
import { AiOutlineClockCircle, AiOutlineEye } from 'react-icons/ai';
import Moment from 'react-moment';
import { useSearchParams, useNavigate } from "react-router-dom";
import moment from "moment";

export const SubTaxiInfo = (props: { isAdmin: boolean, info: Object, onSelected: Function }) => {

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { _id, ref, offer, time, user, bids, createdAt } = props.info;
  const { carType, carColor, area, subarea } = offer;

  return (
    <div onClick={() => props.onSelected(props.info)} key={_id} className="grid grid-cols-10 mb-1 bg-main3 rounded-lg cursor-pointer">
      <div className="col-span-10 p-2 relative">
        <p className="flex justify-between items-center text-maintext text-base">
          <b>
            #{ref} {area} {subarea || ""}
            {bids?.length ? <span className="text-red-500 text-base ml-1">
              你已報價: ${bids[0].rent}
            </span> : ""}{props.isAdmin && user?.phone ? ` (${user.phone})` : ''}
          </b>
          <span className="flex items-center">
            <AiOutlineClockCircle className="inline mr-1"></AiOutlineClockCircle>
            <Moment fromNow className="text-sm">{createdAt}</Moment>
          </span>
        </p>
        <p className="text-maintext text-base">
          車款: {t(`carColor.${carColor}`)} {t(`carType.${carType}`)}
        </p>
        <p className="text-maintext text-base">
          更期: {moment(time.start).format("M月D日")} (星期{t(`weekday.${moment(time.start).day() || 7}`)}) {t(`shift.${time.shift}`)}
        </p>
        {user?.expYears != null && <p className="text-maintext text-base">
          經驗: {user.expYears ? `${user.expYears}年` : "新手"}{user.drivingExpYears ? `，其他${user.drivingExpYears}年` : ""}
        </p>}
      </div>
    </div>
  )
}
