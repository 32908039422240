import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TheTitle from "../../compontents/TheTitle";
import axios from "axios";
import moment from "moment";
import { AlertMessageContext } from "../../context";
import { useAnalyticsEventTracker } from "../../customHook";
import ReactPixel from 'react-facebook-pixel';

const TimelyRequest = () => {
  const gaEventTracker = useAnalyticsEventTracker("Timely Request");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const setAlertMessage = useContext(AlertMessageContext);
  const [searchParams] = useSearchParams();
  const requestId = searchParams.get('id');
  const token = localStorage.getItem('token');
  const [loading, setLoading] = useState(true);
  const [request, setRequest] = useState(null);
  const timeoutRef = useRef(null);

  useEffect(() => {
    ReactPixel.pageView();

    if (!token) {
      const { pathname, search } = window.location;
      return navigate(`/login?redirect=${encodeURIComponent(pathname + search)}`, { replace: true });
    }

    if (requestId) {
      getRequestById();
    } else {
      setLoading(false);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    }
  }, []);

  function getRequestById(retryCount = 0) {
    setLoading(true);
    const api_url = process.env.REACT_APP_API_URL + "/trequest/getById"
    axios.post(api_url, {
      id: requestId
    })
      .then(response => {
        setLoading(false);
        setRequest(response.data.data);
      })
      .catch(error => {
        console.log('err when get timely request:', error)
        if (error.response.status === 403 || retryCount >= 5) {
          setAlertMessage({
            message: "發生錯誤",
            callback: () => navigate(`/userinfo?userID=${searchParams.get('userID') || ''}`)
          });
        } else {
          timeoutRef.current = setTimeout(function () {
            getRequestById(retryCount + 1);
          }, 5000);
        }
      })
  }

  return <div className="appWidth pt-4">
    <TheTitle title={`自由替 ${loading ? '' : `#${request.ref}`}`}></TheTitle>
    {
      !loading && request ? <div className="mt-2">
        <p>
          {request.time.day} ({t(`weekday.${moment(request.time.start).day() || 7}`)}) {t(`shift.${request.time.shift}`)} {request.offer.area} {t(`carColor.${request.offer.carColor}`)}{t(`carType.${request.offer.carType}`)}
        </p>
        <p>
          司機: {request.user.name}
          <br />
          電話: {request.user.phone}
        </p>
        <h3 className="text-center mt-4 font-bold">
          以下為司機相關文件，按下圖片可以下載，如有任何問題請與客服聯絡
        </h3>
        {Object.keys(request.user.documentStatuses).sort().map(type => {
          const url = `${process.env.REACT_APP_API_URL}/trequest/${requestId}/doc?authToken=${token}&type=${type}`;
          return <div key={type} className="p-4">
            <h5>

            </h5>
            <a href={url}>
              <img src={url} />
            </a>
          </div>;
        })}
      </div> : ""
    }
  </div>;
};

export default TimelyRequest;