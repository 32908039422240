import { AiOutlineEye } from 'react-icons/ai';

const TheTitle = (props) =>{
  return (
      <div className={`relative text-xl w-full text-black flex justify-between ${props.className || ''}`}>
        <div>{ props.title }</div>
        { ![null, undefined].includes(props.views) ? <div className="flex gap-x-2 items-center">
          <AiOutlineEye className="inline"></AiOutlineEye>{ props.views }
        </div> : ""}
      </div>
  )
}
export default TheTitle
