import { useContext, useState, useEffect, useCallback, useRef } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import jwt_decode from "jwt-decode";
import ReactPixel from 'react-facebook-pixel';
import Modal from 'react-modal';
import { BsCheck2Circle } from 'react-icons/bs';
import { FaChalkboardTeacher } from 'react-icons/fa';
import { RiSteering2Fill } from 'react-icons/ri';
import { isEqual } from 'lodash';
import { AlertMessageContext } from "../../context";
import { useAnalyticsEventTracker } from "../../customHook";
import { gWhatsapp } from "../../global/constants";
import LoadingComponent from "../../compontents/LoadingComponent";
import styles from './index.css';
import { GrClose } from "react-icons/gr";

Modal.setAppElement('#root');

const customStyles = {
  overlay: {
    padding: '5rem 2rem',
    zIndex: 99,
    touchAction: 'none'
  },
  content: {
    inset: 'unset',
    position: 'relative',
    width: '100%',
    maxHeight: '100%',  
    padding: '0px',
    overlay: 'scroll',
    touchAction: 'none'
  },
};
const joinSteps = [
  {
    title: "檢查報考資格",
    action: "查看詳細",
    details: [
      "年齡需二十一歲或以上且體格適宜駕駛",
      "從2020年10月1日或之後報名的申請人，已經完成私家車或輕型貨車（棍波或自動波都可以）的一年暫准駕駛期（即一年的P牌）；或",
      "如私家車或輕型貨車的暫准駕駛期不適用，已持有私家車或輕型貨車（棍波或自動波都可以）的正式駕駛執照最少一年。",
      "在緊接申請前五年內無觸犯特定的道路交通條例罪行。",
      "若需要詳細資訊，可參閱「駕駛考試申請表（商用車輛）」（TD 321）"
    ]
  }, {
    title: "熟讀的士試題，備考筆試",
    action: "查看詳細",
    details: [
      "自修",
      "報讀駕駛學院的士課程，一般會代報考的士筆試"
    ]
  }, {
    title: "考試及格，報讀職前課程（政府規定）",
    action: "查看詳細",
    details: [
      "必須完成「基礎單元」及「專屬單元（的士）」，總課時16小時",
      "需要在4個月內完成整個課程",
    ]
  }, {
    title: "辦理的士司機證",
    action: "查看辦證地點",
    url: "/newcomer/license"
  }, {
    title: "租的士開工（OKTaxi超過2000個選擇）",
    action: "點擊前往",
    url: "/"
  }
];

const NewComer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const token = localStorage.getItem('token');
  const isAdmin = !!token && (jwt_decode(token).role === 'admin');
  const setAlertMessage = useContext(AlertMessageContext);
  const gaEventTracker = useAnalyticsEventTracker("New Comer Form");
  const [modalStep, setModalStep] = useState(null);
  const [isFormModalOpen, setFormModalOpen] = useState(false);
  const [showForm, setShowForm] = useState(searchParams.get("form") ? 2 : 0);
  const [loading, setLoading] = useState(false);
  const [isDriveCourse, setDriveCourse] = useState(!!searchParams.get("drive"));
  const [formData, setFormData] = useState([{
    name: "name",
    title: "姓名",
    placeholder: "中/英文全名",
    value: ""
  }, {
    name: "phone",
    title: "聯絡電話",
    placeholder: "輸入Whatsapp電話號碼",
    value: (!isAdmin && localStorage.getItem("phone") || "")
  }, {
    name: "district",
    title: "居住/交更地區",
    placeholder: "輸入地區名",
    value: ""
  }, {
    name: "taxiColor",
    title: "揸紅的定綠的？",
    shortTitle: "紅/綠的",
    inputType: "radio",
    options: [{
      title: "紅的",
      value: "red"
    }, {
      title: "綠的",
      value: "green"
    }],
    value: null
  }, {
    name: "isUbertaxi",
    title: "曾經登記UberTaxi？ (不包括Uber)",
    shortTitle: "曾登記 UberTaxi",
    inputType: "radio",
    options: [{
      title: "是",
      value: true
    }, {
      title: "否",
      value: false
    }],
    value: null
  }, {
    name: "isTaxiLicense",
    inputType: "radio",
    title: "已考到的士牌？",
    shortTitle: "已考的士牌",
    options: [{
      title: "是",
      value: true
    }, {
      title: "否",
      value: false
    }],
    value: isDriveCourse || null
  }, {
    name: "taxiLicense",
    cond: (form) => form.find(data => data.name === "isTaxiLicense").value,
    title: "的士證",
    placeholder: "的士證號碼",
    value: ""
  }, {
    name: "isApplyCourse",
    inputType: "radio",
    cond: (form) => form.find(data => data.name === "isTaxiLicense").value === false,
    refChanged: useCallback(elem => {
      if (elem) {
        elem.scrollIntoView({
          behavior: "smooth"
        });
      }
    }, []),
    title: "需要報讀駕駛學校的士課程？(學費只需$500，一年任上，包書本及網上試題，包代辦報考運輸署筆試所有手續)",
    shortTitle: "報讀駕駛學校的士課程",
    options: [{
      title: "是",
      value: true
    }, {
      title: "否",
      value: false
    }],
    value: null
  }, {
    name: "isApplyPreServiceCourse",
    inputType: "radio",
    cond: (form) => isEqual(form.reduce((accu, data) => {
      if (data.name === "isTaxiLicense" || data.name === "isApplyCourse") {
        accu.push(data.value);
      }
      return accu;
    }, []), [false, false]),
    refChanged: useCallback(elem => {
      if (elem) {
        elem.scrollIntoView({
          behavior: "smooth"
        });
      }
    }, []),
    title: "需要報讀的士職前課程？(法例要求完成，費用只需$1000)",
    shortTitle: "報讀職前課程",
    options: [{
      title: "是",
      value: true
    }, {
      title: "否",
      value: false
    }],
    value: null
  }, {
    name: "driveLicense",
    cond: (form) => form.some(data => (data.name === "isApplyCourse" || data.name === "isApplyPreServiceCourse") && data.cond(form) && data.value),
    refChanged: useCallback(elem => {
      if (elem) {
        elem.scrollIntoView({
          behavior: "smooth"
        });
      }
    }, []),
    title: "駕駛執照",
    placeholder: "駕駛執照號碼",
    value: ""
  }, ]);
  const formParentRef = useRef();

  function selectStep(step) {
    if (step.url) {
      navigate(step.url, {
        state: {}
      });
    } else {
      setModalStep(step);
    }
  }

  function onFormDataChanged(index, value) {
    formData[index].value = value;
    setFormData([...formData]);
  }

  async function submit() {
    const form = {};
    let desc = "";
    if (formData.some(({ cond, name, title, shortTitle, options, value }) => {
      if (!cond || cond(formData)) {
        title = shortTitle || title;
        if (!value && value !== false) {
          formParentRef.current?.childNodes[Object.keys(form).length]?.scrollIntoView();
          setAlertMessage({ message: `請填寫${title}` });
          return true;
        }
        form[name] = value;
        desc += `\n${title}: ${options && options.find(opt => opt.value === value)?.title || value}`;
      }
    })) {
      return;
    }

    desc = (isDriveCourse ? "*登記師傅到*" : "*登記新人班*") + desc;

    gaEventTracker("apply new comer class", "submit");
    setLoading(true);
    try {
      const api_url = process.env.REACT_APP_API_URL + `/form/submit`;
      await axios.post(api_url, {
        type: "newcomer",
        data: form,
      });
      window.open(`https://wa.me/${gWhatsapp}?text=${encodeURIComponent(desc)}`, '_blank');

      navigate(location.state ? -1 : '/');
    } catch (error) {
      console.error('err when submit deposit form:', error);
      let message = "登記發生錯誤，請重試";
      if (error.response?.data?.message === "invalid number") {
        message = "請輸入Whatsapp電話號碼";
      }
      setAlertMessage({ message });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    ReactPixel.pageView();
  }, []);

  useEffect(() => {
    setFormModalOpen(showForm === 2);
    setSearchParams({
      ...(showForm <= 1 ? undefined : {
        form: 1
      }),
      ...(!isDriveCourse ? undefined : {
        drive: 1
      })
    });
  }, [showForm]);

  useEffect(() => {
    setShowForm(searchParams.get("form") ? 2 : 0);
    setDriveCourse(!!searchParams.get("drive"));
  }, [searchParams]);

  return <div className={!showForm && "bg" || ""}>
    {
      !showForm ? <div className="ml-12 pl-6 pr-3 pt-6 pb-10 bg-gray-100/90">
        <div className="text-xl text-blue-900 font-semibold mb-10" 
          onClick={() => setShowForm(1)}>
          👋 準備好入行？登記OKTaxi新手班，領取新人獎勵 
          <span className="gradiBtn py-1 px-3 ml-2 specFont text-lg text-center">
            登記
          </span>
        </div>
        <p className="text-3xl font-bold mb-3">
          的士入行流程
        </p>
        {
          joinSteps.map((step, i) => <div key={i} className="relative flex py-6 text-2xl font-bold items-center"
            onClick={() => selectStep(step)}>
            {i < joinSteps.length - 1 && <div className="absolute left-5 top-1/2 w-0.5 h-full -translate-x-1/2 bg-black"></div>}
            <div className="relative z-9 rounded-full bg-red-700 w-10 h-10 text-white flex flex-none items-center justify-center dot">
              {i+1}
              {i > 0 && <div className="absolute rounded-full w-1.5 h-1.5 top-0 -translate-y-1/2 bg-black z-10"></div>}
              {i < joinSteps.length - 1 && <div className="absolute rounded-full w-1.5 h-1.5 bottom-0 translate-y-1/2 bg-black z-10"></div>}
            </div>
            <div className="grow ml-4">
              {step.title}
              <p className="text-base text-gray-500">
                {step.action}
              </p>
            </div>
          </div>)
        }
        <div className="text-xl text-blue-900 font-semibold mt-5" 
          onClick={() => setShowForm(1)}>
          登記埋OKTaxi新手班，領取新人獎勵 
          <span className="gradiBtn py-1 px-3 ml-2 specFont text-lg text-center">
            登記
          </span>
        </div>
      </div> : (showForm === 1 ? <div className="absolute top-12 bottom-0 w-full flex flex-col">
        <div className="flex flex-1 items-center justify-center" >
          <div className="text-center p-5" onClick={() => [setShowForm(2), setDriveCourse(false)]}>
            <FaChalkboardTeacher className="text-yellow-600 text-6xl mb-2 mx-auto"></FaChalkboardTeacher>
            <p className="font-semibold text-gray-900 text-lg mb-2">
              登記新手實戰班
            </p>
            <p>
              免費新手課堂
              <br />
              介紹入行需知及落地開工技巧
            </p>
          </div>
        </div>

        {/* <div className="flex flex-1 items-center justify-center">
          <div className="text-center p-5" onClick={() => [setShowForm(2), setDriveCourse(true)]}>
            <RiSteering2Fill className="text-yellow-600 text-6xl mb-2 mx-auto"></RiSteering2Fill>
            <p className="font-semibold text-gray-900 text-lg mb-2">
              登記「師傅到」實戰班
            </p>
            <p>
              導師親身上車教你開車及接單技巧
              <br />
              每堂兩小時，費用$300，小組教學
            </p>
          </div>
        </div> */}
      </div> : <div className="pt-4 pb-16 appWidth">
        <p className="font-semibold text-gray-900 text-lg text-center mb-4">
          登記{isDriveCourse ? "「師傅到」" : "新手"}實戰班
        </p>
        
        <form id="newcomer" ref={formParentRef} onSubmit={e => e.preventDefault()}>
        {
          formData.map((i, index) => (!i.cond || i.cond(formData)) ? <div key={i.name} ref={i.refChanged}>
            <div className="flex items-center mb-1.5">
              <div className="mr-2 bg-main h-1 w-4"></div>
              <div className="font-semibold text-sm text-gray-700">
                {i.title}
              </div>
            </div>
            <div className="mb-5 pl-2">
              {
                i.inputType === "radio" ? <div>
                  <ul className="inline-block text-sm font-medium text-gray-500 divide-x divide-gray-400 border border-gray-400 rounded-lg overflow-hidden">
                    {
                      i.options.map(opt => <li key={opt.value}
                        className={`inline-block px-6 py-2 ${
                          formData.find(
                            data => data.name === i.name
                          )?.value === opt.value ? "bg-gray-700 text-white" : "bg-white"
                        }`}
                        onClick={() => onFormDataChanged(index, opt.value)}>
                        {opt.title}
                      </li>)
                    }
                  </ul>
                </div> : <input className="p-2.5 w-full bg-gray-50 placeholder-gray-400 rounded-lg border border-gray-400 focus:ring-blue-500 focus:border-blue-500"
                  type={i.type || "text"}
                  placeholder={i.placeholder}
                  value={i.value}
                  onChange={(e) => onFormDataChanged(index, e.target.value)} />
              }
            </div>
          </div> : "")
        }
        </form>

        <div className="fixed bottom-0 left-0 right-0 p-1">
        {
          !loading ? <div className="gradiBtn p-3 specFont shadow text-lg text-center"
            onClick={submit}>
            提交登記
          </div> : <LoadingComponent></LoadingComponent>
        }
        </div>
      </div>)
    }
    <Modal
      isOpen={!!modalStep}
      style={customStyles}
      onRequestClose={() => setModalStep(null)}>
      {modalStep && <div className="p-3 relative">
        <p className="sticky top-0 pt-3 text-lg font-bold text-center">
          <GrClose className="absolute top-2 right-0 text-lg"
            onClick={() => setModalStep(null)}></GrClose> 
          {modalStep.title}
        </p>
        <ol className="list-inside list-decimal text-sm">
          {modalStep.details.map((d, i) => <li key={i} className="my-6 text-base">
            {d}
          </li>)}
        </ol>
      </div>}
    </Modal>
    <Modal
      isOpen={isFormModalOpen}
      style={customStyles}
      onRequestClose={() => setFormModalOpen(false)}>
      <div className="p-2">
        <p className="font-semibold text-gray-900 text-lg text-center">
          OKTaxi 新手入行禮包🎁
        </p>
        <ul className="mt-6 space-y-5 text-gray-700">
          {!isDriveCourse ? <li className="flex items-center gap-x-2">
            <BsCheck2Circle className="flex-shrink-0 text-2xl text-green-500"></BsCheck2Circle>
            <span>
              一條龍報讀的士及職前課程，代報考的士考試
            </span>
          </li> : ""}
          <li className="flex items-center gap-x-2">
            {!isDriveCourse ? <>
              <BsCheck2Circle className="flex-shrink-0 text-2xl text-green-500"></BsCheck2Circle>
              <span>
                獨家新手實戰班，課堂傳授落地搵食技巧
              </span>
            </> : <div>
              <p>
                獨家「師傅到」實戰班，導師親身車上教學，課程如下:
              </p>
              <div class="mt-3">
                1️⃣ 實習的士車輛運作
                <ul className="list-disc list-inside">
                  <li>車輛基本操作及駕駛技巧</li>
                  <li>咪錶操作</li>
                  <li>日常簡易保養及維修</li>
                  <li>緊急狀況處理 (撞車、壞車) 及應對</li>
                </ul>
              </div>
              <div class="mt-3">
                2️⃣ 路面實況
                <ul className="list-disc list-inside">
                  <li>最旺接客地點</li>
                  <li>落客要點</li>
                  <li>交通黑點、盲點</li>
                </ul>
              </div>
              <div class="mt-3">
                3️⃣ 開工須知
                <ul className="list-disc list-inside">
                  <li>早/夜/特更行程安排 (食飯、休息、如廁)</li>
                  <li>導航技巧</li>
                  <li>碌街 (街上接客) 技巧</li>
                  <li>市面接單APP 運作</li>
                </ul>
              </div>
            </div>}
          </li>
          <li className="flex items-center gap-x-2">
            <BsCheck2Circle className="flex-shrink-0 text-2xl text-green-500"></BsCheck2Circle>
            <span>
              上堂後完成指單接單任務，領取現金獎賞高達✨<b className="text-red-600">$5000</b> 🎁
            </span>
          </li>
          <li className="flex items-center gap-x-2">
            <BsCheck2Circle className="flex-shrink-0 text-2xl text-green-500"></BsCheck2Circle>
            <span>
              尊享實戰班學員租車優惠
            </span>
          </li>
        </ul>
        <div className="mt-6 text-center">
          <button className="gradiBtn px-4 py-2 specFont" onClick={() => setFormModalOpen(false)}>
            確定
          </button>
        </div>
      </div>
    </Modal>
  </div>;
};

export default NewComer;