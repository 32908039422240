import "./SingleFilter.css";
import React, { useState, useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useAnalyticsEventTracker } from "../customHook";

export const SingleFilter = (props: { data: Array<Object>, setFilter: Function }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState(new Map());
  const filterRef = useRef();
  const gaEventTracker = useAnalyticsEventTracker("SingleFilter");

  useEffect(() => {
    let closeFilter = (e) => {
      !filterRef.current.contains(e.target) && setOpen(false);
    }

    if (open) document.addEventListener("click", closeFilter);
    else document.removeEventListener("click", closeFilter);

    return () => document.removeEventListener("click", closeFilter);
  }, [open]);

  const filterOptions = useCallback(() => {

    return (
      <div className="single_filter-options">
        <div className="single_filter-category">
          <button className="single_filter-option single" onClick={() => {
            props.setFilter(new Map());
            setFilter(new Map());
            setOpen(false);
            gaEventTracker("filter", "all");
          }}>
            全部{filter.size === 0 && <div className="icon-check" />}
          </button>
        </div>
        {
          Array.from(props.data).map(cat => (
            <div className="single_filter-category" key={cat[0]}>
              <div className="single_filter-subtitle">{t(`filter.${cat[0]}.title`)}</div>
              {Array.from(cat[1]).map(option =>
                <button className="single_filter-option" key={option} onClick={() => {
                  const key = cat[0];
                  const opts = (filter.get(key) || new Set());
                  if (opts.has(option)) {
                    opts.delete(option);
                  } else {
                    opts.add(option);
                  }
                  const newFilter = new Map(filter);
                  if (opts.size > 0) {
                    newFilter.set(key, opts);
                  } else {
                    newFilter.delete(key);
                  }
                  props.setFilter(newFilter);
                  setFilter(newFilter);
                }}>
                  <div>{cat[0] === "area" ? option : t(`${cat[0]}.${option}`)}</div>
                  {(filter.get(cat[0]) || new Set()).has(option) && <div className="icon-check" />}
                </button>
              )}
            </div>
          ))
        }
      </div>
    )
  }, [props.data, filter]);
  return (
    <div ref={filterRef} className="single_filter">
      <button className={`single_filter-selected${open ? " open" : ""}${filter.size > 0 ? " active" : ""}`} onClick={() => setOpen(!open)}>
        篩選 <div className="single_filter-arrow" />
      </button>
      {open && filterOptions()}
    </div>
  )
}
