import { useEffect } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import Modal from 'react-modal';


function App() {
  Modal.defaultStyles.overlay.backgroundColor = "#0009";
  
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {

    window.history.scrollRestoration = 'auto';

    if (localStorage.getItem('token') === 'undefined') {
      localStorage.removeItem('token');
    }

    let isRegistering = false;
    function registerAnony() {
      if (!isRegistering && !localStorage.getItem('anonyToken')) {
        isRegistering = true;
        console.log("create")
        axios.post(process.env.REACT_APP_API_URL + "/user/create", {
          type: 'visitor',
          name: localStorage.getItem('userPhone') || localStorage.getItem('userID'),
        }).then(function (response) {
          localStorage.setItem('anonyToken', response.data.data.user.authToken);
        }).finally(function () {
          isRegistering = false;
        });
      }
    }

    const phoneParam = searchParams.get('phone');
    if (phoneParam && !localStorage.getItem('userPhone')) {
      localStorage.setItem('userPhone', phoneParam);
    }

    axios.defaults.timeout = 10000;
    axios.interceptors.request.use(function (request) {
      const token = localStorage.getItem('token') || localStorage.getItem('anonyToken');
      if (token) {
        request.headers['Authorization'] = `BEARER ${token}`;
      } else {
        registerAnony();
      }
      return request;
    });
    axios.interceptors.response.use(function (response) {
      return response;
    }, function (error) {
      if (error.response
        && error.response.status === 401
        && error.response.data
        && error.response.data.message === 'unauthorized'
        && localStorage.getItem('token')) {
        localStorage.removeItem('token');
        const { pathname, search } = window.location;
        navigate(`/login?redirect=${encodeURIComponent(pathname + search)}`);
      }
      return Promise.reject(error);
    });
    registerAnony();
  }, []);
  return;
}

export default App;
