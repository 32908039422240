import { useState, useContext, useEffect } from 'react';
import { GrClose } from 'react-icons/gr';
import { gCarType } from '../../global/constants';
import { UserInfoContext, AlertMessageContext } from "../../context";
import LoadingComponent from "../../compontents/LoadingComponent";

const rows = [{
  title: "出租人姓名",
  key: "ownerName",
  placeholder: "出租人身分證全名",
  required: true
}, {
  title: "司機姓名",
  key: "driverName",
  placeholder: "司機身分證全名",
  required: true
}, {
  title: "司機駕照號碼",
  key: "driverDrivingLicense",
  placeholder: "香港駕駛執照號碼",
  required: true
}, {
  title: "供金 ($)",
  key: "deposit",
  placeholder: "如需供按金，請填上先付按金金額",
  required: true
}, {
  title: "每更供按金 ($)",
  key: "depositInstalment",
  placeholder: "(選填，如需供按金)"
}, {
  title: "按金總額 ($)",
  key: "depositTotal",
  placeholder: "(選填，如需供按金)"
}, {
  title: "墊底費 ($)",
  key: "excess",
  placeholder: "意外墊底費金額",
  required: true
}, {
  title: "租金 ($)",
  key: "rent",
  placeholder: "租金金額",
  required: true
}, {
  title: "最少維修減租時間 (小時)",
  key: "deductAfterHours",
  placeholder: "小時",
  required: true
}, {
  title: "司機通知期 (天)",
  key: "driverNotifyDays",
  placeholder: "日數",
  required: true
}, {
  title: "出租人通知期 (天)",
  key: "ownerNotifyDays",
  placeholder: "日數",
  required: true
}, {
  title: "退按金期限 (天)",
  key: "depositReturnDays",
  placeholder: "日數",
  required: true
}];

const RentalContractForm = (props: {
  driver: any,
  carType: string,
  onFormConfirm: (form) => Promise<void>,
  onClose: () => void,
}) => {
  const [userInfo] = useContext(UserInfoContext);
  const setAlertMessage = useContext(AlertMessageContext);
  const contractDetail = userInfo.defaultContractDetail || {};
  const [carType, setCarType] = useState(props.carType);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    ownerName: contractDetail.ownerName || "",
    driverName: props.driver.names[0] || "",
    driverDrivingLicense: props.driver.idNumber?.replace(/[\(\)]/g, ""),
    deposit: "",
    depositInstalment: contractDetail.depositInstalment || "",
    depositTotal: contractDetail.depositTotal || "",
    rent: "",
    excess: "",
    deductAfterHours: contractDetail.deductAfterHours || 1,
    driverNotifyDays: contractDetail.driverNotifyDays || 7,
    ownerNotifyDays: contractDetail.ownerNotifyDays || 7,
    depositReturnDays: contractDetail.depositReturnDays || 45
  });

  useEffect(() => {
    if (carType) {
      setForm({
        ...form,
        deposit: (contractDetail.carTypeDeposits || {
          "4seat": 13000,
          "5seat": 13000,
          "hybrid": 18000,
          "ford": 13000,
          "byd": 20000
        })[carType],
        excess: (contractDetail.carTypeExcesses || {
          "4seat": 14000,
          "5seat": 10000,
          "hybrid": 18000,
          "ford": 13000,
          "byd": 20000
        })[carType]
      });
    }
  }, [carType]);

  function getFormInputRow(i, index) {
    return <div className="w-full" key={index}>
      {i.title && <div className="w-full flex items-center">
        <div className="mr-2 bg-main bg-opacity-100 h-1 w-2"></div>
        <div className="font-bold text-base text-gray-800 bg-main bg-opacity-0 mb-0.5">
          {i.title}
        </div>
      </div>}
      <div className="flex bg-main bg-opacity-0 mb-4">
        <div className="grow leading-8 text-xl">
          <input className={"placeholder-gray-400 border border-gray-400 rounded-md focus:ring-blue-600 focus:border-blue-600 px-4 py-2 w-full text-gray-700"}
            type="text"
            value={form[i.key]}
            placeholder={i.placeholder} 
            onChange={(e) => setForm({
              ...form,
              [i.key]: e.target.value
            })} />
        </div>
      </div>
    </div>;
  }

  async function confirm(form) {
    for (const row of rows) {
      if (row.required && !form[row.key]) {
        setAlertMessage({
          message: `請填寫${row.title}`
        });
        return;
      }
    }

    setLoading(true);
    try {
      await props.onFormConfirm(form);
    } catch (err) {
      console.error("err when confirm contract form:", err);
    } finally {
      setLoading(false);
    }
  }

  return <div>
    <div className="sticky top-0 bg-white pb-4">
      <GrClose className="text-black float-right" onClick={props.onClose}></GrClose>
      <p className="text-main font-bold text-xl">合約資料</p>
    </div>
    {
      loading ? <LoadingComponent></LoadingComponent> : <>
        {carType ? <>
          {
            rows.map((i, index) => getFormInputRow(i, index))
          }
          <button className="sticky bottom-0 left-0 w-full gradiBtn p-4 specFont shadow-md text-xl"
            onClick={() => confirm(form)}>確定</button>
        </> : <div>
          <p className="mb-2 text-center">
            請選擇車型
          </p>
          {gCarType.map(t => <button key={t.id}
            className="m-1 py-2 px-4 rounded-full bg-white border border-gray-500"
            onClick={() => setCarType(t.value)}>
            {t.title}
          </button>)}
        </div>}
      </>
    }
  </div>;
};

export default RentalContractForm;