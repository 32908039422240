import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import reportWebVitals from './reportWebVitals';
// import Footer from './compontents/Footer'
import "./i18n";
import { BrowserRouter } from 'react-router-dom';

import moment from 'moment/min/moment-with-locales';
import Moment from 'react-moment';
import { Content } from "./content";
import ReactGA4 from "react-ga4";
import ReactPixel from 'react-facebook-pixel';

process.env.REACT_APP_GA_TRACKING_ID && ReactGA4.initialize(process.env.REACT_APP_GA_TRACKING_ID);
process.env.REACT_APP_PIXEL_TRACKING_ID && ReactPixel.init(process.env.REACT_APP_PIXEL_TRACKING_ID, {}, {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
});

if (process.env.NODE_ENV !== "development") console.log = () => {};

moment.updateLocale('zh-hk', {
  relativeTime: {
    future: '%s後',
    past: '%s前',
    s: '幾秒',
    ss: '%d秒',
    m: '1分鐘',
    mm: '%d分鐘',
    h: '1小時',
    hh: '%d小時',
    d: '1天',
    dd: '%d天',
    M: '1個月',
    MM: '%d個月',
    y: '1年',
    yy: '%d年',
  }
});
Moment.globalMoment = moment;
Moment.globalLocale = 'zh-hk';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <Content />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
