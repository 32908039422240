import React from "react";
import ReactGA4 from "react-ga4";
import ReactPixel from 'react-facebook-pixel';

export const useAnalyticsEventTracker = (category = "unnamed category") => {
    const eventTracker = (action = "unnamed action", label = "unnamed label") => {
        if (process.env.NODE_ENV === "development") return;
        console.log("tracking", category, action, label);
        if (process.env.REACT_APP_GA_TRACKING_ID) {
            ReactGA4.event({category, action, label});
        }
        if (process.env.REACT_APP_PIXEL_TRACKING_ID) {
            ReactPixel.track(category, `${action}: ${label}`);
        }
    }
    return eventTracker;
}
