import React from "react";
import "./Alert.css";

export const Alert = (props: { message: string, type: "alert" | "confirm", callback: Function, onClose: Function }) => {
  return (
    <div id="alert" onClick={props.onClose}>
      <div id="alert-box" onClick={(e) => e.stopPropagation()}>
        <div>{props.message}</div>
        {
          props.type === "alert" ?
            <button className="alert-box-button" onClick={() => { props.callback && props.callback(); props.onClose() }}>確定</button> :
            <div id="alert-confirm">
              <button className="alert-box-button cancel" onClick={() => { props.onCancel && props.onCancel(); props.onClose() }}>
                { props.cancelText || "取消" }
              </button>
              <button className="alert-box-button" onClick={() => { props.callback && props.callback(); props.onClose() }}>
                { props.confirmText || "確定" }
              </button>
            </div>
        }
      </div>
    </div>
  )
}
