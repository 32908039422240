import { useState, useEffect, useContext } from 'react';
import { withTranslation } from 'react-i18next';
import jwt_decode from "jwt-decode";
import ReactPixel from 'react-facebook-pixel';
import axios from 'axios';
import { gOwnerWhatsapp } from '../../global/constants';
import { useAnalyticsEventTracker } from "../../customHook";
import LoadingComponent from "../../compontents/LoadingComponent";
import { AlertMessageContext } from "../../context";
import { BsWhatsapp } from 'react-icons/bs';
import { GrClose } from 'react-icons/gr';
import Modal from 'react-modal';
import moment from 'moment';

const types = [{
  value: "hkid",
  title: "香港身分證",
  placeholder: "首7位英文字母及數字，括號內不用輸入"
}, {
  value: "phone",
  title: "手機號碼",
  placeholder: "8位香港手機號碼"
}];

const idNumberSuffices = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, "A"];

const customStyles = {
  overlay: {
    zIndex: 99
  },
  content: {
    width: '80%',
    maxHeight: '70%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const dataRows = [{
  title: "英文全名",
  key: "fullName",
  placeholder: "英文全名(與身份證一致)",
  pattern: "[A-Za-z]"
}, {
  title: "電話號碼",
  type: "number",
  key: "phone",
  placeholder: "電話號碼",
  maxlength: 8
}, {
  title: "身份證號碼",
  key: "idNumber",
  placeholder: "香港身份證號碼",
  pattern: "[A-Za-z0-9]"
}, {
  title: "黑名單事件",
  type: "textarea",
  key: "desc",
  placeholder: "事件經過"
}, {
  title: "損失金額",
  key: "amount",
  placeholder: "損失金額(如有)"
}];

const DriverBlacklist = () => {
  const gaEventTracker = useAnalyticsEventTracker("Driver Blacklist");
  const [myTab, setMyTab] = useState(false);
  const [type, setType] = useState(types[0].value);
  const [input, setInput] = useState("");
  const [dataList, setDataList] = useState([]);
  const [form, setForm] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [searched, setSearched] = useState(false);
  const setAlertMessage = useContext(AlertMessageContext);
  const token = localStorage.getItem('token');
  const userId = token && jwt_decode(token).sub;
  
  useEffect(() => {
    ReactPixel.pageView();
  }, []);

  function getFormInputRow(i, index, data, setData) {
    if (!data) {
      return "";
    }
    return <div className="w-full" key={index}>
      {i.title && <div className="w-full flex items-center">
        <div className="mr-2 bg-main bg-opacity-100 h-1 w-2"></div>
        <div className="font-bold text-base text-gray-800 bg-main bg-opacity-0 mb-0.5">
          {i.title}
        </div>
      </div>}
      <div className="flex bg-main bg-opacity-0 mb-4">
        <div className="grow leading-8 text-xl">
          {i.type === "textarea" ? <textarea className="px-4 py-2 w-full text-gray-700 placeholder-gray-400 border border-gray-400 rounded-md focus:ring-blue-600 focus:border-blue-600"
            rows={2}
            value={data[i.key]}
            placeholder={i.placeholder}
            onChange={(e) => setData({
              ...data,
              [i.key]: e.target.value
            })}
          /> : <input className={"placeholder-gray-400 border border-gray-400 rounded-md focus:ring-blue-600 focus:border-blue-600 " + (i.type === "date" ? "p-2.5 w-full bg-gray-50" : "px-4 py-2 w-full text-gray-700")}
            type={i.type || "text"}
            value={data[i.key]}
            maxLength={i.maxLength}
            pattern={i.pattern}
            placeholder={i.placeholder}
            onChange={(e) => setData({
              ...data,
              [i.key]: e.target.value
            })} />}
        </div>
        {
          i.key === "idNumber" ? <select className="px-1 m-1 text-lg border-x-2 border-gray-700 rounded-xl bg-gray-50"
            value={data[`${i.key}Suffix`]}
            onChange={(e) => setData({
              ...data,
              [`${i.key}Suffix`]: e.target.value
            })}>
            {idNumberSuffices.map(v => <option key={v} value={v}>
              {v}
            </option>)}
          </select> : ""
        }
      </div>
    </div>;
  }

  async function search() {
    if (type === "phone") {
      if (!/\d{8}/.test(input)) {
        return setAlertMessage({ message: "請輸入8位香港手機號碼" });
      }
    } else {
      if (!/^[A-Z]{1,2}\d{6}$/.test(input)) {
        return setAlertMessage({ message: "請輸入首1或2位英文字母及6位數字" });
      }
    }

    gaEventTracker("search blacklist", type);

    setSearched(true);
    setLoading(true);
    setDataList(null);
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/blacklist/${myTab ? "getByUserId" : "search"}`,
        myTab ? undefined : {
          type, input
        });
      setDataList(data.data);
    } catch (error) {
      console.error('err when search:', error);

      let message = "查詢發生錯誤，請稍後重試";
      switch (error.response?.status) {
        case 403:
          setSearched(false);
          message = "你暫時無法使用黑名單功能，請聯絡客服開通";
          break;

        case 409:
          setSearched(false);
          message = "查詢次數過多，請稍後重試";
          break;
      }
      setAlertMessage({ message });
    } finally {
      setLoading(false);
    }
  }

  function openBlacklistForm() {
    setForm(dataRows.reduce((accu, row) => ({
      ...accu,
      [row.key]: ""
    }), {}))
  }

  async function submitBlacklist() {
    const problemRow = dataRows.find(row => !form[row.key]);
    if (problemRow) {
      setAlertMessage({ message: `請填寫${problemRow.title}` });
      return;
    }

    gaEventTracker("submit blacklist");
    setFormLoading(true);
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/blacklist/create`, {
        fullNames: [form.fullName],
        phones: [form.phone],
        idNumber: `${form.idNumber}(${form.idNumberSuffix})`,
        idNumberSuffix: undefined,
        incident: {
          desc: form.desc,
          amount: (parseInt(form.amount) || undefined)
        }
      });
      setForm(null);
      setAlertMessage({
        message: "感謝你提供黑名單，我們會盡快處理"
      });
    } catch (error) {
      console.error('err when create blacklist:', error);

      let message = "";
      switch (error.response?.status) {
        case 403:
          message = "你暫時無法使用黑名單功能，請聯絡客服開通";
          break;
      }
      setAlertMessage({
        message,
      });
    } finally {
      setFormLoading(false);
    }
  }

  return <div>
    <div className="appWidth">
      <div className="flex mt-8 mb-2">
        <ul className="rounded-lg border border-gray-500 overflow-hidden mx-auto text-gray-700 divide-x divide-gray-200">
        {
          types.map((i) => <li key={i.value}
            className={`inline-block h-full px-4 py-2 ${type === i.value ? "bg-green-700 text-white" : "gray-400 bg-white"}`}
            onClick={() => [setType(i.value), setInput("")]}>
            {i.title}
          </li>)
        }
        </ul>
      </div>
      <div className="px-6 py-4 text-center">
        <div className="w-full border border-solid border-[#CCCCCC] rounded text-lg bg-white">
          <input className="px-3 py-2 w-full bg-transparent placeholder-gray-500 text-gray-700"
            type="text" value={input}
            maxLength={8}
            placeholder={types.find(t => t.value === type)?.placeholder}
            onChange={(e) => setInput(e.target.value.toUpperCase())} />
        </div>
        <button className="bg-main px-6 py-2 mt-5 rounded-lg text-xl text-white"
          onClick={search}>
          搜尋
        </button>
      </div>
      {
        !searched && <div className="mt-10 text-center">
          司機黑名單集合OKTaxi 眾多合作出租人所提供的資訊，現時共收錄約500項名單，所有資料僅供參考
          <p className="mt-2">
            由於私隱問題，只供會員以精確資料搜尋
          </p>
          <p className="mt-2">
            如有任何資料提供，歡迎<span className="text-green-500" onClick={openBlacklistForm}>
              提供黑名單
            </span>
          </p>
        </div>
      }
      {
        searched && <div className="mt-6">
          <p className="mb-3 text-center">
            如有任何資料提供，歡迎<span className="text-green-500" onClick={openBlacklistForm}>
              提供黑名單
            </span>
          </p>
          <p className="mt-2">
            所有資料僅供參考，只供內部查詢，未經授權不應分享/傳閱
          </p>
          {dataList?.map((item, i) => <div key={i} className="bg-white p-2 rounded shadow-lg border border-red-300">
            <b>
              #{item.ref} {item.userId === userId ? "(此資料由你提供)" : ""}
            </b>
            {item.fullNames.length ? <div>
              姓名: {item.fullNames.join(" / ")}
            </div> : ""}
            {item.phones.length ? <div>
              電話: {item.phones.join(" / ")}
            </div> : ""}
            {item.idNumber ? <div>
              身分證: {item.idNumber}
            </div> : ""}
            <div>
              記錄:
              <ul className="list-disc list-inside">
              {item.incidents.map((inc, i) => <li key={i}>
                {inc.at ? `(${moment(inc.at).format("YYYY/M/D")}) ` : ""}{inc.desc}{inc.amount ? `，約$${inc.amount}` : ""}
              </li>)}
              </ul>
            </div>
          </div>)}
        </div>
      }
      {
        !loading && searched && dataList?.length === 0 ?
          <div className="mt-10 text-center">
            <div className="text-gray-700 text-xl">
              - 未有相關黑名單記錄 -
            </div>
            <button className="bg-green-500 rounded text-white px-4 py-2 mt-6"
              onClick={openBlacklistForm}>
              提供黑名單
            </button>
          </div>
          :
          ""
      }
      {
        !loading && searched && !dataList ?
          <div className="text-center mt-10">
            <div className="text-gray-700 text-xl">
              發生網絡錯誤 請重試
            </div>
          </div>
          :
          ""
      }
      {
        !loading ?
          ""
          :
          <LoadingComponent></LoadingComponent>
      }
    </div>

    <Modal
      isOpen={!!form}
      style={customStyles}
      shouldCloseOnOverlayClick={false}
      onRequestClose={() => setForm(null)}>
      {
        form ? (formLoading ? <LoadingComponent>
          </LoadingComponent> : <div>
          <GrClose className="text-black float-right" onClick={() => setForm(null)}></GrClose>
          <p className="mb-4 text-main font-bold text-xl">
            提供黑名單
          </p>
          {
            dataRows.map((i, index) => getFormInputRow(i, index, form, setForm))
          }
          <button className="mt-4 w-full gradiBtn p-4 specFont shadow-md"
            onClick={submitBlacklist}>確定</button>
        </div>) : ""
      }
    </Modal>
  </div>;
};

export default withTranslation()(DriverBlacklist);