import { useTranslation } from "react-i18next";
import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import Select from 'react-select';
import TheTitle from "../../compontents/TheTitle";
import axios from "axios";
import { Checkbox } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { gRegion, gRegionDistricts, gCarColor, gCarShiftWithoutAll, gCarType, gCarCurrentShift } from "../../global/constants";
import LoadingComponent from "../../compontents/LoadingComponent";
import { AlertMessageContext } from "../../context";
import { useAnalyticsEventTracker } from "../../customHook";
import ReactPixel from 'react-facebook-pixel';

const SubPlaza = (props) => {
  const gaEventTracker = useAnalyticsEventTracker("Sub Plaza");
  const defaultRegionOpt = { label: '無', value: '' };
  const defaultAreaOpt = { label: '全區', value: '' };
  const refer = !!props.refer;
  
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const setAlertMessage = useContext(AlertMessageContext);
  const data = location.state?.enquiryData || props.enquiryData;
  const [loading, setLoading] = useState(false);
  const [regions, setRegions] = useState(() => {
    const arr = [null, null, null];
    if (data) {
      data.areas.forEach((area, i) => {
        if (gRegionDistricts[area]) {
          arr[i] = gRegion.find(r => r.value === area);
        } else {
          gRegion.some(r => {
            if (gRegionDistricts[r.value].some(d => d === area)) {
              arr[i] = r;
              return true;
            }
          });
        }
      });
    }
    return arr;
  });
  const [areas, setAreas] = useState(() => {
    const arr = [null, null, null];
    if (data) {
      data.areas.forEach((area, i) => {
        if (!gRegionDistricts[area]) {
          arr[i] = {
            label: area,
            value: area
          };
        }
      });
    }
    return arr;
  });
  const [carColor, setCarColor] = useState(data?.carColor || gCarColor[0].value);
  const [carTypes, setCarTypes] = useState(new Set(data?.carTypes));
  const [carShift, setCarShift] = useState(data?.carShift || gCarShiftWithoutAll[0].value);
  const [currentShift, setCurrentShift] = useState(data?.currentShift !== 'part' && data?.currentShift || refer && gCarCurrentShift[0].value);
  const [daysOpen, setDaysOpen] = useState(new Set(data?.dayOpen));
  const [daysPerWeek, setDaysPerWeek] = useState({ label: `${data?.daysPerWeek || 1}天`, value: data?.daysPerWeek || 1 });
  const [drivingYears, setDrivingYears] = useState(data?.drivingYears ? { label: `${data?.drivingYears}年`, value: data?.drivingYears } : null);
  const [taxiDrivingYears, setTaxiDrivingYears] = useState(data?.taxiDrivingYears ? { label: `${data?.taxiDrivingYears}年`, value: data?.taxiDrivingYears } : null);
  const [rent, setRent] = useState(data?.rent || "");
  const [depositRemark, setDepositRemark] = useState(data?.depositRemark ? { label: "一次性", value: false } : { label: "供款", value: true });
  const [otherRemark, setOtherRemark] = useState(data?.otherRemark || "");
  const [phone, setPhone] = useState(!refer && localStorage.getItem("phone") || data?.phone || "");

  useEffect(() => {
    ReactPixel.pageView();
  }, []);

  function submit() {
    gaEventTracker("add sub", "submit");
    if (!regions[0]) {
      setAlertMessage({ message: "請選擇地區" });
      return;
    }

    if (carTypes.size === 0) {
      setAlertMessage({ message: "請剔選車款"});
      return;
    }

    if (!refer && daysOpen.size === 0) {
      setAlertMessage({ message: "請剔選開工日子"});
      return;
    }

    if (!refer && !taxiDrivingYears) {
      setAlertMessage({ message: "請選擇的士駕駛年數"});
      return;
    }

    if (!/[456789]\d{7}/.test(phone)) {
      setAlertMessage({ message: "請輸入正確的WhatsApp聯絡電話號碼"});
      return;
    }

    const json = {
      enquiryId: data?._id,
      refer,
      phone,
      carColor,
      carTypes: Array.from(carTypes),
      shift: carShift,
      currentShift,
      areas: areas.map((area, i) => regions[i] ? (area && area.value || regions[i].value) : null).filter(val => !!val),
      dayOpen: !refer ? Array.from(daysOpen) : null,
      daysPerWeek: !refer ? daysPerWeek.value : null,
      drivingYears: !refer ? (drivingYears ? drivingYears : taxiDrivingYears).value : null,
      taxiDrivingYears: !refer ? taxiDrivingYears.value : null,
      rent: !refer ? parseInt(rent) : null,
      deposit: null,
      depositRemark: !refer && depositRemark.value ? "需要供" : "",
      otherRemark,
    };
    if (isNaN(json.rent)) {
      json.rent = null;
    }
    setLoading(true)
    const api_url = process.env.REACT_APP_API_URL + `/enquiry/create`;
    axios.post(api_url, json)
      .then(response => {
        if (!refer) {
          localStorage.setItem("phone", phone);
        }
        localStorage.removeItem("enquiryCheckedAt");
        setAlertMessage({
          message: refer ? "已推薦司機，如成功租出我們會聯絡你" : "替更報名成功，有合適出租人會直接聯絡你",
          callback: () => navigate(refer ? "/userinfo" : "/")
        });
      })
      .catch(error => {
        console.error('err when update:', error)
        if (error.response
            && error.response.status === 403
            && error.response.data.message === 'quota exceeded') {
          setAlertMessage({ message: "你的報名已經到達上限，請聯絡客服處理" });
        } else {
          setAlertMessage({ message: "報名失敗，請重試" });
        }
      })
      .finally(() => setLoading(false));
  }

  return <div>
    <div className="appWidth">
    {
      !loading ? <div className={!refer ? "pb-18" : ""}>
        {
          <div className="text-center text-gray-700 font-bold my-4">
            {refer ? "成功介紹司機租車，可獲等值半更租金的現金回佣" : "填低你既替更資料，出租人見到就會聯絡你"}
          </div>
        }
        <div>
          <div className="w-full flex items-center mt-2">
            <div className="mr-2 bg-main bg-opacity-100 h-1 w-4"></div>
            <div className="text-gray-700">車種</div>
          </div>
          <RadioGroup
            row
            name="carColor"
            defaultValue={gCarColor[0].value}
            value={carColor}>
            {
              gCarColor.map((i) => <FormControlLabel
                key={i.value}
                label={t(`carColor.${i.value}`)}
                value={i.value}
                control={<Radio
                  onChange={(e) => setCarColor(i.value)}
                />} />
              )}
          </RadioGroup>

          <div className="w-full flex items-center mt-2">
            <div className="mr-2 bg-main bg-opacity-100 h-1 w-4"></div>
            <div className="text-gray-700">車款</div>
          </div>
          <div className="flex flex-wrap">
            {
              gCarType.map((i) => <FormControlLabel
                key={i.value}
                label={t(`carType.${i.value}`)}
                value={i.value}
                control={<Checkbox
                  checked={carTypes.has(i.value)}
                  onChange={(e) => setCarTypes(s => {
                    if (e.target.checked) {
                      s.add(i.value);
                    } else {
                      s.delete(i.value);
                    }
                    return new Set(s);
                  })}
                />} />
              )
            }
          </div>

          <div className="w-full flex items-center mt-2">
            <div className="mr-2 bg-main bg-opacity-100 h-1 w-4"></div>
            <div className="text-gray-700">更期</div>
          </div>
          <RadioGroup
            row
            name="shift"
            defaultValue={gCarShiftWithoutAll[0].value}
            value={carShift}>
            {
              gCarShiftWithoutAll.map((i) => <FormControlLabel
                key={i.value}
                label={t(`shift.${i.value}`)}
                value={i.value}
                control={<Radio
                  onChange={(e) => setCarShift(i.value)}
                />} />
              )}
          </RadioGroup>

          {
            currentShift && <>
              <RadioGroup
                row
                name="currentShift"
                defaultValue={gCarCurrentShift[0].value}
                value={currentShift}>
                {
                  gCarCurrentShift.filter(i => i.value !== 'all').map((i) => <FormControlLabel
                    key={i.value}
                    label={t(`currentShift.${i.value}`)}
                    value={i.value}
                    control={<Radio
                      onChange={(e) => setCurrentShift(i.value)}
                    />} />
                  )}
              </RadioGroup>
            </>
          }

          {
            !refer && <>
              <div className="mb-0.5 w-full flex items-center mt-2">
                <div className="mr-2 bg-main bg-opacity-100 h-1 w-4"></div>
                <div className="text-gray-700">開工日子</div>
              </div>
              <div>
                <FormControlLabel
                  label="每星期"
                  labelPlacement="start"
                  control={<Select
                    styles={{ menu: base => ({ ...base, zIndex: 9 }) }}
                    placeholder="請選擇"
                    value={daysPerWeek}
                    options={Array(4).fill(0).map((_, i) => ({
                      label: `${i+1}天`,
                      value: i+1
                    }))}
                    onChange={setDaysPerWeek}
                  />}
                  sx={{marginLeft: "8px", gap: "5px"}}
                />
              </div>
              <div className="flex flex-wrap mb-2">
                {
                  Array(7).fill(0).map((_, i) => <FormControlLabel
                    key={`restDay${i}`}
                    label={t(`weekday.${i+1}`)}
                    value={i+1}
                    control={<Checkbox
                      checked={daysOpen.has(i+1)}
                      onChange={(e) => setDaysOpen(s => {
                        const value = i + 1;
                        if (e.target.checked) {
                          s.add(value)
                        } else {
                          s.delete(value)
                        }
                        return new Set(s);
                      })}
                    />}
                  />)
                }
              </div>
            </>
          }

          <div>
            <div className="mb-0.5 w-full flex items-center mt-2">
              <div className="mr-2 bg-main bg-opacity-100 h-1 w-4"></div>
              <div className="text-gray-700">地區</div>
            </div>
            {
              (refer ? [
                ""
              ] : [
                "首選",
                "次選",
                "三選",
              ]).map((label, i) => <div key={i} className="grid grid-cols-10 mb-1">
                {
                  label && <span className="col-span-1 flex items-center">
                    { label }
                  </span>
                }
                <Select
                  styles={{ menu: base => ({ ...base, zIndex: 9 }) }}
                  placeholder={ i ? "非必要" : "請選擇" }
                  value={regions[i]}
                  options={ i ? [
                    defaultRegionOpt,
                    ...gRegion,
                  ] : gRegion }
                  className="basic-multi-select text-black col-span-4 mr-1"
                  onChange={(r) => [setRegions((regions) => {
                    regions = regions.slice();
                    regions[i] = r;
                    return regions;
                  }), setAreas((areas) => {
                    areas = areas.slice();
                    areas[i] = (r ? defaultAreaOpt : null);
                    return areas;
                  })]}
                />
                {regions[i] && regions[i].value ? <Select
                  styles={{ menu: base => ({ ...base, zIndex: 9 }) }}
                  value={areas[i]}
                  options={[
                    defaultAreaOpt,
                    ...gRegionDistricts[regions[i].value].map(d => ({ label: d, value: d })),
                  ]}
                  className="basic-multi-select text-black col-span-5 ml-1"
                  onChange={(d) => setAreas((areas) => {
                    areas = areas.slice();
                    areas[i] = d;
                    return areas;
                  })}
                /> : ""}
              </div>)
            }
          </div>

          {
            !refer && <>
              <div className="mb-0.5 w-full flex items-center mt-3">
                <div className="mr-2 bg-main bg-opacity-100 h-1 w-4"></div>
                <div className="text-gray-700">駕駛經驗</div>
              </div>
              <div className="grid grid-cols-10">
                <div className="col-span-5">
                  <FormControlLabel
                    label="的士"
                    labelPlacement="start"
                    control={<Select
                      styles={{ menu: base => ({ ...base, zIndex: 9 }) }}
                      placeholder="請選擇"
                      value={taxiDrivingYears}
                      options={Array(11).fill(0).map((_, i) => ({
                        label: (i === 0 ? "無" : `${i}年${i >= 10 ? "以上" : ""}`),
                        value: i
                      }))}
                      onChange={setTaxiDrivingYears}
                    />}
                    sx={{marginLeft: "8px", gap: "5px"}}
                  />
                </div>
                <div className="col-span-5">
                  <FormControlLabel
                    label="其他車"
                    labelPlacement="start"
                    control={<Select
                      styles={{ menu: base => ({ ...base, zIndex: 9 }) }}
                      placeholder="請選擇"
                      value={drivingYears}
                      options={Array(11).fill(0).map((_, i) => ({
                        label: (i === 0 ? "無" : `${i}年${i >= 10 ? "以上" : ""}`),
                        value: i
                      }))}
                      onChange={setDrivingYears}
                    />}
                    sx={{marginLeft: "8px", gap: "5px"}}
                  />
                </div>
              </div>
            </>
          }

          <div className="grid grid-cols-10 mt-3">
            <div className="col-span-9">
              <div className="mb-0.5 w-full flex items-center">
                <div className="mr-2 bg-main bg-opacity-100 h-1 w-4"></div>
                <div className="text-gray-700">{refer && "司機"}聯絡電話</div>
                { !refer && <div className="ml-1 text-gray-500 text-sm">車主會以WhatsApp聯絡你</div> }
              </div>
              <div className="w-full border border-solid border-[#CCCCCC] rounded bg-white">
                <input className='px-2 py-1 w-full bg-transparent placeholder-gray-400 text-gray-700'
                  value={phone}
                  maxLength="8"
                  placeholder={refer ? "司機電話號碼" : "Whatsapp電話號碼"}
                  onChange={(e) => setPhone(e.target.value.replace(/\D/g, ""))} />
              </div>
            </div>
          </div>

          { !refer && <>
              <div className="grid grid-cols-10 mt-3">
                <div className="col-span-4">
                  <div className="mb-0.5 w-full flex items-center">
                    <div className="mr-2 bg-main bg-opacity-100 h-1 w-4"></div>
                    <div className="text-gray-700">理想租金(每更)</div>
                  </div>
                  <div className="w-full border border-solid border-[#CCCCCC] rounded bg-white">
                    <input className='px-2 py-1 w-full bg-transparent placeholder-gray-400 text-gray-700'
                      type="number"
                      placeholder="可留空"
                      value={rent}
                      onChange={(e) => setRent(e.target.value)} />
                  </div>
                </div>
                <div className="col-span-1"></div>
                <div className="col-span-4">
                  <div>
                    <FormControlLabel
                      label={<p className="text-gray-700">按金安排</p>}
                      labelPlacement="top"
                      control={<Select
                        styles={{ menu: base => ({ ...base, zIndex: 9 }) }}
                        placeholder="請選擇"
                        value={depositRemark}
                        options={[false, true].map(value => ({
                          label: value ? "供款" : "一次性",
                          value,
                        }))}
                        onChange={setDepositRemark}
                      />}
                      sx={{marginLeft: "8px"}}
                    />
                  </div>
                </div>
              </div>
            </>
          }

          {
            !refer && <div className="w-full border border-solid border-[#CCCCCC] rounded bg-white mt-3">
              <textarea rows="2"
                className="px-2 py-1 w-full bg-transparent placeholder-gray-400 text-gray-700"
                placeholder="其他備註"
                value={otherRemark}
                onChange={(e) => setOtherRemark(e.target.value)} />
            </div>
          }
        </div>
        <div className={`${!refer ? 'fixed bottom-0 left-0 p-1' : 'mt-6'} w-full`}>
          <button className="w-full gradiBtn p-4 specFont shadow-md"
            onClick={() => submit()}>
            提交
          </button>
        </div>
      </div>
      :
      <LoadingComponent></LoadingComponent>
    }
    </div>
  </div>
}

export default SubPlaza;
