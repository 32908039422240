import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import { withTranslation } from 'react-i18next';
import CarDetail from "../CarDetail";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AiOutlineClockCircle } from 'react-icons/ai';
import { BsWhatsapp } from 'react-icons/bs';
import { FaTaxi } from 'react-icons/fa';
import { GiSteeringWheel } from 'react-icons/gi';
import { GrClose } from 'react-icons/gr';
import { MdOutlineCarRental } from 'react-icons/md';
import { GoBook } from 'react-icons/go';
import axios from "axios";
import LoadingComponent from "../../compontents/LoadingComponent";
import { News } from "../../compontents/News";
import Modal from 'react-modal';
import Moment from 'react-moment';
import Select from 'react-select';
import { Checkbox } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { gRegion, gRegionDistricts, gCarColor, gCarCurrentShift, gCarShift, gCarType, gOrderBy, gTaxiImg, gWhatsapp } from "../../global/constants";
import styles from './styles.css';
import { TaxiInfo } from "../../compontents/TaxiInfo";
import { Filter } from "../../compontents/Filter";
import { AlertMessageContext, DataListContext, ScrollPositionContext } from "../../context";
import { useAnalyticsEventTracker } from "../../customHook";
import jwt_decode from "jwt-decode";
import ReactPixel from 'react-facebook-pixel';
import moment from 'moment';
import SubDetail from '../SubDetail';
Modal.setAppElement('#root');

const NEWS_COOLDOWN_HOURS = 12;
const ENQUIRIES_COOLDOWN_HOURS = 24;
const ENQUIRIES_UPDATED_COOLDOWN_HOURS = 24 * 3;
const customStyles = {
  overlay: {
    padding: '3rem 2rem',
    zIndex: 99,
    touchAction: 'none'
  },
  content: {
    inset: 'unset',
    position: 'relative',
    width: '100%',
    maxHeight: '100%',
    padding: '0px',
    overlay: 'scroll',
    touchAction: 'none'
  },
};
const customCenterStyles = {
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '3rem 2rem',
    zIndex: 99,
    touchAction: 'none'
  },
  content: {
    inset: 'unset',
    position: 'relative',
    width: '100%',
    maxHeight: '100%',
    padding: '0px',
    overlay: 'scroll',
    touchAction: 'none'
  },
};

const Home = ({ t }) => {
  const navigate = useNavigate();
  const gaEventTracker = useAnalyticsEventTracker("Home");
  const setAlertMessage = useContext(AlertMessageContext);
  const [savedDataList, setSavedDataList] = useContext(DataListContext);
  const [savedScrollPosition, setSavedScrollPosition] = useContext(ScrollPositionContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const scrollPosition = useRef(0);
  const getSearchParams = (type) => {
    try {
      const param = searchParams.get(type);
      switch (type) {
        case "orderBy":
          const asc = (searchParams.get('asc') === 'true');
          return gOrderBy.find(({ value }) => value.field === param && value.asc === asc) || gOrderBy[0];

        case "tag":
          return searchParams.get("tag");

        default:
          if (param) {
            let searchArray = param.split(",");
            switch (type) {
              case "region":
                return searchArray.reduce((accu, option) => {
                  if (gRegionDistricts[option]) {
                    if (!accu.has(option)) {
                      accu.set(option, []);
                    }
                  } else {
                    const region = gRegion.find(r => gRegionDistricts[r.value].includes(option));
                    if (region) {
                      accu.set(region.value, [...(accu.get(region.value) || []), option]);
                    }
                  }
                  return accu;
                }, new Map());

              case "color":
                searchArray = searchArray.filter(option => gCarColor.find(color => color.value === option));
                break;
              case "type":
                searchArray = searchArray.filter(option => gCarType.find(type => type.value === option));
                break;
              case "shift":
                searchArray = searchArray.filter(option => gCarShift.find(shift => shift.value === option));
                break;
              case "currentShift":
                searchArray = searchArray.filter(option => gCarCurrentShift.find(currentShift => currentShift.value === option));
                break;
            }
            return new Set(searchArray);
          }
      }
    } catch (e) {
      console.error('err when getSearchParams:', e);
    }
    return new Set();
  };

  const [loading, setLoading] = useState(savedDataList === undefined);
  const [bottom, setBottom] = useState(0);
  const [scrollFinal, setScrollFinal] = useState(false);
  const [filterRegion, setFilterRegion] = useState(getSearchParams("region"));
  const [filterCarColor, setFilterCarColor] = useState(getSearchParams("color"));
  const [filterCarType, setFilterCarType] = useState(getSearchParams("type"));
  const [filterCarShift, setFilterCarShift] = useState(getSearchParams("shift"));
  const [filterCarCurrentShift, setFilterCarCurrentShift] = useState(getSearchParams("currentShift"));
  const [filterRestDays, setFilterRestDays] = useState(new Set());
  const [filterChanged, setFilterChanged] = useState(false);
  const [filterOrderBy, setFilterOrderBy] = useState(getSearchParams('orderBy'));
  const [filterTag, setFilterTag] = useState(getSearchParams("tag"))
  const [reload, setReload] = useState(savedDataList === undefined);
  const [dataList, setDataList] = useState(savedDataList ? savedDataList.data : []);
  const [news, setNews] = useState([]);
  const [enquiries, setEnquiries] = useState(null);
  const [enquiryModalOpen, setEnquiryModalOpen] = useState(false);
  const [slashTaxiModalOpen, setSlashTaxiModalOpen] = useState(false);
  const filterPostSource = useRef(null);
  const token = localStorage.getItem('token');
  const isAdmin = !!token && (jwt_decode(token).role === 'admin');

  useEffect(() => {
    Array.from(searchParams.entries()).length === 0 && setSearchParams({orderBy: "createdAt"}, {replace: true});
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("searchParams") !== JSON.stringify(Array.from(searchParams.entries())) && !loading) {
      let filterValue;
      filterValue = getSearchParams("region");
      JSON.stringify(Array.from(filterRegion.entries())) !== JSON.stringify(Array.from(filterValue.entries())) && setFilterRegion(filterValue);
      filterValue = getSearchParams("color");
      JSON.stringify(Array.from(filterCarColor.entries())) !== JSON.stringify(Array.from(filterValue.entries())) && setFilterCarColor(filterValue);
      filterValue = getSearchParams("type");
      JSON.stringify(Array.from(filterCarType.entries())) !== JSON.stringify(Array.from(filterValue.entries())) && setFilterCarType(filterValue);
      filterValue = getSearchParams("shift");
      JSON.stringify(Array.from(filterCarShift.entries())) !== JSON.stringify(Array.from(filterValue.entries())) && setFilterCarShift(filterValue);
      filterValue = getSearchParams("currentShift");
      JSON.stringify(Array.from(filterCarCurrentShift.entries())) !== JSON.stringify(Array.from(filterValue.entries())) && setFilterCarCurrentShift(filterValue);
      filterValue = getSearchParams("orderBy");
      JSON.stringify(filterOrderBy) !== JSON.stringify(filterValue) && setFilterOrderBy(filterValue);
      // console.log("tag", getSearchParams("tag"))
      // filterValue = getSearchParams("tag");
      // JSON.stringify(Array.from(filterTag.entries())) !== JSON.stringify(Array.from(filterValue.entries())) && setFilterTag(filterValue);
    }
    sessionStorage.setItem("searchParams", JSON.stringify(Array.from(searchParams.entries())));

  }, [searchParams, loading]);

  useEffect(() => {
    ReactPixel.pageView();

    const oid = searchParams.get('oid');
    if (oid) {
      const userId = searchParams.get('userId');
      navigate(`/cardetail?oid=${oid}${userId ? `&userId=${userId}` : ""}`, {
        replace: true
      });
      return;
    }

    if (savedScrollPosition) {
      window.scrollTo(0, savedScrollPosition);
    }
    setSavedScrollPosition();
    setSavedDataList();

    if (!isAdmin) {
      const api_url = process.env.REACT_APP_API_URL + "/enquiry/getByUserId";
      axios.post(api_url).then(response => {
        const enquiries = response.data.data;
        setEnquiries(enquiries);
      });
    }
  }, []);

  useEffect(() => {
    if (!enquiries) {
      return;
    }

    if (!enquiries.length) {
      setEnquiryModalOpen(false);
      return;
    }

    const enquiryCheckedAt = localStorage.getItem('enquiryCheckedAt');
    if ((!enquiryCheckedAt || moment().add(-ENQUIRIES_COOLDOWN_HOURS, 'hours').isAfter(parseInt(enquiryCheckedAt)))
      && moment().add(-ENQUIRIES_UPDATED_COOLDOWN_HOURS, 'hours').isAfter(enquiries[0].updatedAt)) {
      setEnquiryModalOpen(true);
    }
  }, [enquiries]);

  useEffect(() => {
    const handleScroll = () => {
      scrollPosition.current = window.pageYOffset;
      setBottom(window.document.body.getBoundingClientRect().bottom);
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      setSavedScrollPosition(scrollPosition.current);
    }
  }, [dataList, searchParams]);

  useEffect(() => {
    return () => {
      setSavedDataList(dataList.length > 0 ? {
        data: dataList,
        search: Object.fromEntries(searchParams.entries())
      } : undefined);
    }
  }, [dataList])

  useEffect(() => {
    if (bottom && bottom - window.innerHeight < 100) {
      searchCar();
    }
  }, [bottom]);

  function handleEnquiryModal(enquiry, action) {
    const cb = () => {
      setEnquiryModalOpen(false);
      localStorage.setItem("enquiryCheckedAt", (new Date()).getTime());
    };

    switch (action) {
      case 'delete':
        setAlertMessage({
          message: '確定刪除替更資料？',
          callback: () => {
            cb();
            setEnquiries([]);
            const api_url = process.env.REACT_APP_API_URL + `/enquiry/setDeleted`;
            axios.post(api_url, {
              enquiryId: enquiry._id
            });
          }
        });
        break;

      case 'update':
        cb();
        goToSub(enquiry);
        break;

      default:
        const api_url = process.env.REACT_APP_API_URL + `/enquiry/setUpdated`;
        axios.post(api_url, {
          enquiryId: enquiry._id
        });
        cb();
        break;
    }
  }

  function goToSub() {
    gaEventTracker("navigate", "subplaza");
    setSlashTaxiModalOpen(false);
    window.open(process.env.REACT_APP_SLASHTAXI_INSTALL_LINK, "_blank");
    // navigate('/subplaza/form', {
    //   state: {
    //     enquiryData: enquiry
    //   }
    // })
  }

  function goToNewComer() {
    gaEventTracker("navigate", "newcomer")
    navigate('/newcomer', {
      state: {}
    });
  }

  function goToUserInfo() {
    gaEventTracker("navigate", "userinfo");
    navigate('/userinfo');
  }

  function contactCS() {
    gaEventTracker("whatsapp", "CS")
    window.open(`https://wa.me/${gWhatsapp}`, '_blank')
  }

  function goToCarDetail(offer) {
    gaEventTracker("taxi info", "view");
    if (offer.request) {
      navigate(`/carrequest?rid=${offer.request._id}`, {
        state: {}
      });
    } else {
      navigate(`/cardetail?oid=${offer._id}`, {
        state: {
          carData: offer,
          offerId: offer._id,
          enquiries: enquiries
        }
      });
    }
  }

  useEffect(() => {
    const api_url = process.env.REACT_APP_API_URL + "/news";
    fetch(api_url).then(response => response.json()).then(json => {
      const readNewsData = JSON.parse(localStorage.getItem("news") || "[]");
      const updatedReadNewsData = [];
      const readIds = readNewsData.reduce((accu, data) => {
        if (new Date() - new Date(data.lastRead) < NEWS_COOLDOWN_HOURS * 60 * 60 * 1000) {
          if (!accu.has(data.id)) {
            accu.add(data.id);
            updatedReadNewsData.push(data);
          }
        }
        return accu;
      }, new Set());

      if (updatedReadNewsData.length !== readNewsData.length) {
        localStorage.setItem("news", JSON.stringify(updatedReadNewsData));
      }

      const newsArray = json.data.filter(news => !readIds.has(news._id));
      setNews(newsArray);
    });
  }, [])

  function resetSearch() {
    gaEventTracker("reset search", "empty result");
    setFilterRegion(new Set());
    setFilterCarColor(new Set());
    setFilterCarType(new Set());
    setFilterCarShift(new Set());
    setFilterCarCurrentShift(new Set());
    setFilterRestDays(new Set());
    setFilterTag();
  }

  function setTag(tag) {
    resetSearch();
    gaEventTracker("set tag", tag);
    setFilterTag(tag)
  }

  function contactSearch(t) {
    gaEventTracker("whatsapp", "empty result");
    const desc = [
      Array.from(filterRegion).slice(3).join('/'),
      Array.from(filterCarColor).map(val => t(`carColor.${val}`)).join('/'),
      Array.from(filterCarType).map(val => t(`carType.${val}`)).join('/'),
      Array.from(filterCarCurrentShift).map(val => t(`currentShift.${val}`)).join('/'),
      Array.from(filterCarShift).map(val => t(`shift.${val}`)).join('/'),
    ].filter(val => val).join(', ');
    window.open(`https://wa.me/${gWhatsapp}?text=想租${encodeURIComponent(desc)}`, '_blank');
  }

  function searchCar(reset = false) {
    if (!reset) {
      if (scrollFinal || loading) {
        return;
      }
    } else {
      setScrollFinal(false)
    }
    setLoading(true)
    setReload(false)
    if (reset) {
      setDataList([])
    }
    const source = axios.CancelToken.source()
    if (filterPostSource.current) {
      filterPostSource.current.cancel()
    }
    filterPostSource.current = source;

    const json = {
      "area": filterRegion.size === 0 ? null : Array.from(filterRegion).map(([r, districts]) => {
        if (districts.length === 0) {
          districts = gRegionDistricts[r];
        }
        return [...districts, r];
      }).flat(),
      "carColor": filterCarColor.size === 0 ? null : Array.from(filterCarColor),
      "carType": filterCarType.size === 0 ? null : Array.from(filterCarType),
      "shift": filterCarShift.size === 0 ? null : Array.from(filterCarShift),
      "currentShift": filterCarCurrentShift.size === 0 ? null : Array.from(filterCarCurrentShift),
      "dayOff": filterRestDays.size === 0 ? null : Array.from(filterRestDays),
      "offset": reset ? 0 : dataList.length,
      "limit": 100,
      "orderBy": filterOrderBy.value.field,
      "asc": filterOrderBy.value.asc,
    };
    if (filterCarShift.size === 0 && filterCarCurrentShift.size === 0 && filterRegion.size === 0 || filterCarShift.has('all')) {
      if (json.area) {
        json.area.push('');
      }
      if (json.currentShift) {
        json.currentShift.push('');
      }
    }
    if (filterTag) json.tags = filterTag;
    const api_url = process.env.REACT_APP_API_URL + "/offer/search"
    axios.post(api_url, json, {
      cancelToken: source.token
    }).then(response => {
      if (filterPostSource.current !== source) {
        return
      }

      const offers = response.data.data.offers;
      if (offers.length < json.limit) {
        setScrollFinal(true)
      }
      if (reset) {
        setDataList(offers)
      } else {
        setDataList(list => [...list, ...offers])
      }
    }).catch(err => {
      if (filterPostSource.current !== source) {
        return
      }

      console.error('err when search:', err)
      if (dataList.length === 0 || reset) {
        setReload(true)
      }
    }).finally(() => {
      if (filterPostSource.current !== source) {
        return
      }
      setLoading(false)
    });
  }

  useEffect(() => {
    if (searchParams.get("oid")) {
      return;
    }

    const keys = ["region", "color", "type", "shift", "currentShift"];
    const params = [filterRegion, filterCarColor, filterCarType, filterCarShift, filterCarCurrentShift].reduce((accu, optSet, i) => {
      if (optSet.size > 0) {
        accu[keys[i]] = Array.from(optSet).join(",");
      }
      return accu;
    }, {});
    params.orderBy = filterOrderBy.value.field;
    if (filterOrderBy.value.asc) {
      params.asc = "true";
    }
    if (filterTag) {
      params.tag = filterTag;
    }


    let currentSearchParams = [getSearchParams("region"), getSearchParams("color"), getSearchParams("type"), getSearchParams("shift"), getSearchParams("carCurrentShift")].reduce((accu, optSet, i) => {
      if (optSet.size > 0) {
        accu[keys[i]] = Array.from(optSet).join(",");
      }
      return accu;
    }, {});
    let orderBy = getSearchParams("orderBy")
    currentSearchParams.orderBy = orderBy.value.field;
    if (orderBy.value.asc) currentSearchParams.asc = "true";
    let tag = getSearchParams("tag");
    if (tag) currentSearchParams = tag;

    if (JSON.stringify(currentSearchParams) !== JSON.stringify(params)) setSearchParams(params);
    // if(savedDataList) console.log(JSON.stringify(savedDataList.search), JSON.stringify(params));
    if (!savedDataList || JSON.stringify(savedDataList.search) !== JSON.stringify(params)) {
      searchCar(true);
    }
  }, [filterRegion, filterCarColor, filterCarType, filterCarCurrentShift, filterCarShift, filterRestDays, filterOrderBy, filterTag]);

  const onUpdateFilter = (filter) => {
    setFilterRegion(new Map(filter.region));
    setFilterCarColor(new Set([...filter.color].filter(pair => pair[1]).map(pair => pair[0])));
    setFilterCarType(new Set([...filter.type].filter(pair => pair[1]).map(pair => pair[0])));
    setFilterCarShift(new Set([...filter.shift].filter(pair => pair[1]).map(pair => pair[0])));
    setFilterCarCurrentShift(new Set([...filter.currentShift].filter(pair => pair[1]).map(pair => pair[0])));
  }

  useEffect(() => {
    loading && window.scrollTo(0, document.body.scrollHeight);
  }, [loading]);

  return (
    <div id="home" className="appWidth w-full pb-20">
      <div className="flex justify-between bg-white py-4 px-12">
        {/* <div className="text-center">
          <FaTaxi className="inline text-red-600 text-3xl p-0.5"></FaTaxi>
          <p className="text-xs font-bold">
            全架專區
          </p>
        </div> */}
        <div className="text-center" onClick={goToNewComer}>
          <GoBook className="inline text-yellow-600 text-3xl"></GoBook>
          <p className="text-xs font-bold">
            新人入行
          </p>
        </div>
        <div className="text-center" onClick={() => setSlashTaxiModalOpen(true)}>
          <img className="mx-auto" width="30px" src={require("../../assets/slashtaxi_icon.png")} />
          <p className="text-xs font-bold">
            自由替更
          </p>
        </div>
        <div className="text-center" onClick={goToUserInfo}>
          <MdOutlineCarRental className="inline text-red-700 text-3xl"></MdOutlineCarRental>
          <p className="text-xs font-bold">
            {token ? "車主服務" : "我要出租"}
          </p>
        </div>
      </div>
      <div className="flex justify-center bg-white pb-3">
        <button className="py-1 px-3 gradiBtn shadow-lg rounded-lg"
          onClick={goToSub}>
          想每星期彈性替更？下載自由替啦
        </button>
      </div>
      <Filter filters={{
        region: filterRegion,
        color: filterCarColor,
        type: filterCarType,
        shift: filterCarShift,
        currentShift: filterCarCurrentShift
      }}
        updateFilter={onUpdateFilter}
        sort={filterOrderBy}
        updateSorter={setFilterOrderBy}
        resetTag={setFilterTag} />
      <div className="appWidth mt-1">
        {
          dataList.map(i => <TaxiInfo isAdmin={isAdmin}
            info={i}
            key={i._id}
            onSelected={goToCarDetail} />)
        }
        {
          !loading && dataList.length === 0 && !reload ?
            <div className="my-10 text-center">
              <div className="text-gray-700 text-xl">
                未找到合適的士
                <p className="text-main"
                  onClick={() => contactSearch(t)}>
                  㩒呢到問下啦
                </p>
              </div>
              <button className="greyBtn w-28 p-3 specFont shadow-md mt-6"
                onClick={() => resetSearch()}>
                顯示全部
              </button>
            </div>
            :
            ""
        }
        {
          !loading && reload ?
            <div className="text-center my-10">
              <div className="text-gray-700 text-xl">
                發生網絡錯誤
              </div>
              <button className="gradiBtn w-28 text-center p-3 mt-6"
                onClick={() => searchCar(true)}>
                重試
              </button>
            </div>
            :
            ""
        }
        {
          !loading ?
            ""
            :
            <LoadingComponent></LoadingComponent>
        }
      </div>
      {news.length > 0 && <News onClose={() => setNews([])} news={news} />}
      {
        navigator.share &&
        <button id="share-button" className="fixed w-10 h-10 right-4 rounded-full shadow-lg"
          onClick={() => {
            gaEventTracker("share", "floating button");
            navigator.share({
              title: 'OK的 的士至啱租',
              text: '一齊上OK的搵心水的士租啦!',
              url: document.location.href
            })
              .catch((error) => console.log('err when share:', error));
          }}>
          <div id="share-icon" />
        </button>
      }
      <div className="fixed w-10 h-10 bottom-6 right-4 bg-whatsapp rounded-full shadow-lg"
        onClick={contactCS}>
        <BsWhatsapp className="w-full h-full text-white p-20p" />
      </div>
      {/* {
        enquiries?.length && <Modal
          isOpen={enquiryModalOpen}
          shouldCloseOnOverlayClick={false}
          style={customStyles}>
          <div className="sticky top-0 left-0 w-full logobg px-5 py-1 z-10">
            <div className="w-full h-full relative flex justify-center">
              <img className="h-8 object-contain" src={require("../../assets/logo_sub.png")} />
            </div>
          </div>
          <div className="px-1 pb-2">
            <SubDetail enquiryData={enquiries[0]} noAction={true}></SubDetail>
          </div>
          <div className="sticky bottom-0 left-0 right-0 w-full p-1 bg-white">
            <div className="text-center text-main text-bold mb-1">
              請確認你的替更資料正確
            </div>
            <div className="grid grid-cols-9 gap-1">
              <div className="greyBtn p-2 specFont shadow-md col-span-3 flex items-center justify-center"
                onClick={() => handleEnquiryModal(enquiries[0], 'delete')}>
                不再需要
              </div>
              <div className="gradiBtn p-2 specFont shadow-md col-span-3 flex items-center justify-center"
                onClick={() => handleEnquiryModal(enquiries[0], 'update')}>
                修改
              </div>
              <div className="greenBtn p-2 specFont shadow-md col-span-3 flex items-center justify-center"
                onClick={() => handleEnquiryModal(enquiries[0])}>
                確定
              </div>
            </div>
          </div>
        </Modal>
      } */}

      <Modal
        isOpen={slashTaxiModalOpen}
        style={customCenterStyles}
        onRequestClose={() => setSlashTaxiModalOpen(false)}>
        <div className="p-3 relative">
          <GrClose className="text-black float-right" onClick={() => setSlashTaxiModalOpen(false)}></GrClose>
          
          <h3 className="text-lg font-bold text-center">
            OK的首創【自由替】App
          </h3>

          <h4 className="mt-4">適合： </h4>
          <p>✅ 專做替更司機，邊日得閒就預約邊日</p>
          <p>✅ 每星期都想報更既司機</p>
          <p>✅ 鐘意彈性自由開工既司機，唔需要固定時間開工沒假放😆</p>

          <p className="mt-6 text-sm">* 如要搵長工正/替更，請按右上角返回繼續瀏覽</p>

          <button className="mt-5 w-full text-lg gradiBtn p-3 specFont shadow-md"
            onClick={() => goToSub()}>
            立即下載
          </button>
        </div>
      </Modal>
    </div>
  )
}

export default withTranslation()(Home)
