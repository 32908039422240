import { Toggle } from "./Toggle";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { gTaxiImg } from "../global/constants"
import { useTranslation } from "react-i18next";

export const MyTaxi = (props: { info: Object, deleteTaxi: Function, enableTaxi: Function, editTaxiInfo: Function }) => {
  const { t } = useTranslation();
  const info = props.info;
  return <div className="bg-main3 rounded-lg mb-1">
    <div className="relative grid grid-cols-10">
      <div className="flex col-span-4 items-center p-1">
        <Toggle checked={ info.enabled } disabled={ info.loading } toggleFunc={ (enabled) => props.enableTaxi(info, enabled) } />
        {
          !info.enabled && <div className="p-1 greyBtn text-sm ml-2"
            onClick={() => props.deleteTaxi(info)}>
            <AiOutlineDelete></AiOutlineDelete>
          </div>
        }
      </div>
      <div className="flex col-span-6 items-center justify-between pl-2 p-1">
        <b className="mr-1 text-sm">#{info.ref} {info.enabled ? (info.invalidated ? '(審核中)' : '') : '(已下架)'}</b>
        {info.enabled ? <span className="bg-main px-2 py-1 rounded-lg text-white text-sm"
          onClick={() => props.editTaxiInfo(info)}>
          <AiOutlineEdit className="inline" /> 修改
        </span> : ""}
      </div>
      <div className="ratio-16/9 rounded-md col-span-4">
        <div className="ratio-in p-1">
          <img className="w-full h-full object-contain mb-4" src={gTaxiImg(info.carColor, info.carType)} />
        </div>
      </div>
      <div className="col-span-6 px-2 pb-2">
        <p className="text-maintext text-base">
          {t(`carType.${info.carType}`)}{info.carYear ? `(${info.carYear})` : ""} {info.area}{info.subarea || ''} {info.currentShift ? t(`currentShift.${info.currentShift}`) : ''}{t(`shift.${info.shift}`)}
        </p>
        {info.dayOpen && info.dayOpen.length ? <p className="text-maintext text-base">
          開: {info.dayOpen.map(d => t(`weekday.${d}`)).join('、')}
        </p> : ""}
        {info.dayOff && info.dayOff.length ? <p className="text-maintext text-base">
          休: {info.dayOff.map(d => t(`weekday.${d}`)).join('、')}
        </p> : ""}
        <p className="text-maintext text-base">
          租金: {info.rent ? `$${info.rent}` : '待議'} {info.rentRemark}
        </p>
        <p className="text-maintext text-base">
          按金: {info.deposit != null ? `$${info.deposit}` : '待議'} {info.depositRemark || ''}
        </p>
        {info.otherRemark ? <p className="text-maintext text-sm text-base">
          + {info.otherRemark}
        </p> : ""}
      </div>
    </div>
  </div>;
}
